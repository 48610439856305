import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "1rem 1rem",
  },
  header: {
    marginBottom: "1rem",
  },
  textCenter: {
    textAlign: "center",
  },
}));

export default function CQRImagePreview(props) {
  const {
    apiUrl,
    batchAsset,
    qrBackgroundSession,
    eccLevel,
    magnification,
    selectedAssets,
    selectCQRLayout,
    version,
  } = props;
  const classes = useStyles();
  const assetId = batchAsset
    ? props.assetId
    : selectedAssets[Object.keys(selectedAssets)[0]]
    ? selectedAssets[Object.keys(selectedAssets)[0]].assetId
    : "";
  return (
    <Card className={classes.root}>
      <CardContent className={classes.textCenter}>
        <Typography className={classes.header} variant="h4">
          Image Preview
        </Typography>

        {selectCQRLayout.value === "addNew" && qrBackgroundSession.image ? (
          <img
            alt="cqr"
            style={{ border: "#E6E7E8 2px solid", maxWidth: "100%" }}
            src={qrBackgroundSession.image}
          />
        ) : selectCQRLayout.value ? (
          <img
            alt="cqr"
            style={{ border: "#E6E7E8 2px solid", maxWidth: "100%" }}
            src={`${apiUrl}assets/${assetId}/qrCode?urlBase=https://qr.locatorx.com&target=lxproductscan&eccLevel=${eccLevel}&magnification=${magnification}&version=${version}&qrBackground=${selectCQRLayout.label}`}
          />
        ) : (
          <img
            alt="cqr"
            style={{ border: "#E6E7E8 2px solid", maxWidth: "100%" }}
            src={`${apiUrl}assets/${assetId}/qrCode?urlBase=https://qr.locatorx.com&target=lxproductscan&eccLevel=${eccLevel}&magnification=${magnification}&version=${version}`}
          />
        )}
      </CardContent>
    </Card>
  );
}
