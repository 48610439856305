import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index, label) {
  return {
    id: label,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    margin: "auto",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  scroller: {},
  wrapper: {
    alignItems: "flex-start",
  },
  indicator: {
    backgroundColor: "#3D5BA9",
  },
  selected: {
    // border: "2px solid #3D5BA9",
    "&:focus": {
      // outline is same syntax as border, so you could do "2px solid #3D5BA9" etc.
      outline: "none",
    },
  },
}));

export default function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const children =
  //   props.children && props.children.length > 1
  //     ? props.children.filter((item) => item !== null) || []
  //     : props.children;

  return (
    <div className={classes.root} id="hey" style={props.containerStyle || {}}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={props.value || value}
        onChange={props.handleChange || handleChange}
        className={classes.tabs}
        centered={false}
        classes={{
          indicator: classes.indicator,
          scroller: classes.scroller,
        }}
      >
        {props.children
          .filter((child) => child !== null)
          .map((child, idx) => (
            <Tab
              id={child.props.label}
              label={child.props.label}
              {...a11yProps(idx, child.props.label)}
              key={`${idx} - ${child.props.label}`}
              icon={child.props.icon ? child.props.icon : null}
              classes={{
                selected: classes.selected,
                wrapper: props.alignLeft ? classes.wrapper : "",
              }}
            />
          ))}
      </Tabs>

      {props.children
        .filter((child) => child !== null)
        .map((child, idx) => (
          <TabPanel
            value={props.value || value}
            index={idx}
            key={`${idx} - ${child.props.label}`}
            style={props.panelStyle || {}}
          >
            {child}
          </TabPanel>
        ))}
    </div>
  );
}
