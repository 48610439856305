import _ from "lodash";
import { BrandColorSwatches } from "../../../styles/BrandColors";
import { uniq } from "lodash";
import moment from "moment";

const defaultColorsMap = {
  0: BrandColorSwatches[0][0],
  1: BrandColorSwatches[0][2],
  2: BrandColorSwatches[1][0],
  3: BrandColorSwatches[1][2],
  4: BrandColorSwatches[2][0],
  5: BrandColorSwatches[2][2],
  6: BrandColorSwatches[3][0],
};

export const enumerateDaysBetweenDates = (
  startDate,
  endDate,
  dateSelection
) => {
  var dates = [];
  var currDate = startDate;
  var lastDate = endDate;

  // inclusive first day
  dates.push(currDate.clone().valueOf());

  const diff = lastDate.clone().diff(currDate.clone(), "days");

  switch (dateSelection) {
    case "daily":
      while (currDate.add(1, "days").diff(lastDate) < 0) {
        dates.push(currDate.clone().valueOf());
      }
      break;
    case "weekly":
      while (currDate.add(1, "weeks").diff(lastDate) < 0) {
        dates.push(currDate.clone().valueOf());
      }
      break;
    case "monthly":
      while (currDate.add(1, "months").diff(lastDate) < 0) {
        dates.push(currDate.clone().valueOf());
      }
      break;
    case "custom":
      if (diff <= 14) {
        while (currDate.add(1, "days").diff(lastDate) < 0) {
          dates.push(currDate.clone().valueOf());
        }
      } else if (diff <= 90) {
        while (currDate.add(1, "weeks").diff(lastDate) < 0) {
          dates.push(currDate.clone().valueOf());
        }
      } else {
        while (currDate.add(1, "months").diff(lastDate) < 0) {
          dates.push(currDate.clone().valueOf());
        }
      }

      break;
    default:
      break;
  }

  // inclusive last day
  dates.push(endDate.valueOf());
  return dates;
};

export const formatFacetQueryResponse = (
  res,
  filters,
  eventTypesMap
) => {
  // event memo is for indexing events and correlating with the default colors
  let eventMemo = {};
  // construct eventDateCountArray for graph, this holds each date interval and count for each event
  const eventDateCountArray = res
    .map((k) => {
      const event = k.event

      const count = k.value;
      const dateIntervalMap = {
        startDate: moment
          .tz(k.gte, filters.timeZone)
          .format("MM/DD/YYYY"),

        endDate: moment
          .tz(k.lte, filters.timeZone)
          .format("MM/DD/YYYY"),
      };
      eventMemo[event] = true;
      const eventMemoArr = Object.keys(eventMemo);
      const eventIdx = eventMemoArr.indexOf(event);

      return {
        event,
        count,
        dateIntervalMap,
        color: filters.customColors
          ? eventTypesMap[event].color || ""
          : defaultColorsMap[eventIdx],
      };
    })
    // sort to make sure we are preserving date/time order
    .sort(
      (a, b) =>
        +new Date(a.dateIntervalMap.startDate) -
        +new Date(b.dateIntervalMap.startDate)
    );

  // building event totals object with aggregated counts for each event for menu
  const eventTotals = eventDateCountArray.reduce((x, y) => {
    return {
      ...x,
      [y.event]: {
        event: y.event,
        color: y.color,
        // aggregate counts
        count: (x[y.event]?.count || 0) + y.count,
      },
    };
  }, {});

  const intervals = eventDateCountArray.reduce((x, y) => {
    const dataArray = x[y.event]?.data || [];
    dataArray.push(y.count);

    return {
      ...x,
      [y.event]: {
        backgroundColor: y.color,
        label: y.event,
        data: dataArray,
      },
    };
  }, {});

  // construct unique date interval labels, sorting by date, reformatting with moment
  const intervalLabels = uniq(
    eventDateCountArray
      .map((interval) => interval.dateIntervalMap)
      .sort((a, b) => +new Date(a.startDate) - +new Date(b.startDate))
      .map((interval) => `${interval.startDate} - ${interval.endDate}`)
  ).map((label) => {
    const splitLabel = label.split(" - ");

    if (splitLabel[0] === splitLabel[1] || filters.dateSelection === "daily") {
      return `${moment(splitLabel[0], "MM/DD/YYYY").format("MM/DD")}`;
    }
    if (filters.dateSelection === "weekly") {
      return [
        `Week of`,
        `${moment(splitLabel[0], "MM/DD/YYYY").format("MM/DD")}`,
      ];
    }
    if (filters.dateSelection === "monthly") {
      // add a couple days, set to start of month to account for leap years and end dates that fall on the last days of the month
      return moment(splitLabel[0], "MM/DD/YYYY")
        .add(2, "day")
        .startOf("month")
        .format("MMM Y");
    }

    return [`${splitLabel[0]} to`, splitLabel[1]];
  });

  const dataSets = Object.keys(intervals).map((interval) => {
    return intervals[interval];
  });

  return {
    eventTotals,
    intervals: dataSets,
    intervalLabels: intervalLabels,
  };
};

export const facetQueryEvents = async (
  props,
  filters,
  eventTypesMap
) => {
  const { apiUrl, token, organizationId } = props;
  let { startDate, endDate, events, dateSelection, timeZone } = filters;
  const csmToken =
    process.env.REACT_APP_CUSTOM_NODE_ENV === "production"
      ? "8b5f49a4-3694-4fa2-ae75-47a009c6921d"
      : process.env.REACT_APP_CUSTOM_NODE_ENV === "staging"
      ? "4e271452-e6dd-4072-809e-42f62387c1aa"
      : "84eab48e-c0c7-48cc-9612-3ffe159d5e15";

  // IMPORTANT
  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  startDate = moment(startDate).isValid()
    ? moment.tz(startDate, timeZone).startOf("day").utc()
    : null;
  endDate = moment(endDate).isValid()
    ? moment.tz(endDate, timeZone).endOf("day").utc()
    : null;

  const dates = enumerateDaysBetweenDates(startDate, endDate, dateSelection);
  const queryList = events.map((event) => {
    return dates
      .map((date, dateIdx) => {
        if (dates[dateIdx + 1]) {
          return {
            event: event.value,
            gte: date,
            lte: dates[dateIdx + 1],
          };
        } else return null;
      })
      .filter((el) => el !== null);
  });

  // TODO - Elastic - Waiting for Email on how to properly do facet queries in ES

  const fetchEventCounts = queryList.map(async (item) => {
    const fetchDateCounts = item.map(async (secondItem) => {
      let elasticQuery = {
        csmToken: csmToken,
        elasticSearchQuery: {
          bool: {
            must: [
              {
                term: {
                  event: secondItem.event,
                },
              },
              {
                range: {
                  time_of_log: {
                    gte: secondItem.gte,
                    lte: secondItem.lte,
                  },
                },
              },
              {
                term: {
                  organization_id: organizationId,
                },
              },
            ],
          },
        },
      };

      const fetchCount = await fetch(
        `${apiUrl}assetHistories/groupCount/category`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": token,
          },
          body: JSON.stringify(elasticQuery),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res?.success) {
            const value = _.isEmpty(res?.count?.category_agg)
              ? 0
              : res?.count?.category_agg[
                  Object.keys(res?.count?.category_agg)[0]
                ];
            return {
              event: secondItem.event,
              lte: secondItem.lte,
              gte: secondItem.gte,
              value,
            };
          }
          return res;
        })
        .catch((err) => {
          return {
            error: err,
          };
        });

      return fetchCount;
    });

    const results2 = await Promise.all(fetchDateCounts).then((res) => res);
    return results2;
  });
  const results = await Promise.all(fetchEventCounts).then((res) => res.flat());
  
  return formatFacetQueryResponse(results, filters, eventTypesMap);
};

const browserTimeZone = moment.tz.guess();

export const date_interval_util = (selection, timeZone) => {
  const tz = timeZone?.value || timeZone || browserTimeZone;

  const currentTime = moment().tz(tz);
  const mutateTime = moment().tz(tz);

  let startDate = null;
  let endDate = null;

  switch (selection) {
    case "daily":
      endDate = currentTime;
      startDate = mutateTime.subtract(2, "week");
      break;
    case "weekly":
      endDate = currentTime.endOf("week");
      startDate = mutateTime.subtract(12, "week").startOf("week");
      break;
    case "monthly":
      endDate = currentTime.endOf("month");
      startDate = mutateTime.subtract(12, "month").startOf("month");
      break;
    default:
      break;
  }

  return {
    startDate: new Date(startDate.format()),
    endDate: new Date(endDate.format()),
  };
};
