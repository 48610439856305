import { useState } from "react";
import { AppBar, Toolbar, Avatar, Menu, MenuItem } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { isEqual, cloneDeep } from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AddAssetButton from "./AddAssetButton";
import AssetSelector from "../../Selects/AssetSelector/AssetSelector";
import NotificationsByEventType from "../../Widgets/Notifications/NotificationsByEventType";
import QuickLinks from "./QuickLinks";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: (props) => ({
      background: "#fafafa",
      padding: theme.spacing(1),
      height: props.appBarHeight,
      width: `calc(100% - ${props.sideNavWidth}px)`,
      marginLeft: props.sideNavWidth,
      justifyContent: "center",
    }),

    buttonContainer: {
      display: "flex",
      alignItems: "center",
    },

    line: {
      borderLeft: "1px solid #32355c",
      height: "45px",
      paddingRight: "15px",
    },

    toolbarContainer: {
      display: "flex",
      justifyContent: "space-between",
    },

    avatar: {
      cursor: "pointer",
      backgroundColor: "#3D5BA9",
      "&:hover": {
        backgroundColor: "#5884F5",
      },
    },
    menuPaper: {
      backgroundColor: "#f9f9f9",
      marginTop: "10px",
    },
    menuItem: {
      backgroundColor: "#f9f9f9",
      color: "#383837",
      "&:hover": {
        color: "white",
        backgroundColor: "#5884f5",
      },
    },
    reactSelect: {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: "Lato",
      fontSize: "1rem",
      marginLeft: "auto",
      marginRight: "16px",
      width: "40%",
    },
  })
);

export default function AppBarLayout(props) {
  const {
    sideNavWidth = 120,
    appBarHeight = 64,
    apiUrl = "",
    organizationId,
    token: authToken,
  } = props;

  const initials =
    localStorage.getItem("firstName")?.charAt(0).toUpperCase() &&
    localStorage.getItem("lastName")?.charAt(0).toUpperCase()
      ? `${localStorage
          .getItem("firstName")
          ?.charAt(0)
          .toUpperCase()}${localStorage
          .getItem("lastName")
          ?.charAt(0)
          .toUpperCase()}`
      : null;

  const classes = useStyles({ sideNavWidth, appBarHeight });
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userRoles = useSelector((state) => state.user.userActions);

  const appUserType = useSelector((state) => state.user.appUserType);

  const userPropertiesMap = cloneDeep(
    useSelector((state) => state.user.userPropertiesMap, isEqual)
  );

  const customs = useSelector((state) => state.organization.customs, isEqual);

  const organization = useSelector(
    (state) => state.organization.organization,
    isEqual
  );
  const classifications = useSelector(
    (state) => state.organization.classifications,
    isEqual
  );
  const facilities = useSelector(
    (state) => state.organization.facilities,
    isEqual
  );

  const usersMap = useSelector((state) => state.organization.usersMap, isEqual);
  const usersConsoleRole = useSelector((state) => state.user.usersConsoleRole);

  const userId = useSelector((state) => state.user.appUserId);
  const token = useSelector((state) => state.user.token);
  const timeZone = useSelector(
    (state) => state.user.userPropertiesMap.propertiesMap.timeZone,
    isEqual
  );
  const batches = useSelector((state) => state.organization.batches, isEqual);
  const products = useSelector((state) => state.organization.products, isEqual);

  const extraProps = {
    apiUrl,
    appUserType,
    batches,
    classifications,
    customs,
    facilities,
    organization,
    organizationId: organization.organizationId,
    products,
    timeZone,
    token,
    userId,
    userPropertiesMap,
    usersConsoleRole,
    usersMap,
  };

  // We need to come up with a better way of dealing with this.
  const showAddAssetButton =
    userRoles["Create Assets"] &&
    appUserType !== "Product" &&
    appUserType !== "Inventory" &&
    appUserType !== "Inventory/Product";

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbarContainer}>
        <QuickLinks />
        {/* <AssetSelect /> */}
        <div className={classes.reactSelect}>
          <AssetSelector
            apiUrl={apiUrl}
            classifications={classifications}
            isMulti={false}
            onChange={(e) => {
              if (e) {
                if (e.batchId) {
                  history.push(
                    `/batches/${e.batchId}/history/${
                      e.assetType ? e.assetType : ""
                    }`
                  );
                } else {
                  history.push(`/assetSnapshot/${e.value}`);
                }
              }
            }}
            organizationId={organizationId}
            showCheckboxes={false}
            showLabel={false}
            token={authToken}
          />
        </div>

        {appUserType === "Product" ? null : (
          <NotificationsByEventType
            {...extraProps}
            useWidget={true}
            appUserType={appUserType}
          />
        )}

        {/* Only visible for asset or product/asset users */}
        {showAddAssetButton ? <AddAssetButton {...extraProps} /> : null}

        <Avatar onClick={(e) => handleClick(e)} className={classes.avatar}>
          {initials}
        </Avatar>
        <Menu
          classes={{ paper: classes.menuPaper }}
          id="simple-menu"
          autoFocus={false}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              history.push("/settings/user");
              handleClose();
            }}
          >
            View Account Information
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              history.push("/logout");
              handleClose();
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
