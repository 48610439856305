import { centroidPreflightCheck, polygonPreflightCheck } from "./preflight";

export const putCentroid = async (props, centroid) => {
  const { apiUrl, token, facilityId } = props;
  const { latitude, longitude, radius } = centroid;

  if (!latitude || !longitude || !radius) {
    return {
      error: "No latitude, longitude, or radius provided.",
    };
  }
  const centroidString = `POINT(${longitude.value.toFixed(
    5
  )} ${latitude.value.toFixed(5)})`;
  // putting to the centroid for one of the three fields will populate the other fields in the appropraite format. Using PointString for simplicity.
  const payload = {
    centroidString: centroidString,
    radius: radius / 1000,
  };

  const results = await centroidPreflightCheck(
    { ...props },
    {
      centroidString: centroidString,
      radius: radius / 1000,
      facilityId: facilityId,
    }
  ).then(async (preFlight) => {
    if (preFlight.error) {
      return {
        error: preFlight.error,
      };
    } else {
      return await fetch(`${apiUrl}facilities/${facilityId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return {
            error: err,
          };
        });
    }
  });

  return results;
};

export const putPolygon = async (props, polygon) => {
  const { apiUrl, token, facilityId } = props;
  const payload = {
    polygonGeoJSON: {
      crs: {
        type: "name",
        properties: {
          name: "EPSG:4326",
        },
      },
      type: "Polygon",
      coordinates: polygon.features.geometry?.coordinates || [],
    },
  };

  const polygonString = polygon.features.geometry?.coordinates[0]
    ? polygon.features.geometry?.coordinates[0]
        .map((cor, idx) => {
          return `${cor[0].toFixed(6)} ${cor[1].toFixed(6)}`;
        })
        .join(", ")
    : null;

  const results = await polygonPreflightCheck(
    { ...props },
    {
      polygonString: polygonString,
      facilityId: facilityId,
    }
  ).then(async (preFlight) => {
    if (preFlight.error) {
      return {
        error: preFlight.error,
      };
    } else {
      return await fetch(`${apiUrl}facilities/${facilityId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log(err);
          return { error: err };
        });
    }
  });

  return results;
};
