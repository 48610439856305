import { useSelector, useDispatch } from "react-redux";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import { hideAlert } from "../../globalStore/slices/alert/alertSlice";

import MaterialConfirmationModal from "../Modals/MaterialConfirmationModal";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100vh",
      fontSize: "1rem",
      lineHeight: "1.5",
      overflow: "auto",
    },
    content: {
      minHeight: "100vh",
      flexGrow: 1,
      // padding: theme.spacing(3),
    },
  })
);

export default function NoAuthLayout(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const alert = useSelector((state) => state.alert);

  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <main className={classes.content}>
          <MaterialConfirmationModal
            closeModal={() => {
              dispatch(hideAlert({}));
            }}
            content={alert.text}
            modalOpen={alert.show}
            severity={alert.type || "error"}
          />
          {props.children ? props.children : null}
        </main>
      </div>
    </>
  );
}
