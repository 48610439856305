export const retrieveAssetCategories = async (props) => {
  const { apiUrl, token } = props;
  const results = await fetch(`${apiUrl}assets/groupCount/category`, {
    method: "POST",
    headers: {
      "auth-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });
  return results;
};
