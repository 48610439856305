import { Grid, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import moment from "moment-timezone/builds/moment-timezone-with-data";

const useStyles = makeStyles((theme) => ({
  notificationContainer: {
    border: "1px solid #dee2e6",
    display: "flex",
    justifyContent: "space-between",
    padding: ".5rem",
  },
  link: { display: "flex", width: "100%" },
  sent: {
    width: "25%",
  },
  newButton: {
    borderRadius: ".25rem",
    backgroundColor: "#3d5ba9",
    color: "#fff",
    boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)!important",
    marginRight: "1rem",
    marginLeft: "auto",
    width: "100%",
    textAlign: "center",
  },
  textDanger: {
    color: "#dc3545",
  },
  textWarning: {
    color: "#ffc107",
  },
}));

function NotificationBar(props) {
  const {
    dismissNotification,
    isNew = true,
    propertiesMap = {},
    status = "",
    timeZone,
    userNotificationId,
  } = props;
  const classes = useStyles();
  const {
    assetId,
    assetType,
    assetTag,
    batchId = "",
    timeOfLog,
    timeOfLogLong,
    triggerMap = {},
  } = propertiesMap;

  const { event } = triggerMap;

  const usersTimeAndDate = timeOfLogLong
    ? moment
        .utc(timeOfLogLong, "x")
        .tz(timeZone)
        .format("YYYY-MM-DD hh:mm:ss a")
    : moment
        .utc(timeOfLog, "YYYY-MM-DD HH:mm")
        .tz(timeZone)
        .format("YYYY-MM-DD hh:mm a") || "DNP";

  return (
    <Grid item xs={12} style={{ padding: "1rem" }}>
      <div className={`${classes.notificationContainer} alertOne pointer`}>
        <Link
          className={classes.link}
          to={`${
            !batchId
              ? `/assetSnapshot/${assetId}`
              : `/batches/${batchId}/history/${assetType}`
          }`}
          onClick={() => {
            dismissNotification(userNotificationId, "read");
          }}
        >
          {isNew ? (
            <div
              className={`${status === "sent" ? classes.sent : ""}`}
              style={{ marginBottom: "auto" }}
            >
              {status === "sent" ? (
                <div className={classes.newButton}>
                  <div
                    className="alert-icon"
                    style={{ fontWeight: "bold", marginBottom: "auto" }}
                  >
                    NEW
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          <div style={{ marginLeft: ".3rem", width: "75%" }}>
            {assetTag} {event} {usersTimeAndDate}
          </div>
        </Link>
        <div style={{ marginTop: "auto", marginBottom: "auto" }}>
          <button
            className="close-button"
            type="button"
            onClick={() => {
              dismissNotification(userNotificationId, "dismissed");
            }}
          >
            <i
              className={`fas fa-times-circle ${
                isNew ? classes.textDanger : classes.textWarning
              }`}
            />
          </button>
        </div>
      </div>
    </Grid>
  );
}

export default NotificationBar;
