import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Box, Grid, makeStyles, Paper } from "@material-ui/core";
import { getAssetProps, getTelemetry, saveFilterSettings } from "./api";
import { isEqual } from "lodash";
import { thunks } from "../../globalStore/slices/assetHistory/assetHistorySlice";
// import { State } from "./interface";
import { updateUserPropertiesMap } from "../../globalStore/slices/user/userSlice";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTreeView } from "./hooks/useTreeView";
import * as Tab from "./Tabs/index";
import HorizontalTabs from "../../components/Tabs/HorizontalTabs";
import Loading from "../../components/Loading/Loading";
import Map from "../../components/Maps/AssetHistoryMap/AssetHistoryMap";
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";
import ModalDialog from "../../components/Modals/ModalDialog/ModalDialog";

// Component uses the xlsx library which is very heavy , which is why we are using lazy/Suspense
const Table = lazy(() => import("./Table"));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100%",
    width: "100%",
    minHeight: "66vh",
    maxHeight: "100%",
  },
  pipe: {
    fontWeight: 100,
    borderRight: "1px #A6A8AB solid",
    margin: 10,
  },
  button: {
    fontWeight: "bold",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
}));

function AssetSnapshot(props) {
  const {
    apiUrl,
    appUserType,
    match,
    organizationId,
    timeZone,
    token,
    userId,
    usersConsoleRole,
  } = props;
  const { assetId = "" } = match.params;
  const history = useHistory();
  const { searchHistories } = thunks.assetHistory;

  const dispatchGlobal = useDispatch();
  // OK to destructure organization data because it's not going to change in the snapshot page
  const {
    facilities,
    devices,
    classifications,
    organization,
    zones,
    usersMap,
  } = useSelector((state) => state.organization, isEqual);

  const assetSnapshotSettings = useSelector(
    (state) => state.user.userPropertiesMap.propertiesMap?.assetSnapshotSettings
  );

  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [infoModal, setInfoModal] = useState({
    content: "",
    modalShow: false,
    title: "",
  });

  const modalClose = () => {
    setModal({
      ...modal,
      modalShow: false,
    });
    setInfoModal({
      ...infoModal,
      modalShow: false,
    });
  };

  const [modal, setModal] = useState({
    modalShow: false,
    text: "",
    isError: false,
  });

  // Array.from() creates a new reference from the read-only redux state objects
  const assetTypes =
    organization && organization.assetTypes
      ? Array.from(organization.assetTypes).sort()
      : [];

  // If the user is NOT an admin, we remove the Tag Destroyed event
  const eventTypes = organization?.eventTypesMap
    ? Object.keys(organization.eventTypesMap)
        .sort()
        .filter((item) => {
          if (usersConsoleRole !== "Admin") {
            return item !== "Tag Destroyed";
          }
          return item;
        })
    : [];

  const facilityArray = facilities
    ? Object.values(facilities).sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return -1;
      })
    : [];

  // The "limit", "start", and "sort" filters are controlled from the "rows", "page" index, and column sorting headers on the table itself, not the filter drop down
  const initFilters = {
    assetId: "",
    assetIds: null,
    binLocation: null,
    defaultColumnOrder: [],
    endDate: null,
    events: null,
    hideScans: true,
    limit: 25,
    locals: null,
    locations: null,
    proximity: false,
    startDate: null,
    tz: timeZone,
    users: null,
    sorted: [
      {
        id: "timeOfLog",
        desc: true,
      },
    ],
    zones: null,
    // spreading the saved filter settings under the default init filters will override any of the defaults, if they are present in the settings
    ...(assetSnapshotSettings || {}),
  };

  const deviceLogInitFilters = {
    devices: null,
    limit: 25,
  };

  const [state, setState] = useState({
    filters: {
      ...initFilters,
      start: 0,
      tz: timeZone,
    },
    deviceLogFilters: {
      ...deviceLogInitFilters,
      start: 0,
      tz: timeZone,
    },
    devicePage: 0,
    page: 0,
    lists: {
      assetCategories: assetTypes,
      eventTypes: eventTypes,
      facilityArray: facilityArray,
      eventTypesMap: organization.eventTypesMap,
      usersMap: usersMap,
    },
    assetData: {},
    activeTab: 0,
    availableZones: Object.keys(zones).map((zone) => {
      // TODO: Is this how we are going to handle the differences between
      // pZones and tZones?
      return {
        internalZoneType: zones[zone].internalZoneType || null,
        label: zones[zone].name,
        value: zones[zone].zoneId,
      };
    }),
    mounted: false,
    deviceData: {},
    showDeviceTab: false,
  });

  // first effect, get asset props and set mounted to true
  useEffect(() => {
    getAssetProps({ apiUrl, token, organizationId }, assetId).then((res) => {
      if (res.error) {
        setModal({
          modalShow: true,
          text: `Uh-oh! Something went wrong while fetching asset data... ${res.error}`,
          isError: true,
        });
      } else {
        const parentId = res.asset ? res.asset.parentId : "";
        // TODO: Insert the device call here and return the device data with the setState
        // initialize state, changing the state.filters will fire useEffect to retrieve data

        const device =
          Object.values(devices).find(
            (element) => element.assetId === parentId
          ) || {};

        const showDeviceTab = device ? true : false;
        setState((prevState) => {
          return {
            ...prevState,
            assetData: {
              ...res.asset,
            },
            mounted: true,
            deviceData: device.propertiesMap,
            showDeviceTab,
          };
        });
      }
    });

    return () => {
      // Resets the users Filters
      setState((prevState) => ({
        ...prevState,
        filters: {
          assetIds: null,
          endDate: null,
          events: null,
          locals: null,
          locations: null,
          pca: false,
          startDate: null,
          users: null,
        },
        mounted: false,
        assetData: {},
      }));
    };
  }, [apiUrl, organizationId, userId, token, assetId, devices]);

  const hasTelemetry = devices[state?.assetData.parentId] || false;

  // refs work to control our runaway use effects... if the history or device filters have changed, it will fire an effect that searches for data.
  // In the effects, we check to make sure that the previous filters are different than the incoming filters by using refs.
  // If they are different, we then reset the ref.current to the incoming filters. That way, we can control runaway use effects.
  const prevFiltersRef = useRef();
  const prevDeviceFiltersRef = useRef();

  const locationRef = useRef(props.history.location.pathname);
  const pathName = props.history.location.pathname;

  // Certain elements need to changed depending on which route the user comes in on.
  // In thise case 'inventoryStatus'. The Map disapears / Certain tabs do not render
  const isInventorySnapshot =
    history?.location?.pathname?.split("/")[1] === "inventorySnapshot";

  useEffect(() => {
    if (
      !isEqual(state?.filters, prevFiltersRef.current) ||
      !isEqual(pathName, locationRef.current)
    ) {
      prevFiltersRef.current = state?.filters;
      setLoading(true);
      dispatchGlobal(
        searchHistories({
          assetId,
          filters: state?.filters,
          isExport: false,
          page: state?.page,
        })
      ).then((res) => {
        if (res.error) {
          setModal({
            modalShow: true,
            text: `Uh-oh! Something went wrong while fetching asset data... ${res.error}`,
            isError: true,
          });
          setLoading(false);
        } else {
          setState((prevState) => {
            locationRef.current = pathName;
            return {
              ...prevState,
              page: Math.floor(
                prevState.filters.start / prevState.filters.limit
              ),
              histories: res.payload,
            };
          });
          setLoading(false);
        }
      });
    }
  }, [
    pathName,
    assetId,
    apiUrl,
    token,
    organizationId,
    userId,
    state?.filters,
    state?.page,
    dispatchGlobal,
    searchHistories,
  ]);

  // Seperated this useEffect, that way this only fires off when the device log table filters change.
  useEffect(() => {
    if (
      (hasTelemetry &&
        (!isEqual(state?.deviceLogFilters, prevDeviceFiltersRef.current) ||
          !isEqual(pathName, locationRef.current))) ||
      (hasTelemetry && !state.telemetryInit)
    ) {
      prevDeviceFiltersRef.current = state.deviceLogFilters;
      getTelemetry(
        { apiUrl, organizationId },
        state?.assetData?.parentId,
        state?.deviceLogFilters,
        state?.page
      ).then((res) => {
        if (res.error) {
          setModal({
            modalShow: true,
            text: `Uh-oh! Something went wrong while fetching asset data... ${res.error}`,
            isError: true,
          });
        } else {
          setState((prevState) => {
            locationRef.current = pathName;
            return {
              ...prevState,
              page: Math.floor(
                prevState.deviceLogFilters.start /
                  prevState.deviceLogFilters.limit
              ),
              deviceTelemetry: res,
              telemetryInit: true,
            };
          });
        }
      });
    }
  }, [
    apiUrl,
    devices,
    hasTelemetry,
    organizationId,
    pathName,
    state?.assetData?.parentId,
    state?.page,
    state.deviceLogFilters,
    state.telemetryInit,
  ]);

  const handleSave = (filters) => {
    saveFilterSettings({ apiUrl, token, userId }, filters).then((res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        const { appUser = {} } = res;
        const { propertiesMap = {} } = appUser;
        const { assetSnapshotSettings = {} } = propertiesMap;

        dispatchGlobal(
          updateUserPropertiesMap({
            assetSnapshotSettings,
          })
        );
      }
    });
  };

  // CustomControl is to help manage which tab is selected on the tabbed tables
  const customControl = (e, index) => {
    setState((prevState) => ({ ...prevState, activeTab: index }));
  };

  // custom hook for building hiearchy tree, see ./hooks/
  const treeView = useTreeView({
    buttonClass: classes.button,
    pipeClass: classes.pipe,
    facilities,
    history,
    assetId,
    organizationId,
    apiUrl,
    token,
    zones,
    assetData: state.assetData,
    mounted: state.mounted,
  });

  return (
    <Box className={classes.root} mt={4}>
      {state && state.mounted ? (
        <Grid container spacing={3}>
          <ModalDialog
            handleClose={modalClose}
            open={infoModal.modalShow}
            title={infoModal.title}
            content={
              <Grid container>
                <Grid item xs={12}>
                  {infoModal.content}
                </Grid>
              </Grid>
            }
          />

          {/* Map */}
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <Map
                eventTypesMap={
                  state.lists.eventTypesMap ? state.lists.eventTypesMap : {}
                }
                facilities={facilities}
                state={state}
                style={{
                  border: "rgba(50, 53, 93, 0.514) solid 2px",
                  borderRadius: "4px",
                }}
                timeZone={timeZone}
              />
            </Paper>
          </Grid>

          {/* Details */}
          <Grid item xs={12} sm={8}>
            <Paper className={classes.paper}>
              <HorizontalTabs>
                <Tab.Overview
                  assetData={state.assetData}
                  classifications={classifications}
                  eventTypesMap={organization.eventTypesMap}
                  facilities={facilities}
                  isInventorySnapshot={isInventorySnapshot}
                  label="Inventory Snapshot"
                  setState={setState}
                  state={state}
                />

                {isInventorySnapshot ? null : (
                  <Tab.Hierarchy
                    label="Hierarchy Snapshot"
                    assetData={state.assetData}
                    token={token}
                    apiUrl={apiUrl}
                    treeView={treeView}
                    organizationId={organizationId}
                  />
                )}

                {usersConsoleRole === "Lite" || isInventorySnapshot ? null : (
                  <Tab.EditAsset
                    apiUrl={apiUrl}
                    assetData={state.assetData}
                    classifications={classifications}
                    facilities={facilities}
                    label={"Edit Asset"}
                    organization={organization}
                    setModal={setModal}
                    setState={setState}
                    state={state}
                    token={token}
                    userId={userId}
                  />
                )}

                {usersConsoleRole === "Lite" ? null : (
                  <Tab.Update
                    state={state}
                    setState={setState}
                    assetData={state.assetData}
                    setModal={setModal}
                    eventTypesMap={organization.eventTypesMap}
                    eventTypes={state.lists.eventTypes}
                    facilities={facilities}
                    apiUrl={apiUrl}
                    token={token}
                    userId={userId}
                    label="Update Event"
                  />
                )}

                {hasTelemetry || !isInventorySnapshot ? (
                  <Tab.DeviceOverview
                    assetData={state.assetData}
                    devices={devices}
                    facilities={facilities}
                    label="Device Overview"
                    state={state}
                    timeZone={state.filters.tz ? state.filters.tz : ""}
                  />
                ) : null}
              </HorizontalTabs>
            </Paper>
          </Grid>

          {/* Table */}
          <Grid item xs={12}>
            {loading ? <Loading color="#5884F5" /> : null}{" "}
            <Suspense fallback={<Loading color="#5884F5" />}>
              <Table
                activeTab={state.activeTab}
                apiUrl={apiUrl}
                appUserType={appUserType}
                assetData={state.assetData}
                assetId={assetId}
                customControl={customControl}
                defaultColumnOrder={state.filters.defaultColumnOrder}
                devices={devices}
                dispatchGlobal={dispatchGlobal}
                eventTypes={state.lists.eventTypes}
                eventTypesMap={organization.eventTypesMap}
                facilities={facilities}
                facilityArray={state.lists.facilityArray}
                handleSave={handleSave}
                hasTelemetry={hasTelemetry}
                isInventorySnapshot={isInventorySnapshot}
                organizationId={organizationId}
                searchHistories={searchHistories}
                setInfoModal={setInfoModal}
                setLoading={setLoading}
                setModal={setModal}
                setState={setState}
                state={state}
                timeZone={state.filters.tz ? state.filters.tz : ""}
                token={token}
                treeView={treeView}
                userId={userId}
                usersMap={usersMap}
                zones={zones}
              />
            </Suspense>
          </Grid>
        </Grid>
      ) : (
        <Loading color="#5884F5" opaque={true} />
      )}
      <MaterialConfirmationModal
        content={modal.text}
        closeModal={() => {
          setModal({ ...modal, modalShow: false });
        }}
        modalOpen={modal.modalShow}
        severity={modal.isError ? "error" : "success"}
        variant="filled"
      />
    </Box>
  );
}

export default AssetSnapshot;
