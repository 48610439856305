import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { showAlert } from "../../../globalStore/slices/alert/alertSlice";
import { updateFacilities } from "../../../globalStore/slices/organization/organizationSlice";
import { Tooltip } from "@material-ui/core";
import ConfirmationModalContent from "../../../components/Modals/ConfirmationModalContent";
import CreateFacility from "./CreateFacility/CreateFacility";
import EditFacility from "./EditFacility/EditFacility";
import FacilityArea from "./FacilityArea/FacilityArea";
import FacilityDetail from "./FacilityDetail/FacilityDetail";
import FacilityTable from "./FacilityTable/FacilityTable";
import Loading from "../../../components/Loading/Loading";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";

// Component uses xlxs which is very heavy, which is why we are using lazy/Suspense
const ImportLocations = lazy(() => import("./ImportLocations/ImportLocations"));

export default function Facilities(props) {
  const {
    apiUrl,
    facilities,
    dispatchGlobal,
    organization,
    permissions = {},
    setView = {},
    token,
  } = props;
  const [state, setState] = useState({
    facilities: [],
  });
  const [fullScreenModal, setFullScreenModal] = useState(false);
  const [dialog, setDialog] = useState({
    dialogTitle: "",
    dialogShow: false,
    selectedLocation: {},
  });
  const [redirect, setRedirect] = useState({
    isRedirect: false,
    selectedFacility: "",
  });

  const mounted = useRef(false);
  // first render useEffect, retrieve facilities; retrieve facilities function is memoized via useCallback so it can be used in the useEffect dep array without creating an infinite loop
  useEffect(() => {
    const { state: routerState = {} } = props.location;
    const { modalShow = false, modal = "" } = routerState;

    setState((prevState) => {
      return {
        ...prevState,
        facilities: Object.keys(facilities).map((k) => facilities[k]),
      };
    });

    if (modalShow) {
      setDialog({ dialogTitle: modal, dialogShow: modalShow });
    }

    mounted.current = true;
    // This controls the quickLinks and modal behavior
    return props.history.listen((location) => {
      setDialog({ dialogTitle: modal, dialogShow: modalShow });
    });
  }, [facilities, props.history, props.location]);

  const handleDelete = (id) => {
    fetch(`${apiUrl}facilities/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          // Here we remove the deleted facility from the existing array
          setState((prevState) => {
            let newFacilities = prevState.facilities.filter(
              (item) => item.facilityId !== id
            );
            return { ...prevState, facilities: newFacilities };
          });
          handleConfirmationModal("Facility Successfully Deleted");
          dialogClose();
        }
      })
      .catch((err) => {
        handleConfirmationModal("There was a problem", true);
      });
  };

  const handleConfirmationModal = (text, error) => {
    dispatchGlobal(
      showAlert({ type: error ? "error" : "success", text: text })
    );
  };

  const dialogClose = () =>
    setDialog({
      ...dialog,
      dialogShow: false,
      dialogTitle: "",
      selectedLocation: {},
    });

  const { createLocation } = permissions;

  const { dialogShow, selectedLocation, dialogTitle } = dialog;
  const { propertiesMap = {} } = organization;
  const { locationTypes = [] } = propertiesMap;

  const switchDialog = (dialog) => {
    switch (dialog) {
      case "Facility Detail":
        return (
          <FacilityDetail
            handleClose={() => {
              dialogClose();
            }}
            open={true}
            token={token}
            apiUrl={apiUrl}
            locationTypes={locationTypes}
            facility={selectedLocation}
            notificationModal={handleConfirmationModal}
          />
        );
      case "Facility Area":
        return (
          <FacilityArea
            token={token}
            apiUrl={apiUrl}
            facilityId={selectedLocation.facilityId}
            selectedLocation={selectedLocation}
            notificationModal={handleConfirmationModal}
            onHide={dialogClose}
            onSuccess={() => console.log("YOU NEED TO WORK ON THIS")}
            setFullScreenModal={(bool) => setFullScreenModal(bool)}
          />
        );
      case "Create Facility":
        return (
          <CreateFacility
            apiUrl={apiUrl}
            dispatchGlobal={dispatchGlobal}
            locationTypes={locationTypes}
            notificationModal={handleConfirmationModal}
            onHide={dialogClose}
            token={token}
            updateFacilities={updateFacilities}
          />
        );
      case "Import Facilities":
        return (
          <Suspense fallback={<Loading />}>
            <ImportLocations
              apiUrl={apiUrl}
              locationTypes={locationTypes}
              notificationModal={handleConfirmationModal}
              onHide={dialogClose}
              onSuccess={() => console.log("YOU NEED TO WORK ON THIS")}
              organization={organization}
              token={token}
            />
          </Suspense>
        );
      case "Edit Facility":
        return (
          <EditFacility
            apiUrl={apiUrl}
            dispatchGlobal={dispatchGlobal}
            facility={selectedLocation}
            locationTypes={locationTypes}
            match={{
              params: { facilityId: selectedLocation.facilityId },
            }}
            notificationModal={handleConfirmationModal}
            onHide={dialogClose}
            onSuccess={() => console.log("YOU NEED TO WORK ON THIS")}
            token={token}
            updateFacilities={updateFacilities}
          />
        );
      case "Delete Facility":
        return (
          <ConfirmationModalContent
            content={`Are you sure you want to delete ${selectedLocation.name}? This action cannot be
            undone.`}
            handleSubmit={() => {
              handleDelete(selectedLocation.facilityId);
            }}
            handleCancel={() => {
              dialogClose();
            }}
          />
        );
      default:
        return;
    }
  };

  return (
    <Grid container>
      <Box mt={3} width="100%">
        {redirect.isRedirect ? (
          <Redirect
            to={{
              pathname: "/facilities/overview",
              state: { selectedFacility: redirect.selectedFacility },
            }}
          />
        ) : null}
        <ModalDialog
          handleClose={dialogClose}
          open={dialogShow}
          title={`${dialogTitle}${
            selectedLocation?.name ? ` - ${selectedLocation?.name}` : ""
          }`}
          content={switchDialog(dialogTitle)}
          fullScreen={fullScreenModal}
        />
        <Grid item xs={12}>
          {/* Header */}
          <Box mt={4} mb={3} textAlign="left">
            <h3>Facilities</h3>
          </Box>
        </Grid>

        {/* Buttons Group */}
        <Grid item xs={12}>
          {createLocation ? (
            <Box
              display="flex"
              justifyContent="end"
              textAlign="right"
              width="100%"
            >
              <Box ml={1} mr={1}>
                {/* Create Facility */}
                <Tooltip title="Create a new facility based on an address or intersection of latitude and longitude coordinates. When creating a new facility, it will default to a 400m radius around the center point of the address or lat/long coordinates.  You will have the option of adjusting that radius or creating a polygon, showing the perimeter of your facility.  Your facility will be created using the default radius unless you adjust it. If you enter a polygon, that will override all radius usage entirely.">
                  <MaterialUiButton
                    label="Create Facility"
                    onClick={() =>
                      setDialog({
                        ...dialog,
                        dialogShow: true,
                        dialogTitle: "Create Facility",
                      })
                    }
                    size="small"
                  />
                </Tooltip>
              </Box>

              {/* Import Facilities */}
              <Box ml={1} mr={1}>
                <MaterialUiButton
                  label="Import Facilities"
                  onClick={() =>
                    setDialog({
                      ...dialog,
                      dialogTitle: "Import Facilities",
                      dialogShow: true,
                    })
                  }
                  size="small"
                />
              </Box>

              {/* Facilities Map */}
              <Box ml={1} mr={1}>
                <MaterialUiButton
                  label="Facilities Map"
                  onClick={() =>
                    setView({
                      viewFacilitiesMap: true,
                    })
                  }
                  size="small"
                />
              </Box>
            </Box>
          ) : null}
        </Grid>

        {/* Table */}
        <Grid item xs={12}>
          <FacilityTable
            state={state}
            diaog={dialog}
            setDialog={setDialog}
            setRedirect={setRedirect}
            permissions={permissions}
            setView={setView}
          />
        </Grid>
      </Box>
    </Grid>
  );
}
