import { useState } from "react";
import { createZone } from "../API/api";
import { Grid, makeStyles } from "@material-ui/core";
import { naturalSort } from "../../../../utils/naturalSort";
import MaterialUiButton from "../../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import SimpleSelect from "../../../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../../../components/Forms/FieldTypes/TextField";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

export default function CreateZone(props) {
  const {
    facility = {},
    notificationModal,
    onHide,
    onSuccess,
    zoneCategories,
    zoneHashMap,
    zones,
  } = props;
  const classes = useStyles();
  const [createState, setCreateState] = useState({
    abbreviation: "",
    category: "",
    description: "",
    error: {
      name: false,
    },
    floor: "",
    name: "",
    parentId: "",
    validated: false,
    zoneType: "",
  });
  const categorySelectOptions = [...zoneCategories]
    .sort((a, b) => naturalSort(a, b))
    .map((element, index) => ({
      label: element,
      value: element,
    }));

  const parentZoneSelectOptions = [...zones]
    .sort((a, b) => naturalSort(a.name, b.name))
    .map((element, index) => ({
      label: element.name,
      value: element.name,
    }));

  const {
    abbreviation,
    category,
    description,
    floor,
    name,
    parentId,
    zoneType,
  } = createState;

  const handleSubmit = () => {
    const {
      name,
      description,
      abbreviation,
      floor,
      category,
      zoneType,
      parentId,
    } = createState;

    const payload = {
      name,
      abbreviation,
      floor,
      category,
      parentId: zoneHashMap[parentId] ? zoneHashMap[parentId].zoneId : null,
      zoneType,
      propertiesMap: {
        description: description,
      },
      facilityId: facility.facilityId,
    };

    createZone(props, payload).then((createResponse) => {
      if (createResponse.success) {
        onSuccess(createResponse.zone);
        onHide();
        notificationModal(
          "Zone Successfully Created",
          false,
          "fas fa-check-circle",
          3000
        );
      } else {
        notificationModal(
          `${createResponse.error}`,
          true,
          "fas fa-times-circle",
          3000
        );
      }
    });
  };

  function validateFields() {
    let isValidated = true;
    const fieldsToValidate = [{ id: "name", value: name }];

    // If something is empty, we flip isValidated to false
    fieldsToValidate.forEach((element) => {
      const { id, value = "" } = element;
      if (value?.length === 0) {
        isValidated = false;

        setCreateState((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            [id]: true,
          },
        }));
      }
    });

    // If isValidated is never flipped, we move on
    if (isValidated) {
      handleSubmit();
    }
  }

  return (
    <Grid className={classes.root} container spacing={2}>
      {/* Zone Name */}
      <Grid item xs={6}>
        <SimpleTextField
          error={createState.error?.name}
          id="name"
          label="Zone Name"
          onChange={(event) => {
            setCreateState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, name: false },
              name: event.target.value,
            }));
          }}
          required
          value={name}
        />
      </Grid>

      {/* Zone Abbreviation  */}
      <Grid item xs={6}>
        <SimpleTextField
          id="abbreviation"
          label="Zone Abbreviation - optional"
          onChange={(event) => {
            setCreateState((prevState) => ({
              ...prevState,
              abbreviation: event.target.value,
            }));
          }}
          value={abbreviation}
        />
      </Grid>

      {/* Floor */}
      <Grid item xs={6}>
        <SimpleTextField
          id="abbreviation"
          label="Floor - optional"
          onChange={(event) => {
            setCreateState((prevState) => ({
              ...prevState,
              floor: event.target.value,
            }));
          }}
          value={floor}
        />
      </Grid>

      {/* Category */}
      <Grid item xs={6}>
        <SimpleSelect
          id="category"
          label="Category - optional"
          onChange={(event) => {
            setCreateState((prevState) => ({
              ...prevState,
              category: event.target.value,
            }));
          }}
          options={categorySelectOptions}
          value={category}
          variant="outlined"
        />
      </Grid>

      {/* Zone Type */}
      <Grid item xs={6}>
        <SimpleTextField
          id="zoneType"
          label="Zone Type - optional"
          onChange={(event) => {
            setCreateState((prevState) => ({
              ...prevState,
              zoneType: event.target.value,
            }));
          }}
          value={zoneType}
        />
      </Grid>

      {/* Parent Zone */}
      <Grid item xs={6}>
        <SimpleSelect
          id="parentId"
          label="Parent Zone - optional"
          onChange={(event) => {
            setCreateState((prevState) => ({
              ...prevState,
              parentId: event.target.value,
            }));
          }}
          options={parentZoneSelectOptions}
          value={parentId}
          variant="outlined"
        />
      </Grid>

      {/* Zone Description */}
      <Grid item xs={12}>
        <SimpleTextField
          id="abbreviation"
          label="Zone Description - optional"
          multiline
          onChange={(event) => {
            setCreateState((prevState) => ({
              ...prevState,
              description: event.target.value,
            }));
          }}
          rows={4}
          value={description}
        />
      </Grid>

      {/* Cancel and Submit Buttons */}
      <Grid className={classes.buttonContainer} item xs={12}>
        {/* Cancel Button */}
        <Grid item xs={4}>
          <MaterialUiButton
            color="cancel"
            fullWidth
            label="Cancel"
            onClick={() => {
              onHide();
            }}
            variant="outlined"
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={4}>
          <MaterialUiButton
            color="primary"
            fullWidth
            label="Submit"
            onClick={() => {
              validateFields();
            }}
            variant="contained"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
