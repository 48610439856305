import { Grid } from "@material-ui/core";
import AssetSelector from "../../../../Selects/AssetSelector/AssetSelector";
import BinSelector from "../../../../Selects/BinSelector/BinSelector";
import ClassificationFilters from "./ClassificationsFilters";
import DatePicker from "../../../../Forms/FieldTypes/DatePicker";
import DateSelectionRadios from "./DateSelectionRadios";
import LimitSlider from "./LimitSlider";
import ReactSelectCheckboxes from "../../../../Selects/ReactSelectCheckboxes";
import states from "./states.json";
import ZoomSlider from "./ZoomSlider";

export default function Standardfilters(props) {
  const {
    setFilters,
    filters,
    eventTypesArray,
    facilityArray,
    zonesArray,
    classifications,
    timeZone,
  } = props;

  const handleFilterChange = (update) => {
    setFilters((f) => {
      return {
        ...f,
        ...update,
      };
    });
  };

  return (
    <>
      <ZoomSlider filters={filters} setFilters={setFilters} />
      <LimitSlider filters={filters} setFilters={setFilters} />
      {/* Asset Tag */}
      <Grid item xs={6}>
        <AssetSelector
          {...props}
          onChange={(e) => {
            handleFilterChange({ assetIds: e });
          }}
          value={filters.assetIds}
        />
      </Grid>

      {/* Events */}
      <Grid item xs={6}>
        <label>Event</label>
        <ReactSelectCheckboxes
          id={"overviewMapEventFilter"}
          options={
            eventTypesArray && eventTypesArray.length
              ? [
                  ...eventTypesArray.map((type) => {
                    return {
                      label: type,
                      value: type,
                    };
                  }),
                ]
              : []
          }
          onChange={(e) => {
            handleFilterChange({ lastEvents: e });
          }}
          value={filters.lastEvents}
        />
      </Grid>

      {/* Facilities */}
      <Grid item xs={6}>
        <label style={{ marginTop: "1vh" }}>Facility Name</label>
        <ReactSelectCheckboxes
          id={"overviewMapFacilityFilter"}
          options={
            facilityArray && facilityArray.length
              ? [
                  ...facilityArray.map((facility) => {
                    return {
                      label: facility.name,
                      value: facility,
                    };
                  }),
                ]
              : []
          }
          onChange={(e) => {
            handleFilterChange({ locations: e, locale: null });
          }}
          value={filters.locations}
        />
      </Grid>

      {/* States */}
      <Grid item xs={6}>
        <label style={{ marginTop: "1vh" }}>State</label>
        <ReactSelectCheckboxes
          isDisabled={
            filters.locations === null ||
            filters.locations === undefined ||
            filters.locations.length === 0
              ? false
              : true
          }
          onChange={(e) => {
            handleFilterChange({ locals: e });
          }}
          options={states}
          value={filters.locals}
        />
      </Grid>

      {/* Bin Locations */}
      <Grid item xs={6}>
        <BinSelector
          {...props}
          onChange={(e) => {
            handleFilterChange({ binLocation: e || null });
          }}
          value={filters.binLocation || null}
        />
      </Grid>

      {/* Zones */}
      <Grid item xs={6}>
        <label style={{ marginTop: "1vh" }}>Zones</label>
        <ReactSelectCheckboxes
          options={zonesArray ? zonesArray : []}
          onChange={(e) => {
            handleFilterChange({ zones: e });
          }}
          value={filters.zones}
        />
      </Grid>

      <ClassificationFilters
        classifications={classifications}
        filters={filters}
        handleFilterChange={handleFilterChange}
      />

      <DateSelectionRadios
        filters={filters}
        setFilters={setFilters}
        timeZone={timeZone}
      />

      {/* Start Date */}
      <Grid item xs={6}>
        <label style={{ marginTop: "1vh" }}>Select Start Date</label>
        <DatePicker
          disabled={filters.dateSelection !== "custom"}
          format="yyyy/MM/DD"
          label="Start Date"
          onChange={(date) => {
            if (filters.endDate && date > filters.endDate) {
              alert("Can't set start date later than end date");
              return;
            }

            handleFilterChange({ startDate: new Date(date) });
          }}
          size="small"
          value={filters.startDate}
        />
      </Grid>

      {/* End Date */}
      <Grid item xs={6}>
        <label style={{ marginTop: "1vh" }}>Select End Date</label>
        <DatePicker
          disabled={filters.dateSelection !== "custom"}
          format="yyyy/MM/DD"
          label="End date"
          onChange={(date) => {
            if (filters.startDate && date < filters.startDate) {
              alert("Can't set end date earlier than start date");
              return;
            }
            handleFilterChange({ endDate: new Date(date) });
          }}
          size="small"
          value={filters.endDate}
        />
      </Grid>
    </>
  );
}
