import { makeStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  root: {
    margin: 0,
    minWidth: "600px",
    padding: theme.spacing(2),
  },
  title: {
    color: "#414348",
    fontFamily: "Lato",
    fontWeight: "bold",
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: "90%",
    maxWidth: "800px",
    overflowX: "hidden",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={`${classes.root} ${classes.title}`}
      {...other}
    >
      <Typography className={classes.title} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "8px 16px",
  },
}))(MuiDialogContent);

function ModalDialog(props) {
  const classes = useStyles();
  const {
    content,
    dialogActions,
    fullScreen,
    handleClose,
    isTabs,
    maxWidth,
    open,
    title,
  } = props;

  return (
    <Dialog
      aria-labelledby={title}
      classes={{ paper: classes.paper }}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      onClose={(e) => {
        e.preventDefault();
        handleClose();
      }}
      open={open}
    >
      {open ? (
        <>
          {!isTabs ? (
            <DialogTitle onClose={handleClose}>{title}</DialogTitle>
          ) : null}
          <DialogContent dividers>{content}</DialogContent>
          {dialogActions ? (
            <DialogActions>{dialogActions}</DialogActions>
          ) : null}
        </>
      ) : null}
    </Dialog>
  );
}

export default ModalDialog;
