import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Centroid from "./Centroid/Centroid";
import DrawPolygon from "./Polygon/DrawPolygon";
import Grid from "@material-ui/core/Grid";
import Instructions from "./Instructions/Instructions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

function FacilityArea(props) {
  const [view, setView] = useState({
    instructions: true,
    polygon: false,
    centroid: false,
  });

  const { setFullScreenModal } = props;

  useEffect(() => {
    if (view.polygon || view.centroid) {
      setFullScreenModal(true);
    }
    return () => setFullScreenModal(false);
  }, [view, setFullScreenModal]);

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        {view.instructions ? (
          <Instructions setView={setView} />
        ) : view.polygon ? (
          <DrawPolygon {...props} />
        ) : view.centroid ? (
          <Centroid {...props} />
        ) : null}
      </Grid>
    </>
  );
}

export default FacilityArea;
