import React from "react";

import clsx from "clsx";
import GlobalFilter from "./GlobalFilter";
import PropTypes from "prop-types";

import {
  Grid,
  Typography,
  Toolbar,
  lighten,
  makeStyles,
} from "@material-ui/core";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.common.black,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  title: {
    flex: "1 1 100%",
    paddingLeft: theme.spacing(1),
  },
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    hideTotalEvents,
    numSelected,
    preGlobalFilteredRowCount,
    setGlobalFilter,
    globalFilter,
    toolbarChildren,
    globalFilterLabel,
    controlledCount,
    useGlobalFilters = true,
    totalLabel = "Total Events",
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Grid container justify="flex-start" alignItems="center" spacing={3}>
        <Grid item xs={4} xl={4}>
          {!hideTotalEvents ? `${totalLabel}: ${controlledCount}` : null}
        </Grid>
        <Grid item xs={8} xl={7}>
          {useGlobalFilters ? (
            <GlobalFilter
              highlighted={numSelected > 0}
              preGlobalFilteredRowCount={preGlobalFilteredRowCount}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              label={globalFilterLabel}
            />
          ) : null}
        </Grid>
      </Grid>

      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected in page
        </Typography>
      ) : props.tableHeader ? (
        <Typography className={classes.title} variant="h4" id="tableHeader">
          {props.tableHeader}
        </Typography>
      ) : null}
      <Grid container spacing={3} justify="flex-end" alignItems={"center"}>
        {toolbarChildren}
      </Grid>
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  preGlobalFilteredRowCount: PropTypes.number.isRequired,
  globalFilter: PropTypes.string.isRequired,
  toolbarChildren: PropTypes.any,
  globalFilterLabel: PropTypes.string,
  controlledCount: PropTypes.number,
  totalLabel: PropTypes.string,
};

export default TableToolbar;
