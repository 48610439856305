import * as DefaultBackgrounds from "../../assets/qrBackgrounds/DefaultQrBackground";

export function downloadCQR(value, props) {
  const {
    apiUrl,
    batchAsset,
    batchId,
    assetType,
    token,
    isLoading,
    onSuccess,
    selectCQRLayout,
    selectedAssets,
    state,
  } = props;

  const { eccLevel, format, magnification, version } = state;
  let body = {
    eccLevel,
    format,
    magnification,
    qrBackground: selectCQRLayout.value ? selectCQRLayout.label : null,
    propertiesMap: {
      width: 1000,
      height: 1000,
    },
    showTag: false,
    target: "lxproductscan",
    urlBase: "https://qr.locatorx.com",
    version,
  };

  if (!batchAsset) {
    body.assetIds = Object.keys(selectedAssets);
  }

  fetch(
    `${apiUrl}${
      batchAsset
        ? `batches/${batchId}/qrCodes?assetType=${assetType}`
        : `assets/qrCodes`
    }`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(body),
    }
  )
    .then((response) => response.blob())
    .then((res) => {
      if (res) {
        // on successful download, update event log with CQR Generated Event for each asset
        if (!batchAsset) {
          const selectedAssetsArray = [];

          // We form the selectedAssetsArray so we can send this to the assets/assetsAction endpoint
          Object.keys(selectedAssets).forEach((asset) => {
            selectedAssetsArray.push(asset);
          });

          fetch(`${apiUrl}assets/assetsAction`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": token,
            },
            body: JSON.stringify({
              assetIds: selectedAssetsArray,
              action: "CQR Generated",
            }),
          })
            .then((response) => response.json())
            .then(() => {
              return null;
            });
        }
        let url = URL.createObjectURL(res);
        let a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        a.href = url;
        a.download = `QRCodes${batchId ? `_${batchId}` : ""}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
        isLoading(false);
        onSuccess("CQR Successfully Created");
      } else alert("Error: " + res.error);
    });
}

function formTemplateBody(props) {
  const {
    aboveData,
    aboveImages,
    belowData,
    belowImages,
    consoleRole,
    cqrLayoutName,
    dataElementAbove,
    dataElementBelow,
    font,
    fontSize,
    templateSize,
    textFormats,
  } = props;
  const body = {
    images: [],
    name: cqrLayoutName.value,
    propertiesMap: {
      fields: [],
      height: templateSize.value + parseInt(fontSize) * 3,
      images: [],
      templateValues: {
        aboveData,
        aboveImages,
        belowData,
        belowImages,
        consoleRole,
        cqrLayoutName: cqrLayoutName.value,
        dataElementAbove: dataElementAbove,
        dataElementBelow: dataElementBelow,
        font: font,
        fontSize: fontSize,
        templateSize,
        textFormats: textFormats,
        userType: "",
      },
      width: templateSize.value + parseInt(fontSize) * 3,
    },
    qrHeight: templateSize.value + parseInt(fontSize) * 3,
    qrWidth: templateSize.value + parseInt(fontSize) * 3,
    xOffset: 0,
    yOffset: 0,
  };

  const fieldsSwitch = (element, position = "above") => {
    let item = {
      fontName: font.value,
      fontSize,
      location: position,
    };

    if (textFormats && textFormats.length > 0) {
      item.fontStyle = textFormats;
    }

    switch (element.value) {
      case "assetDetails":
        item.data = "assetDetails";
        item.source = "asset";
        return item;
      case "assetTag":
        item.data = "tag";
        item.source = "asset.";
        return item;
      case "batch":
        item.data = position === "above" ? aboveData.value : belowData.value;
        item.source = "batch.";
        return item;
      case "category":
        item.data = "category";
        item.source = "asset";
        return item;
      case "Current Value":
        item.data = "Current Value";
        item.source = "asset";
        return item;
      case "description":
        item.data = "description";
        item.source = "asset";
        return item;
      case "Date of Manufacture":
        item.data = "Date of Manufacture";
        item.source = "asset";
        return item;
      case "product":
        item.data = position === "above" ? aboveData.value : belowData.value;
        item.source = "product.";
        return item;
      case "item":
        item.data = position === "above" ? aboveData.value : belowData.value;
        item.source = "asset.";
        return item;
      case "Serial No":
        item.data = "Serial No";
        item.source = "asset";
        return item;
      case "userDefinedText":
        item.data = position === "above" ? aboveData.value : belowData.value;
        item.source = "text";
        return item;
      default:
        return null;
    }
  };

  if (dataElementAbove.value) {
    body.propertiesMap.fields.push({
      field: fieldsSwitch(dataElementAbove, "above"),
    });
  }

  if (dataElementBelow.value) {
    body.propertiesMap.fields.push({
      field: fieldsSwitch(dataElementBelow, "below"),
    });
  }

  if (aboveImages.length > 0) {
    aboveImages.forEach((item) => {
      body.propertiesMap.images.push({
        name: item.name,
        location: item.position,
      });
    });
  }

  if (belowImages.length > 0) {
    belowImages.forEach((item) => {
      body.propertiesMap.images.push({
        name: item.name,
        location: item.position,
      });
    });
  }

  return body;
}

export const createTemplate = async (props) => {
  const { apiUrl, token, aboveImages, belowImages } = props;
  const body = formTemplateBody(props);

  const template = await fetch(`${apiUrl}qrBackgrounds`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      if (aboveImages.length > 0) {
        uploadTemplateImages(
          {
            ...props,
            qrBackgroundId: response.qrBackground.qrBackgroundId,
          },
          aboveImages
        );
      } else if (belowImages.length > 0) {
        uploadTemplateImages(
          {
            ...props,
            qrBackgroundId: response.qrBackground.qrBackgroundId,
          },
          belowImages
        );
      }
      return response.qrBackground;
    });

  return template;
};

export function uploadTemplateImages(props, images) {
  const { apiUrl, token, qrBackgroundId } = props;

  images.forEach((imageItem) => {
    const { image, name } = imageItem;
    const newBlob = new Blob([image]);
    const formData = new FormData();

    formData.append("pngimagedata", newBlob);

    fetch(`${apiUrl}qrBackgrounds/${qrBackgroundId}/images/name/${name}`, {
      method: "POST",
      headers: {
        "auth-token": token,
      },

      body: formData,
    }).then(() => {
      return null;
    });
  });
}

export const deleteTemplate = async (props, templateToDelete) => {
  const { apiUrl, token, cqrTemplates } = props;
  const qrBackgroundId = cqrTemplates.find(
    (element) => element.qrBackgroundId === templateToDelete
  ).qrBackgroundId;

  const deletion = await fetch(`${apiUrl}qrBackgrounds/${qrBackgroundId}`, {
    method: "DELETE",
    headers: {
      "auth-token": token,
    },
  }).then((response) => {
    return response;
  });

  return deletion;
};

export const createQrBackgroundSessions = async (props) => {
  const { apiUrl, token } = props;
  const newBlob = new Blob([DefaultBackgrounds.Medium]);
  const formData = new FormData();

  formData.append("pngimagedata", newBlob);

  const results = await fetch(`${apiUrl}qrBackgrounds/session`, {
    method: "POST",
    headers: {
      "auth-token": token,
    },
    body: formData,
  })
    .then((response) => response.json())
    .then((response) => response)
    .catch((err) =>
      console.log(`There was an Error creating the session template:${err}`)
    );

  return results;
};

export const deleteQrBackgroundSessions = async (props, templateToDelete) => {
  const { apiUrl, token } = props;

  const results = await fetch(`${apiUrl}qrBackgrounds/${templateToDelete}`,{
    method: "DELETE",
    headers: {
      "auth-token": token,
    }
  })
  .then((response) => response.json())
  .then((response) => {
    return response;
  });

  return results;
};

export const updateSessionImage = async (props, templateId) => {
  const { apiUrl, assetId, eccLevel, magnification, token, version } = props;
  let body = formTemplateBody(props);
  body.qrBackgroundId = templateId;

  // Here we form the body to match what the backend is expecting
  delete body.name;
  // delete body.images;
  delete body.propertiesMap.templateValues;

  body.magnification = magnification;
  body.eccLevel = eccLevel;
  body.version = version;

  const sessionImage = fetch(`${apiUrl}assets/${assetId}/qrCode`,{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
  .then((response) => response.json())
  .then((response) => {
    let url = URL.createObjectURL(response);

    return url;
  });


  // const sessionImage = request
  //   .post(`${apiUrl}assets/${assetId}/qrCode`)
  //   .send(body)
  //   .set("auth-token", token)
  //   .responseType("blob")
  //   .then((response) => {
  //     let url = URL.createObjectURL(response.body);

  //     return url;
  //   });
  return sessionImage;
};
