import { useEffect, useState } from "react";
import { getFixedNodesByZone } from "./api";
import { Box, Grid } from "@material-ui/core";
import { searchRadios } from "../../../utils/API/Radios/Radios";
import ConfigureZoneTable from "./ConfigureZoneTable/ConfigureZoneTable";
import EditNode from "./EditNode/EditNode";
import HoverIconButton from "../../../components/ReusedComponents/HoverIconButton";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import ViewNodeInformation from "./ViewNodeInformation/ViewNodeInformation";

export default function ConfigureZone(props) {
  const { handleAlertModal, setView, view } = props;
  const { zoneInfo = {} } = view;
  const { name = "", zoneId = "" } = zoneInfo;
  const [state, setState] = useState({
    nodes: [],
    selectedMountPointId: null,
    selectedNode: {},
  });

  useEffect(() => {
    getFixedNodesByZone(props, zoneId).then((res) => {
      const { fixedNodes = [] } = res;
      let fixedNodeHashMap = {};

      // This lets us know what fixedNodes are available for this
      // particular zone.
      fixedNodes.forEach((item) => {
        fixedNodeHashMap[item.name] = { ...item, children: {} };
      });

      searchRadios(props, [zoneId]).then((res) => {
        const { radios = [] } = res;
        const nodesArray = [];

        // Divvy's up the radios to the appropiate fixedNode
        radios.forEach((radio) => {
          if (fixedNodeHashMap[radio.node]) {
            fixedNodeHashMap[radio.node].children[radio.radioId] = radio;
          }
        });

        // Then turn it into an array for the table
        Object.keys(fixedNodeHashMap)
          .sort()
          .forEach((item) => {
            nodesArray.push({
              node: item,
              children: fixedNodeHashMap[item].children,
              ...fixedNodeHashMap[item],
            });
          });
        setState((prevState) => ({
          ...prevState,
          nodes: nodesArray,
        }));
      });
    });
  }, [props, zoneId]);

  const [dialog, setDialog] = useState({
    dialogTitle: "",
    dialogType: "",
    dialogShow: false,
  });
  const { dialogTitle, dialogShow, dialogType } = dialog;

  const dialogClose = () =>
    setDialog({
      ...dialog,
      dialogShow: false,
      dialogTitle: "",
    });

  const switchDialog = (item) => {
    switch (item) {
      case "Edit":
        return (
          <EditNode
            {...props}
            dialog={dialog}
            dialogClose={dialogClose}
            handleAlertModal={handleAlertModal}
            setState={setState}
            showTitle={false}
            state={state}
          />
        );
      case "View":
        return <ViewNodeInformation {...props} state={state} />;
      default:
        return;
    }
  };

  return (
    <Grid container>
      <Box display="flex" mt={3} width="100%">
        <ModalDialog
          content={switchDialog(dialogType)}
          handleClose={dialogClose}
          maxWidth="md"
          open={dialogShow}
          title={dialogTitle}
        />
        <Grid item xs={12}>
          <Box textAlign="left">
            <h3>{name}</h3>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="right">
            <span
              onClick={() =>
                setView((prevState) => ({
                  ...prevState,
                  viewConfigureZone: false,
                  zoneInfo: {},
                }))
              }
            >
              <HoverIconButton
                icon="fas fa-arrow-left"
                iconDirection="left"
                text="Back to Zones"
              />
            </span>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ConfigureZoneTable
            setDialog={setDialog}
            setState={setState}
            state={state}
          />
        </Grid>
      </Box>
    </Grid>
  );
}
