import { makeStyles } from "@material-ui/core/styles";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const useStyles = makeStyles(() => {
  return {
    paddingTop: {
      paddingTop: "3px",
    },
  };
});

export default function TextFormatButtonGroup(props) {
  const {
    disabled = false,
    textFormats = [],
    onChange,
    size = "medium",
  } = props;
  const classes = useStyles();
  const handleFormat = (event, newFormats) => {
    onChange(newFormats);
  };

  return (
    <ToggleButtonGroup
      aria-label="text formatting"
      classes={{ root: classes.paddingTop }}
      exclusive
      onChange={handleFormat}
      size={size}
      value={textFormats}
    >
      <ToggleButton disabled={disabled} value="bold" aria-label="bold">
        <FormatBoldIcon />
      </ToggleButton>
      <ToggleButton disabled={disabled} value="italic" aria-label="italic">
        <FormatItalicIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
