import { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import createClass from "create-react-class";
import Select, { components } from "react-select";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
  },
}));

export default function ReactSelectCheckboxes(props) {
  const classes = useStyles();

  const {
    classNamePrefix,
    isDisabled,
    isMulti = true,
    options,
    onChange,
    placeholder = "Select",
    styles,
    value,
    maximumSelectionLength,
    id,
  } = props;

  const onChangeWithLimit = useCallback(
    (e,actionMeta) => {
      if (
        maximumSelectionLength &&
        Array.isArray(e) &&
        e.length > maximumSelectionLength
      )
        return;
      if (onChange) {
        return onChange(e,actionMeta);
      }
    },
    [maximumSelectionLength, onChange]
  );

  const Option = createClass({
    render() {
      return (
        <div>
          <components.Option {...this.props}>
            {isMulti ? (
              <input
                type="checkbox"
                checked={this.props.isSelected}
                onChange={(e) => null}
              />
            ) : null}{" "}
            <label className={classes.label}>{this.props.label} </label>
          </components.Option>
        </div>
      );
    },
  });

  return (
    // Taken from https://codesandbox.io/s/o567zv6j2q?file=/example.js:1576-1589
    <Select
      id={id || ""}
      backspaceRemovesValue={false}
      classNamePrefix={classNamePrefix}
      closeMenuOnSelect={false}
      components={{ Option, MultiValue }}
      hideSelectedOptions={false}
      isDisabled={isDisabled}
      isMulti={isMulti}
      maximumSelectionLength={maximumSelectionLength}
      menuPortalTarget={document.querySelector("main")}
      menuPosition={"fixed"}
      placeholder={placeholder}
      onChange={(e,actionMeta) => onChangeWithLimit(e,actionMeta)}
      options={options}
      styles={{
        ...styles,
        menuPortal: (styles) => ({ ...styles, zIndex: 99999 }),
      }}
      value={value}
    />
  );
}

const MultiValue = (props) => {
  return (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
};
