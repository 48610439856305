import { useState, useEffect } from "react";
import { isArray } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { naturalSort } from "../../utils/naturalSort";
import { Tooltip } from "@material-ui/core";
import { validatePasswordStrength } from "../../utils/passwordStrength";
import Grid from "@material-ui/core/Grid";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";
import moment from "moment-timezone";
import SimpleCheckbox from "../../components/Forms/FieldTypes/Checkbox";
import SimpleSelect from "../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";

const useStyles = makeStyles((theme) => ({
  addOrganizationButton: {
    alignItems: "center",
    display: "flex",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  control: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  paper: {
    height: 140,
    width: 100,
  },
  root: {
    flexGrow: 1,
    paddingBottom: "1rem",
    paddingTop: "1rem",
  },
  select: {
    marginBottom: "1rem",
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function EditUser(props) {
  const [state, setState] = useState({
    consoleRole: { error: false, id: "consoleRole", value: "" },
    email: { error: false, id: "email", value: "" },
    firstName: { error: false, id: "firstName", value: "" },
    jobTitle: { error: false, id: "jobTitle", value: "" },
    lastName: { error: false, id: "lastName", value: "" },
    memberOf: [],
    mobileRole: { error: false, id: "mobileRole", value: "" },
    notes: { error: false, id: "notes", value: "" },
    organization: { error: false, id: "organization", value: "" },
    password: { error: false, id: "password", value: "" },
    phone: { error: false, id: "phone", value: "" },
    readOnly: false,
    resetPassword: false,
    userType: { error: false, id: "userType", value: "" },
  });

  const {
    consoleRoles,
    dispatchGlobal,
    groups,
    isCreate,
    mobileRoles,
    onHide,
    readOnly,
    updateUsersMap,
  } = props;
  const classes = useStyles();
  const {
    consoleRole,
    email,
    firstName,
    jobTitle,
    lastName,
    memberOf = [],
    mobileRole,
    notes,
    password,
    phone,
    resetPassword = false,
    userType,
  } = state;

  const organizationGroupOptions = (organizationGroups) => {
    let groupOptions = [];
    organizationGroups.forEach((item) => {
      groupOptions.push({
        value: item.name,
        label: item.name,
      });
    });
    return groupOptions;
  };

  const [showPasswordAlert, setShowPasswordAlert] = useState(false);

  useEffect(() => {
    const { selectedUser = {}, mobileRoles = {}, consoleRoles = {} } = props;
    const {
      email = "",
      firstName = "",
      lastName = "",
      propertiesMap = {},
      roles = [],
    } = selectedUser;

    // userType, e.g., "Asset", "Product", or "Asset/Product", is called consoleRole in the propertiesMap
    const {
      consoleRole: userType = "",
      jobTitle = "",
      memberOf = [""],
      notes = "",
      phone = "",
    } = propertiesMap;

    // console and mobile roles are stored in the roles array on main user column
    const usersConsoleRole = roles.filter((role) => consoleRoles[role])[0];
    const usersMobileRole = roles.filter((role) => mobileRoles[role])[0];

    const memberOfFormatted = isArray(memberOf) ? memberOf : [memberOf];

    setState((prevState) => ({
      ...prevState,
      consoleRole: { error: false, id: "consoleRole", value: usersConsoleRole },
      email: { error: false, id: "email", value: email },
      firstName: { error: false, id: "firstName", value: firstName },
      jobTitle: { error: false, id: "jobTitle", value: jobTitle },
      lastName: { error: false, id: "lastName", value: lastName },
      memberOf: memberOfFormatted,
      mobileRole: { error: false, id: "mobileRole", value: usersMobileRole },
      notes: { error: false, id: "notes", value: notes },
      password: { error: false, id: "password", value: "" },
      phone: { error: false, id: "phone", value: phone },
      propertiesMap,
      userType: { error: false, id: "userType", value: userType },
    }));
  }, [props]);

  function onChange(event) {
    setState((prevState) => ({
      ...prevState,
      [event.target.id]: {
        error: false,
        id: event.target.id,
        value: event.target.value,
      },
    }));
  }

  function handlePasswordOnChange(event) {
    setState((prevState) => ({
      ...prevState,
      [event.target.id]: {
        error: false,
        id: event.target.id,
        value: event.target.value.trim(),
      },
    }));
  }

  function buildPassword() {
    const passwordLength = 13
  
    let lowerChars = "abcdefghijklmnopqrstuvwxyz";
    let numberChars = "0123456789";
    let specialChars = "!@#$%&?*"
    let upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    let allChars = numberChars + upperChars + lowerChars + specialChars;

    let randPasswordArray = Array(passwordLength);

    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray[3] = specialChars;

    randPasswordArray = randPasswordArray.fill(allChars, 4);

    const a = shuffleArray(randPasswordArray.map(function (x) { return x[Math.floor(Math.random() * x.length)] })).join('');

    setState((prevState) => ({
      ...prevState,
      password: { error: false, id: "password", value: a },
    }));
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  function validateFields() {
    let isValidated = true;
    const fieldsToValidate = [
      email,
      firstName,
      lastName,
      mobileRole,
      consoleRole,
      userType,
    ];

    if (resetPassword && !validatePasswordStrength(password.value)) {
      setShowPasswordAlert(true);
      return null;
    }

    fieldsToValidate.forEach((element) => {
      const { id, value = "" } = element;

      if (id === "email") {
        // Ripped from: https://bobbyhadz.com/blog/react-check-if-email-is-valid
        // Quick test to see if the email is valid. Returns a boolean
        const isValidEmail = /\S+@\S+\.\S+/.test(value);

        // Here we do a weak check to see if the email is valid
        if (!isValidEmail) {
          isValidated = false;
          setState((prevState) => ({
            ...prevState,
            [id]: { ...prevState[id], error: true },
          }));
        }
      }

      // Here we check to verify there is content entered in
      if (!value || value.length === 0) {
        isValidated = false;
        setState((prevState) => {
          return {
            ...prevState,
            [id]: { ...prevState[id], error: true },
          };
        });
      }
    });

    if (isValidated) {
      handleSubmit();
    }
  }

  function handleSubmit(event) {
    const browserTimeZone = moment.tz.guess();
    const {
      apiUrl,
      selectedUser = {},
      notificationModal,
      onHide,
      onSuccess,
      token,
      updateUsersMap,
    } = props;
    const {
      email,
      firstName,
      lastName,
      mobileRole,
      password,
      phone,
      propertiesMap,
      resetPassword,
      userType,
      consoleRole,
    } = state;
    const { appUserId } = selectedUser;

    const consoleNoAccess =
      consoleRole !== "No Console Access" &&
      consoleRole !== "No LXConnect Access";

    const mobileNoAccess = mobileRole !== "No Mobile Access";

    let body = {
      appUserId,
      contacts: [{ type: "phone", value: phone.value }],
      email: email.value,
      firstName: firstName.value,
      lastName: lastName.value,
      permissions: [],
      propertiesMap,
      roles: [consoleRole.value, mobileRole.value],
    };

    // Sets the users time zone
    propertiesMap.timeZone = {
      label: browserTimeZone,
      useDefaultTimeZone: true,
      value: browserTimeZone,
    };

    // Sets the users console user type, e.g., "Product" or "Asset" or "Asset/Product". This is still stored as consoleRole in the users' propertiesMap
    propertiesMap.consoleRole = userType.value;

    // set user job title, memberOf, notes, phone number...
    propertiesMap.jobTitle = state.jobTitle.value;
    propertiesMap.memberOf = state.memberOf;
    propertiesMap.phone = state.phone.value;
    propertiesMap.notes = state.notes.value;

    // Sets the password in the request body if reset password is true
    if (resetPassword === true) {
      body.password = password.value;
    }

    // Deals with the situation that a user selects no console and no mobile access
    if (!consoleNoAccess && !mobileNoAccess) {
      notificationModal(
        'User may not simultaneously have "Console No Access" and "Mobile No Access". Please change roles',
        true,
        "fas fa-times-circle",
        4000
      );
      return null;
    }

    // Deals with console permissions
    if (consoleNoAccess) {
      body.permissions.push("console");
    }

    // Deals with mobile permissions
    if (mobileNoAccess) {
      body.permissions.push("mobile");
    }

    fetch(isCreate ? `${apiUrl}appUsers` : `${apiUrl}appUsers/${appUserId}`, {
      method: isCreate ? "POST" : "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          dispatchGlobal(
            updateUsersMap({
              appUserId: response.appUser?.appUserId,
              body: response.appUser,
            })
          );
          onHide();
          notificationModal(
            "User Successfully Updated",
            false,
            "fas fa-check-circle"
          );
          onSuccess();
        } else {
          notificationModal(
            `Error: ${response.error}`,
            true,
            "fas fa-times-circle"
          );
        }
      });
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      {/* First Name */}
      <Grid item xs={6}>
        <SimpleTextField
          error={firstName.error}
          id="firstName"
          label="First Name"
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          required
          value={firstName.value}
        />
      </Grid>

      {/* Last Name */}
      <Grid item xs={6}>
        <SimpleTextField
          error={lastName.error}
          label="Last Name"
          id="lastName"
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          value={lastName.value}
        />
      </Grid>

      {/* Email */}
      <Grid item xs={6}>
        <SimpleTextField
          error={email.error}
          errorText={
            email.value?.length === 0 ? "Required Field" : "Invalid Email"
          }
          label="Email"
          id="email"
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          value={email.value}
        />
      </Grid>

      {/* Phone Number */}
      <Grid item xs={6}>
        <SimpleTextField
          label="Phone Number - optional"
          id="phone"
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          value={phone.value}
        />
      </Grid>

      {/* Job Title */}
      <Grid item xs={6}>
        <SimpleTextField
          label="Job Title - optional"
          id="jobTitle"
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          value={jobTitle.value}
        />
      </Grid>

      {/* User's type, e.g., Product, Asset, Asset/Product */}
      <Grid item xs={6}>
        <SimpleSelect
          error={userType.error}
          label="User Type"
          id="userType"
          onChange={(event) => {
            event.persist();
            onChange({ target: { id: "userType", value: event.target.value } });
          }}
          readOnly={readOnly}
          value={userType.value}
          options={[
            { value: "Asset-Operations", label: "Asset-Operations" },
            { value: "Asset/Product", label: "Asset/Product" },
            { value: "Asset", label: "Asset-Warehouse" },
            { value: "Product", label: "Product" },
            { value: "Warehouse-Operations", label: "Warehouse-Operations" },
            { value:"Inventory",label:"Inventory" },
            { value:"Inventory/Product",label:"Inventory/Product" },
          ]}
          variant="outlined"
        />
      </Grid>

      {/* User's LXConnect Role, e.g., Admin, Lite, etc*/}
      <Grid item xs={6}>
        <SimpleSelect
          error={consoleRole.error}
          label="LXConnect Role"
          id="consoleRole"
          onChange={(event) => {
            event.persist();
            onChange({
              target: { id: "consoleRole", value: event.target.value },
            });
          }}
          readOnly={readOnly}
          value={consoleRole.value}
          options={Object.keys(consoleRoles)
            .map((element) => {
              if (element === "No Console Access") {
                return { label: "No LXConnect Access", value: element };
              }
              return { label: element, value: element };
            })
            .sort((a, b) => naturalSort(a.label, b.label))}
          variant="outlined"
        />
      </Grid>

      {/* Mobile */}
      <Grid item xs={6}>
        <SimpleSelect
          error={mobileRole.error}
          label="Mobile Role"
          id="mobile"
          onChange={(event) => {
            event.persist();
            onChange({
              target: { id: "mobileRole", value: event.target.value },
            });
          }}
          readOnly={readOnly}
          value={mobileRole.value}
          options={Object.keys(mobileRoles)
            .map((element) => {
              return { label: element, value: element };
            })
            .sort((a, b) => naturalSort(a.label, b.label))}
          variant="outlined"
        />
      </Grid>

      {/* Initial Organization */}
      <Grid item xs={6}>
        {memberOf ? (
          <SimpleSelect
            classes={classes}
            label="Organization"
            id="organization"
            onChange={(event) => {
              event.persist();
              setState((prevState) => {
                let newState = prevState;
                newState.memberOf[0] = event.target.value;
                return {
                  ...newState,
                };
              });
            }}
            readOnly={readOnly}
            value={state.memberOf[0]}
            options={organizationGroupOptions(groups).sort((a, b) =>
              naturalSort(a.label, b.label)
            )}
            variant="outlined"
          />
        ) : null}
      </Grid>

      {/* Add Organization Button */}
      {!readOnly ? (
        <Grid className={classes.addOrganizationButton} item xs={6}>
          <MaterialUiButton
            color="secondary"
            fullWidth={true}
            label="Add Organization"
            onClick={() => {
              setState((prevState) => {
                prevState.memberOf.push("");
                return { ...prevState };
              });
            }}
            variant="contained"
          />
        </Grid>
      ) : null}

      {/* Organization */}
      {memberOf
        ? memberOf.slice(1).map((item, index) => (
            <Grid item xs={12}>
              <Grid item xs={6}>
                <SimpleSelect
                  classes={classes}
                  label="Organization"
                  id="organization"
                  key={index}
                  onChange={(event) => {
                    event.persist();
                    setState((prevState) => {
                      let newState = prevState;
                      newState.memberOf[index + 1] = event.target.value;
                      return {
                        ...newState,
                      };
                    });
                  }}
                  readOnly={readOnly}
                  value={item}
                  options={organizationGroupOptions(groups).sort((a, b) =>
                    naturalSort(a.label, b.label)
                  )}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          ))
        : null}

      {/* Notes */}
      <Grid item xs={12}>
        <SimpleTextField
          label="Notes - optional"
          id="notes"
          multiline
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          readOnly={readOnly}
          rows={4}
          value={notes.value}
        />
      </Grid>

      {/* Generate new password checkbox - Only shows up when isEdit is true */}
      {!readOnly && !isCreate ? (
        <Grid item xs={12}>
          <SimpleCheckbox
            name="generate-password"
            label="Generate New Password"
            checked={resetPassword}
            onChange={() => {
              buildPassword();
              setState((prevState) => ({
                ...prevState,
                resetPassword: !prevState.resetPassword,
              }));
            }}
          />
        </Grid>
      ) : null}

      {/* Generate new password field */}
      {resetPassword ? (
        <Grid item xs={12}>
          <Tooltip
            open={showPasswordAlert}
            title={
              <>
                The requirements for a password are: <br />- At least 8
                characters <br />- A mixture of both uppercase and lowercase
                letters <br />- A mixture of letters and numbers <br />-
                Inclusion of at least one special character, e.g., ! @ # ? ]{" "}
              </>
            }
          >
            <div>
              <SimpleTextField
                id="password"
                label="Password"
                onChange={(event) => {
                  event.persist();

                  // Handles the tooltip for password
                  if (
                    resetPassword &&
                    validatePasswordStrength(event.target.value)
                  ) {
                    setShowPasswordAlert(false);
                  } else if (
                    resetPassword &&
                    !validatePasswordStrength(event.target.value)
                  ) {
                    setShowPasswordAlert(true);
                  }

                  handlePasswordOnChange(event);
                }}
                readOnly={readOnly}
                required
                value={password.value}
              />
            </div>
          </Tooltip>
        </Grid>
      ) : null}

      {/* Cancel and Submit Buttons */}
      {!readOnly ? (
        <Grid className={classes.buttonContainer} item xs={12}>
          {/* Cancel Button */}
          <Grid item xs={4}>
            <MaterialUiButton
              color="cancel"
              fullWidth={true}
              label="Cancel"
              onClick={() => {
                onHide();
              }}
              variant="outlined"
            />
          </Grid>

          {/* Submit */}
          <Grid item xs={4}>
            <MaterialUiButton
              color="primary"
              fullWidth={true}
              label="Submit"
              onClick={validateFields}
            />
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
}
