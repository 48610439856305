import { useState, useEffect } from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MaterialUiButton from "../../Buttons/MaterialUiButton/MaterialUiButton";
import ModalDialog from "../../Modals/ModalDialog/ModalDialog";
import NotificationProfile from "./NotificationProfile";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  noButton: { color: "#dc3545", paddingLeft: ".5rem" },
}));

function NotificationSettings(props) {
  const {
    actions = {},
    apiUrl = "",
    batches = [],
    emailOptOut,
    eventTrigerIdsToRemove,
    handleDelete,
    handleModal,
    onSubmit,
    organization,
    organizationId,
    products = [],
    token = "",
    trackedEvents = [],
    setEmailOptOut,
    setEventTrigerIdsToRemove,
    setSelectedItemIndex,
    setTrackedEvents,
    appUserType,
  } = props;
  const classes = useStyles();
  const [batchHash, setBatchHash] = useState([]);
  const [productHash, setProductHash] = useState([]);
  const [userCanAdd, setUserCanAdd] = useState(false);
  const [modalOpen, setModalOpen] = useState({
    open: false,
    type: "",
  });
  const trackedEventsLength = trackedEvents.length;
  const lastItemInTrackEvents = trackedEvents[trackedEventsLength - 1] || {
    events: "",
    id: "",
    newAction: "",
  };

  const { events, id, newAction = "" } = lastItemInTrackEvents;

  useEffect(() => {
    const batchObject = {};
    const productsObject = {};

    // Creates a batch hash map
    Object.values(batches).forEach((batch) => {
      const { name, tag } = batch;
      batchObject[tag ? tag : name] = batch;
    });

    // Creates a product hash map
    Object.values(products).forEach((product) => {
      const { name } = product;
      productsObject[name] = product;
    });

    // This checks to see if the last item in the array has an valid values attached to it
    // It makes sure an action is selectd. Also verifies the user has chosen a batch or product
    if ((events.length !== 0 || newAction.length !== 0) && id.length !== 0) {
      setUserCanAdd(true);
    } else if (trackedEventsLength === 0) {
      // In the case that user has no trackedEvents
      setUserCanAdd(true);
    } else {
      setUserCanAdd(false);
    }
    setBatchHash((current) => batchObject);
    setProductHash((current) => productsObject);
  }, [
    batches,
    events.length,
    id.length,
    newAction.length,
    products,
    trackedEventsLength,
  ]);

  const modalContent = (
    <Grid container className="modal-body">
      <Grid item xs={12}>
        Are you sure you want to remove the notification? You will no longer
        recieve notifications on this {modalOpen.type} .
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" textAlign="center" mt={2} mb={2}>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                handleDelete();
                setModalOpen({ open: false, type: "" });
              }}
              type="button"
            >
              Yes
              <i
                className="far fa-check-circle"
                style={{ paddingLeft: ".5rem", color: "#28a745" }}
              />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                setModalOpen({ open: false, type: "" });
                setSelectedItemIndex("");
              }}
              type="button"
            >
              No
              <i className={`far fa-times-circle ${classes.noButton}`} />
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <>
      <ModalDialog
        content={modalContent}
        closeModal={() => setModalOpen({ open: false, type: "" })}
        open={modalOpen.open}
        title="Remove Notification"
      />
      <Box pl={2} pr={2}>
        <Grid container>
          <Grid item xs={12}>
            {trackedEvents.map((element, index) => {
              return (
                <NotificationProfile
                  {...element}
                  actions={actions}
                  apiUrl={apiUrl}
                  batches={batches}
                  batchHash={batchHash}
                  eventTrigerIdsToRemove={eventTrigerIdsToRemove}
                  handleModal={handleModal}
                  index={index}
                  key={index}
                  organization={organization}
                  organizationId={organizationId}
                  productHash={productHash}
                  products={products}
                  setEventTrigerIdsToRemove={setEventTrigerIdsToRemove}
                  setModalOpen={setModalOpen}
                  setSelectedItemIndex={setSelectedItemIndex}
                  setTrackedEvents={setTrackedEvents}
                  token={token}
                  trackedEvents={trackedEvents}
                />
              );
            })}

            {/* Email Notifications Toggle */}
            <Box mt={3}>
              <span> Enable Email Notifications </span>
              <Switch
                checked={!emailOptOut}
                color="primary"
                inputProps={{ "aria-label": "Enable email notifications" }}
                name="checkedA"
                onChange={() => {
                  setEmailOptOut((prevState) => !prevState);
                }}
              />
            </Box>

            {/* Add New button */}
            {appUserType === "Asset" ||
            appUserType === "Asset-Operations" ||
            appUserType === "Warehouse-Operations" ? (
              <Box mb={3}>
                <MaterialUiButton
                  disabled={!userCanAdd}
                  fullWidth={true}
                  label="Set Notification by Asset Category or Asset Tag"
                  onClick={() => {
                    const newArray = [...trackedEvents];
                    const type = "asset category";
                    newArray.push({
                      events: [],
                      id: "",
                      isNew: true,
                      newAction: "",
                      type,
                    });
                    setUserCanAdd(false);
                    setTrackedEvents(newArray);
                  }}
                />
              </Box>
            ) : appUserType === "Product" ? (
              <Box mb={3}>
                <MaterialUiButton
                  disabled={!userCanAdd}
                  fullWidth={true}
                  label="Set Notification by Product or Batch ID"
                  onClick={() => {
                    const newArray = [...trackedEvents];
                    const type = "product";
                    newArray.push({
                      events: [],
                      id: "",
                      newAction: "",
                      type,
                      isNew: true,
                    });
                    setUserCanAdd(false);
                    setTrackedEvents(newArray);
                  }}
                />
              </Box>
            ) : appUserType === "Asset/Product" ? (
              <>
                <Box mb={3}>
                  <MaterialUiButton
                    disabled={!userCanAdd}
                    fullWidth={true}
                    label="Set Notification by Asset Category or Asset Tag"
                    onClick={() => {
                      const newArray = [...trackedEvents];
                      const type = "asset category";
                      newArray.push({
                        events: [],
                        id: "",
                        newAction: "",
                        type,
                        isNew: true,
                      });
                      setUserCanAdd(false);
                      setTrackedEvents(newArray);
                    }}
                  />
                </Box>
                <Box mb={3}>
                  <MaterialUiButton
                    disabled={!userCanAdd}
                    fullWidth={true}
                    label="Set Notification by Product or Batch ID"
                    onClick={() => {
                      const newArray = [...trackedEvents];
                      const type = "product";
                      newArray.push({
                        events: [],
                        id: "",
                        isNew: true,
                        newAction: "",
                        type,
                      });
                      setUserCanAdd(false);
                      setTrackedEvents(newArray);
                    }}
                  />
                </Box>
              </>
            ) : null}

            {/* Submit Button */}
            <Box mt={3} mb={3}>
              <MaterialUiButton
                fullWidth={true}
                label="Submit"
                onClick={() => {
                  onSubmit();
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default NotificationSettings;
