import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MaterialUiButton from "../../../../../components/Buttons/MaterialUiButton/MaterialUiButton"

const useStyles = makeStyles((theme) => ({
  instructions: {
    textAlign: "center",
  },
}));

function Instructions(props) {
  const { setView } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.instructions}
      spacing={1}
      justify="center"
    >
      <Grid item xs={12}>
        <p>
          Would you like to define the facility area by Polygon or revise the
          area by the Centroid and Radius?
        </p>
      </Grid>
      <Grid item xs={6}>
        <MaterialUiButton
          color="primary"
          label="Draw Polygon"
          onClick={() => {
            setView({
              instructions: false,
              polygon: true,
              centroid: false,
            });
          }}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={6}>
        <MaterialUiButton
          color="primary"
          label="Centroid and Radius"
          onClick={() => {
            setView({
              instructions: false,
              polygon: false,
              centroid: true,
            });
          }}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
}

export default Instructions;
