import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { utc_to_local_12_hour_clock } from "../../../utils/date_time_utils";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  snapshot: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    minHeight: "30vh",
    maxHeight: "100%",
    overflow: "auto",
  },
}));

export default function DeviceOverview(props) {
  const classes = useStyles();
  const { assetData, devices, facilities, state, timeZone } = props;
  const { deviceTelemetry = {} } = state;
  const { logs = [], lastReport = {}, lastMovement = {} } = deviceTelemetry;
  const { parentId } = assetData;
  const { tag, device } = devices[parentId];
  const { platform } = device;

  // Most recent log
  const { battery, facilityId } = logs[0] || {};

  return (
    <Box className={classes.root}>
      <h4 style={{ textAlign: "left" }}>Device Information</h4>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.snapshot} elevation={0}>
            {/* Device Identifier */}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <strong>Device Identifier</strong> <br />
                {tag}
              </Grid>

              {/* Device Type */}
              <Grid item xs={12} sm={4}>
                <strong>Device Type</strong> <br />
                {platform}
              </Grid>

              {/* The Last Reported Battery Life */}
              <Grid item xs={12} sm={4}>
                <strong>The Last Reported Battery Life</strong> <br />
                {/* TODO: Remove this catch. Its here cause of dummy data thats flowing through */}
                {battery === "ff" ? "" : battery}
              </Grid>

              {/* Last Location */}
              <Grid item xs={12} sm={4}>
                <strong>Last Location</strong> <br />
                {facilities && facilities[facilityId] ? facilities[facilityId].name : "DNP"}
              </Grid>

              {/* Last Report */}
              <Grid item xs={12} sm={4}>
                <strong>Last Report</strong> <br />
                {lastReport && lastReport.count !== 0
                  ? utc_to_local_12_hour_clock(
                      lastReport.logs[0].timeOfReport,
                      timeZone
                    )
                  : ""}
              </Grid>

              {/* Last Movement */}
              <Grid item xs={12} sm={4}>
                <strong>Last Movement</strong> <br />
                {lastMovement && lastMovement.count !== 0
                  ? utc_to_local_12_hour_clock(
                      lastMovement.logs[0].timeOfReport,
                      timeZone
                    )
                  : ""}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
