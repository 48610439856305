import { configureStore } from "@reduxjs/toolkit";

import * as slices from "./slices/index";

import * as apiService from "./apiService";

// https://redux-toolkit.js.org/api/configureStore
export default configureStore({
  // If "reducer" is a single function, it will be directly used as the root reducer for the store.

  // If "reducer" is an object of slice reducers, like {users : usersReducer, posts : postsReducer}, configureStore will automatically create the root reducer by passing this object to the Redux combineReducers utility. See https://redux.js.org/api/combinereducers
  reducer: {
    alert: slices.alertReducer,
    assetHistory: slices.assetHistoryReducer,
    assets: slices.assetsReducer,
    inventory: slices.inventoryReducer,
    inventoryHistory: slices.inventoryHistoryReducer,
    devices: slices.devicesReducer,
    organization: slices.organizationReducer,
    user: slices.userReducer,
  },
  // add custom api service to thunk middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // immutable check and serializable check are useful features in development mode, however since we use immer and redux toolkit, we shouldn't need to worry about mutating state or inserting unserializable data into state... and these slow performance and throw warnings in dev when we have large state objects with deep structures, so disabling for now.
      immutableCheck: false,
      serializableCheck: false,
      thunk: {
        extraArgument: apiService,
      },
    }),
});
