const darkModeStyle = {
  position: "absolute",
  top: 10,
  right: 10,
  padding: "10px",
};

const fullscreenControlStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  padding: "10px",
};

const navStyle = {
  position: "absolute",
  top: 36,
  left: 0,
  padding: "10px",
};

const scaleControlStyle = {
  position: "absolute",
  bottom: 36,
  left: 0,
  padding: "10px",
};

export { darkModeStyle, fullscreenControlStyle, navStyle, scaleControlStyle };
