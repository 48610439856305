import { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import SimpleTable from "../../../../../components/Tables/SimpleTable/SimpleTable";

export default function Devices(props) {
  const history = useHistory();

  const deviceAssetsMap = useSelector(
    (state) => state.devices.deviceAssetsMap
  );
  const devices = useSelector((state) => state.organization.devices);

  const zoneDevices = Object.keys(deviceAssetsMap)
    .filter((deviceId) => {
      return (
        deviceAssetsMap[deviceId][0].zone?.zoneId === props.selectedZone.zoneId
      );
    })
    .map((deviceId) => devices[deviceId]);

  const { selectedZone = {}, facility = {} } = props;
  const { name } = selectedZone;

  useEffect(() => {}, [selectedZone]);

  const columns = [
    {
      Header: "Device Identifier",
      id: "tag",
      accessor: "tag",
    },
    {
      Header: "Availability",
      id: "status",
      accessor: (row) => {
        return row.device?.status || "unassigned";
      },
    },
    {
      Header: "Associated Item",
      id: "assignedTo",
      accessor: (row) => {
        return deviceAssetsMap[row.assetId]
          ? deviceAssetsMap[row.assetId].map((ass) => ass.tag)[0]
          : "";
      },
      Cell: (data) => {
        const row = data.row.original;
        return (
          <div
            className={"pointer"}
            onClick={() => {
              const {
                assetId = "",
                batchId = "",
                assetType = "",
              } = deviceAssetsMap[row.assetId][0];
              if (assetId || batchId) {
                const url = batchId
                  ? `/batches/${batchId}/history/${assetType}`
                  : `/assetSnapshot/${assetId}`;
                history.push(url);
              }
            }}
          >
            {deviceAssetsMap[row.assetId]
              ? deviceAssetsMap[row.assetId].map((ass) => ass.tag)[0]
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Status",
      id: "lastEvent",
      accessor: "lastEvent",
    },
    {
      Header: "Device Type",
      accessor: "assetType",
      id: "assetType",
    },
    {
      Header: "Battery Life",
      id: "batteryLife",
      accessor: (row) => row.propertiesMap?.battery || 0,
      Cell: (data) => {
        const row = data.row.original;
        return (
          <div>
            {row.propertiesMap?.battery ? `${row.propertiesMap.battery}%` : ""}
          </div>
        );
      },
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {" "}
        <h3>
          {name}
          {facility?.name ? ` | ${facility.name}` : ""} Devices
        </h3>
      </Grid>
      <Grid item xs={12}>
        <SimpleTable data={zoneDevices} columns={columns} />
      </Grid>
    </Grid>
  );
}
