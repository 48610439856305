import { useEffect } from "react";
import { Grid } from "@material-ui/core";

function Information(props) {
  const { facility = {}, selectedZone = {}, zonesMap = {} } = props;
  const {
    abbreviation,
    category,
    floor,
    name,
    parentId,
    propertiesMap = {},
    zoneType,
  } = selectedZone;
  const { description = "" } = propertiesMap;

  useEffect(() => {}, [selectedZone]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {" "}
        <h3>
          {name}
          {facility?.name ? ` | ${facility.name}` : ""}
        </h3>
      </Grid>
      <Grid item xs={12} sm={4}>
        <strong>Abbreviation</strong> <br />
        {abbreviation || "None provided"}
      </Grid>
      <Grid item xs={12} sm={4}>
        <strong>Description</strong> <br />
        {description || "None provided"}
      </Grid>
      <Grid item xs={12} sm={4}>
        <strong>Floor</strong> <br />
        {floor || "None provided"}
      </Grid>
      <Grid item xs={12} sm={4}>
        <strong>Category</strong> <br />
        {category || "None provided"}
      </Grid>
      <Grid item xs={12} sm={4}>
        <strong>Zone Type</strong> <br />
        {zoneType || "None provided"}
      </Grid>

      <Grid item xs={12} sm={4}>
        <strong>Parent Zone</strong> <br />
        {zonesMap[parentId]?.name || "None provided"}
      </Grid>
    </Grid>
  );
}

export default Information;
