import { isEmpty } from "lodash";
import NotificationBar from "./NotificationBar";

function Notifications(props) {
  const { events = [], dismissNotification, timeZone } = props;

  if (events.length > 0) {
    return events.map((element, index) => {
      if (!isEmpty(element.propertiesMap)) {
        return (
          <NotificationBar
            {...element}
            dismissNotification={dismissNotification}
            key={index}
            timeZone={timeZone}
          />
        );
      } else return null;
    });
  }
  return null;
}

export default Notifications;
