import { makeStyles, Grid } from "@material-ui/core";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
  },
  container: {
    textAlign: "center",
    padding: "1rem",
  },
  content: {
    marginBottom: "1rem",
  },
  text: {
    color: "black !important",
  },
}));

export default function ConfirmationModalContent(props) {
  const { handleSubmit, handleCancel, content } = props;
  const classes = useStyles();
  return (
    <Grid className={classes.container} container>
      {/* Content */}
      <Grid className={classes.content} item xs={12}>
        {content}
      </Grid>

      {/* Button Container */}
      <Grid className={classes.buttonContainer} item xs={12}>
        {/* Confirmation Button */}
        <Grid item xs={6}>
          <MaterialUiButton
            color="primary"
            className={classes.text}
            label={
              <>
                YES
                <i
                  className="far fa-check-circle"
                  style={{
                    color: "#28a745",
                    paddingLeft: ".5rem",
                  }}
                />
              </>
            }
            onClick={() => handleSubmit()}
            variant="text"
          />
        </Grid>

        {/* Cancel Button */}
        <Grid item xs={6}>
          <MaterialUiButton
            color="primary"
            className={classes.text}
            label={
              <>
                No
                <i
                  className="far fa-times-circle"
                  style={{
                    color: "#dc3545",
                    paddingLeft: ".5rem",
                  }}
                />
              </>
            }
            onClick={() => handleCancel()}
            variant="text"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
