import Typography from "@material-ui/core/Typography";
import Select from "react-select";
import { cqrFontList, fontList } from "./fontList";

export const FontSelect = (props) => {
  const {
    isDisabled = false,
    label = "Font",
    labelStyles,
    onChange,
    useCQRFonts = false,
    value,
    variant = "outlined",
  } = props;
  return (
    <>
      <Typography className={labelStyles} variant="subtitle1">
        {label}
      </Typography>
      <Select
        isDisabled={isDisabled}
        menuPortalTarget={document.querySelector("main")}
        menuPosition={"fixed"}
        onChange={onChange}
        options={useCQRFonts ? cqrFontList : fontList}
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        value={value}
        variant={variant}
      />
    </>
  );
};
