import { createSlice } from "@reduxjs/toolkit";

import { initInventoryHistory } from "./thunks/init";
import * as thunks from "./thunks/thunks";
import { extraReducers } from "./thunks/extraReducers";

export const inventoryHistorySlice = createSlice({
  name: "inventoryHistory",
  initialState: {
    init: {
      status: "idle",
    },
    filters: {},
    histories: [],
    count: 0,
    showInstructions: false,
  },
  reducers: {},
  extraReducers: {
    ...extraReducers,
  },
});

// Action creators are generated for each case reducer function
// const {} = devicesSlice.actions;

export { initInventoryHistory, thunks };

export default inventoryHistorySlice.reducer;
