import { date_interval_util } from "../../../../../utils/date_time_utils";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";

export default function RadioButtonsGroup(props) {
  const { filters, setFilters, timeZone = {} } = props;

  const handleChange = (event) => {
    const selection = (event.target).value;
    if (selection !== "custom") {
      const { startDate, endDate } = date_interval_util(selection, timeZone);
      setFilters((f) => {
        return {
          ...f,
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          dateSelection: selection,
        };
      });
    } else {
      setFilters((f) => {
        return {
          ...f,
          dateSelection: selection,
        };
      });
    }
  };
  return (
    <Grid item xs={12}>
      <label>
        Date Interval{" "}
        <Tooltip title="Select date range for asset information.">
          <i className="fas fa-info-circle fa-sm"></i>
        </Tooltip>{" "}
      </label>
      <RadioGroup
        aria-label="dateSelection"
        name="dateSelection"
        row
        value={filters.dateSelection || "custom"}
        onChange={handleChange}
      >
        <FormControlLabel value="daily" control={<Radio />} label="Daily" />
        <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
        <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
        <FormControlLabel value="custom" control={<Radio />} label="Custom" />
      </RadioGroup>
    </Grid>
  );
}
