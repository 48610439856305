import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1rem 0",
  },
  container: {
    padding: "1.5rem 0",
    textAlign: "center",
  },
}));

export default function ConfigureZonesModalContent(props) {
  const { selectedFacility, setModalState, setRedirect } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.container} container>
      {/* Content */}
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Would you like to configure your zones to view all mount points on
          facility map?
        </Typography>
      </Grid>

      <Grid className={classes.buttonContainer} item xs={12}>
        {/* Cancel */}
        <Grid item xs={4}>
          <MaterialUiButton
            color="cancel"
            label="CANCEL"
            fullWidth={true}
            onClick={() => {
              setModalState((prevState) => ({
                ...prevState,
                modalShow: false,
              }));
            }}
          />
        </Grid>

        {/* Yes */}
        <Grid item xs={4}>
          <MaterialUiButton
            label="YES"
            fullWidth={true}
            onClick={() => {
              setRedirect({
                isRedirect: true,
                pathName: "/facilities",
                state: {
                  facility:selectedFacility,
                  viewZones:true
                },
              });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
