import { 
  Grid, 
  Slider, 
  Tooltip,
 } from "@material-ui/core";

 function valuetext(value) {
  return `${value}`;
}

export default function ZoomSlider(props) {
  const { filters, setFilters } = props;
  return (
    <Grid item xs={12}>
      <label>
        {" "}
        Default Map Zoom Level{" "}
        <Tooltip title="Set default zoom level on overview map. This will cause your map to load with a preset zoom level.">
          <i className="fas fa-info-circle fa-sm"></i>
        </Tooltip>{" "}
      </label>
      <Slider
        defaultValue={4}
        getAriaValueText={valuetext}
        aria-labelledby="zoom-slider"
        valueLabelDisplay="auto"
        step={1}
        marks
        min={1}
        max={22}
        value={filters.defaultZoomLevel}
        onChange={(e, newValue) => {
          setFilters((f) => {
            return { ...f, defaultZoomLevel: newValue };
          });
        }}
      />
    </Grid>
  );
}
