import { InputLabel } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export default function SimpleTextField(props) {
  const {
    autoComplete,
    autoFocus,
    classes,
    color,
    defaultValue,
    disabled = false,
    error = false,
    errorText = "Required Field",
    externalLabel = true,
    FormHelperTextProps,
    fullWidth = true,
    helperText,
    id,
    InputLabelProps = {},
    InputProps = {},
    inputProps,
    label = "",
    margin,
    // maxRows,
    // minRows,
    multiline = false,
    name,
    onChange,
    onClick = () => {},
    readOnly = false,
    required,
    rows,
    size = "small",
    type = "",
    value,
    variant = "outlined",
  } = props;
  const useStyles = makeStyles((theme) => ({
    label: {
      color:"#6D6E70",
      fontFamily:"Lato",
      fontWeight:"bold"
    }
  }));
  const textFieldClasses = useStyles();

  return (
    <>
      {externalLabel ? 
        <InputLabel className={textFieldClasses.label}>{label}</InputLabel>
      : null}
      <TextField
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        classes={classes}
        color={color}
        defaultValue={defaultValue}
        disabled={disabled}
        error={error}
        FormHelperTextProps={FormHelperTextProps}
        fullWidth={fullWidth}
        helperText={error ? errorText : helperText}
        label={externalLabel ? null : label}
        id={id}
        InputLabelProps={{
          ...InputLabelProps,
          required: false,
          style: {
            ...InputLabelProps?.style,
          },
        }}
        inputProps={inputProps}
        InputProps={{
          ...InputProps,
          readOnly,
        }}
        margin={margin}
        multiline={multiline}
        name={name}
        onChange={(event) => {
          onChange(event);
        }}
        onClick={() => onClick()}
        required={required}
        rows={rows}
        type={type}
        size={size}
        value={value}
        variant={variant}
      />
    </>
  );
}
