import { Box, Grid, makeStyles } from "@material-ui/core";
import HoverIconButton from "../../../components/ReusedComponents/HoverIconButton";
import Map from "./map";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    display: "flex",
    height: "75vh",
    justifyContent: "center",
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "rgb(46, 105, 255, 0.1)",
    },
  },
}));

export default function FacilitiesMap(props) {
  const { setView, apiUrl, token } = props;
  const classes = useStyles();

  return (
    <Box mt={3}>
      <Grid container>
        <Grid item xs={12}>
          <Box textAlign="left">
            <h3>Facilities Map</h3>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={4} mb={3} textAlign="right">
            <span
              onClick={() => setView({ viewFacilityMap: false })}
            >
              <HoverIconButton
                icon="fas fa-arrow-left"
                iconDirection="left"
                text="Back to Facilities"
              />
            </span>
          </Box>
        </Grid>
        <Grid className={classes.mapContainer} id="facilitiesMap" item xs={12}>
          <Map
            apiUrl={apiUrl}
            defaultZoomLevel={4}
            height="100%"
            token={token}
            width="90%"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
