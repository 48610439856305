import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  label: {
    fontFamily: "Lato",
    fontWeight: 500,
    fontSize: "1rem",
  },
});

export default function RecursiveTreeView(props) {
  const classes = useStyles();

  const data = React.useMemo(() => props.data, [props.data]);

  const renderTree = (nodes) => (
    <TreeItem
      classes={{ label: classes.label }}
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.label}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={data?.defaultExpanded || ["root"]}
      defaultSelected={data?.defaultSelected || "root"}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeSelect={(e, value) => {}}
    >
      {data ? renderTree(data) : null}
    </TreeView>
  );
}
