import { lazy, Suspense } from "react";
import { isEqual } from "lodash";
import { onLogout } from "../globalStore/slices/user/userSlice";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userSelectors } from "../globalStore/slices/user/userSlice";
import { useSelector } from "react-redux";
import AssetSnapshot from "../pages/assetSnapshot/AssetSnapshot";
import AssetStatus from "../pages/assets/assetStatus/AssetStatus";
import AuthLayout from "../components/Layout/AuthLayout";
import BatchHistory from "../pages/log/BatchHistory";
import Dashboard from "../pages/dashboard/Dashboard";
import EditOrganization from "../pages/organizations/EditOrganization";
import EditUser from "../pages/users/EditUser";
import Facilities from "../pages/facilities/Index";
import FacilityOverview from "../pages/facilities/FacilityOverview/FacilityOverview";
import GenerateCQR from "../pages/qr/GenerateCQR";
import InventoryHistory from "../pages/inventory/inventoryHistory/InventoryHistory";
import InventoryStatus from "../pages/inventory/inventoryStatus/InventoryStatus";
import Organizations from "../pages/organizations/Organizations";
import Products from "../pages/products/Products";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

// Routes that need to be lazy loaded. This is to control our bundle size
const AssetHistory = lazy(() =>
  import("../pages/assets/assetHistory/AssetHistory")
);
const AssetOperationsDashboard = lazy(() =>
  import("../pages/dashboard/AssetOperationsDashboard/AssetOperationsDashboard")
);
const Batches = lazy(() => import("../pages/batches/Batches"));
const Devices = lazy(() => import("../pages/devices/Devices"));
const PCABuilder = lazy(() => import("../pages/pcaBuilder/pcaBuilder"));
const ProductDashboard = lazy(() =>
  import("../pages/dashboard/ProductDashboard/ProductDashboard")
);
const Settings = lazy(() => import("../pages/settings/Settings"));
const Users = lazy(() => import("../pages/users/Users"));
const WarehouseOperationsDashboard = lazy(() =>
  import(
    "../pages/dashboard/WarehouseOperationsDashboard/WarehouseOperationsDashboard"
  )
);

const AuthContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setAuth } = props;
  const { appUserTypeSelector, viewPermissionsSelector, checkSessionSelector } =
    userSelectors;

  // auth and routing data
  const appUserType = useSelector(appUserTypeSelector);
  const viewPermissions = useSelector(viewPermissionsSelector);
  const checkSession = useSelector(checkSessionSelector, isEqual);

  const {
    showAssetHistory,
    showBatches,
    showDevices,
    showFacilities,
    showInventory,
    showOrganizations,
    showProducts,
    showSettings,
    showUsers,
  } = viewPermissions;

  // KEEP THESE GLOBAL STATE OBJECTS IN TOP LEVEL AND PASS THROUGH PROPS, AS A RE-RENDER WOULD NOT BE UNDESIRABLE IF THESE EVER CHANGED
  const appUserId = useSelector((state) => state.user.appUserId);
  const token = useSelector((state) => state.user.token);
  const organizationId = useSelector((state) => state.user.organizationId);
  const organization = useSelector((state) => state.organization.organization);
  const userActions = useSelector((state) => state.user.userActions, isEqual);
  const usersConsoleRole = useSelector((state) => state.user.usersConsoleRole);
  const timeZone = useSelector(
    (state) => state.user.userPropertiesMap.propertiesMap.timeZone,
    isEqual
  );

  const logout = () => {
    dispatch(onLogout());
    setAuth((auth) => {
      return {
        ...auth,
        isAuthed: false,
      };
    });
    history.push("/login");
  };

  // Redux selectors take two arguments, the selector function argument (e.g., state=>state.organization.facilities[facilityId])) and an optional equality function (default equality function is shallow comparison, which doesn't work with arrays or objects)

  const PrivateRoute = ({
    component: Component,
    isBlocked = false,
    ...rest
  }) => {
    if (isBlocked) {
      return (
        <Route
          {...rest}
          render={() => (
            <div>
              You are not authorized to view {rest.location.pathname || "this"}{" "}
              in LXConnect. Please contact your system administator and inquire
              about your permissions in LXConnect.
            </div>
          )}
        />
      );
    } else {
      return (
        <Route
          {...rest}
          render={(props) => (
            <>
              <Component
                {...props}
                apiUrl={apiUrl}
                appUserType={appUserType}
                key={rest?.location?.pathname}
                organization={organization}
                organizationId={organizationId}
                timeZone={timeZone}
                token={token}
                userId={appUserId}
                userRoles={userActions}
                usersConsoleRole={usersConsoleRole}
              />
            </>
          )}
        />
      );
    }
  };

  const dashboardPicker = (role) => {
    switch (role) {
      case "Product":
        return ProductDashboard;
      case "Asset-Operations":
        return AssetOperationsDashboard;
      case "Warehouse-Operations":
        return WarehouseOperationsDashboard;
      default:
        return Dashboard;
    }
  };

  // as long as the session is valid, render auth container
  return checkSession.sessionValid ? (
    <AuthLayout apiUrl={apiUrl} organizationId={organizationId} token={token}>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          {/* Home */}
          <PrivateRoute
            exact
            path={["/", "/home"]}
            component={dashboardPicker(appUserType)}
          />

          {/* Generate CQR */}
          <PrivateRoute exact path="/generateCQR/" component={GenerateCQR} />

          {/* Asset History */}
          <PrivateRoute
            exact
            path="/assetHistory"
            component={AssetHistory}
            isBlocked={!showAssetHistory}
          />

          {/* Asset Status */}
          <PrivateRoute
            exact
            path="/assetStatus"
            component={AssetStatus}
            isBlocked={!showAssetHistory}
          />

          {/* Asset Snapshot */}
          <PrivateRoute
            exact
            path="/assetSnapshot/:assetId"
            component={AssetSnapshot}
            isBlocked={!showAssetHistory}
          />

          <PrivateRoute
            exact
            path="/inventorySnapshot/:assetId"
            component={AssetSnapshot}
            isBlocked={false}
          />

          {/* Batches */}
          <PrivateRoute
            exact
            path="/batches"
            component={Batches}
            isBlocked={!showBatches}
          />

          {/* Batch History */}
          <PrivateRoute
            exact
            path="/batches/:batchId/history/:assetType*"
            component={BatchHistory}
            isBlocked={!showBatches}
          />

          {/* Inventory History */}
          <PrivateRoute
            exact
            path="/inventoryHistory"
            component={InventoryHistory}
            isBlocked={!showInventory}
          />

          {/* Inventory Status */}
          <PrivateRoute
            exact
            path="/inventoryStatus"
            component={InventoryStatus}
            isBlocked={!showInventory}
          />

          {/* Organizations */}
          <PrivateRoute
            exact
            path="/organizations"
            component={Organizations}
            isBlocked={!showOrganizations}
          />

          {/* Organization */}
          <PrivateRoute
            exact
            path="/organizations/:organizationId"
            component={EditOrganization}
            isBlocked={!showOrganizations}
          />

          {/* Facilities */}
          <PrivateRoute
            exact
            path="/facilities"
            component={Facilities}
            isBlocked={!showFacilities}
          />

          {/* Facility */}
          <PrivateRoute
            exact
            path="/facilities/overview"
            component={FacilityOverview}
            isBlocked={!showFacilities}
          />

          {/* Products */}
          <PrivateRoute
            exact
            path="/products"
            component={Products}
            isBlocked={!showProducts}
          />

          {/* PCA */}
          <PrivateRoute
            exact
            path="/pca/:connectPageName/:productId*"
            component={PCABuilder}
          />

          {/* Devices */}
          <PrivateRoute
            exact
            path="/devices"
            component={Devices}
            isBlocked={!showDevices}
          />

          {/* Settings */}
          <PrivateRoute
            exact
            path="/settings"
            component={Settings}
            isBlocked={!showSettings}
          />

          {/* Settings Page */}
          <PrivateRoute
            exact
            path="/settings/:page"
            component={Settings}
            isBlocked={!showSettings}
          />

          {/* Settings Page Tab */}
          <PrivateRoute
            exact
            path="/settings/:page/:tab"
            component={Settings}
            isBlocked={!showSettings}
          />

          {/* Users */}
          <PrivateRoute
            exact
            path="/users"
            component={Users}
            isBlocked={!showUsers}
          />

          {/* User */}
          <PrivateRoute
            exact
            path="/users/:appUserId"
            component={EditUser}
            isBlocked={!showUsers}
          />

          {/* Asset Operations Dashboard */}
          <PrivateRoute
            exact
            path="/dashboards/assetOperations"
            component={AssetOperationsDashboard}
            isBlocked={false}
          />

          {/* Product Dashboard */}
          <PrivateRoute
            exact
            path="/dashboards/product"
            component={ProductDashboard}
            isBlocked={false}
          />

          {/* Asset Warehouse Dashboard */}
          <PrivateRoute
            exact
            path="/dashboards/assetWarehouse"
            component={Dashboard}
            isBlocked={false}
          />

          {/* Logout */}
          <Route path="/logout" render={() => logout()} />
        </Switch>
      </Suspense>
    </AuthLayout>
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: props.location },
      }}
    />
  );
};

export default AuthContainer;
