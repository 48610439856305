import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import SimpleTextField from "../Forms/FieldTypes/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  removeField: {
    textAlign: "right",
  },
  root: {
    marginTop: ".5rem",
  },
}));

export default function CustomDataField(props) {
  const { title, value, removeField, count, handleChange, readOnly } = props;
  const [deleteCustomField, setDeleteCustomField] = useState(false);
  const classes = useStyles();
  const deleteTextFlow = deleteCustomField ? (
    <div>
      <span className="mx-2">Are you sure?</span>
      <span
        className="pointer mx-2"
        onClick={() => {
          removeField(count);
        }}
      >
        <i className="fas fa-check" />
      </span>
      <span
        className="pointer mx-2"
        onClick={() => {
          setDeleteCustomField(false);
        }}
      >
        <i className="fas fa-times" />
      </span>
    </div>
  ) : (
    <span
      className="pointer h6"
      onClick={() => {
        setDeleteCustomField(true);
      }}
    >
      Remove Field
    </span>
  );
  return (
    <Grid item className={classes.root} xs={12}>
      <div className={classes.removeField}>
        {readOnly ? "" : deleteTextFlow}
      </div>
      <SimpleTextField
        id="description"
        label={title}
        onChange={(event) => {
          handleChange(event.target.value, count);
        }}
        readOnly={readOnly}
        required
        value={value}
      />
    </Grid>
  );
}
