import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";
import { date_interval_util } from "../utils";

export default function RadioButtonsGroup(props) {
  const { filters, setFilters, timeZone = {} } = props;

  const handleChange = (event) => {
    const selection = (event.target).value;
    if (selection !== "custom") {
      const { startDate, endDate } = date_interval_util(selection, timeZone);
      setFilters((f) => {
        return {
          ...f,
          startDate,
          endDate,
          dateSelection: selection,
        };
      });
    } else {
      setFilters((f) => {
        return {
          ...f,
          dateSelection: selection,
        };
      });
    }
  };
  return (
    <Grid item xs={12}>
      <label>
        Date Interval{" "}
        <Tooltip
          title={`Select date interval for tracking event activity. "Daily" presents each day within the past two weeks, "weekly" presents each week within the past three months, and "monthly" presents each month within the last year. "Custom" enables you to select a date interval of your choosing.`}
        >
          <i className="fas fa-info-circle fa-sm"></i>
        </Tooltip>{" "}
      </label>
      <RadioGroup
        aria-label="dateSelection"
        name="dateSelection"
        row
        value={filters.dateSelection || "custom"}
        onChange={handleChange}
        id={"eventActivityDateSelectionRadio"}
      >
        <FormControlLabel value="daily" control={<Radio />} label="Daily" />
        <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
        <FormControlLabel
          value="monthly"
          control={<Radio id="eventActivityMonthlyRadio" />}
          label="Monthly"
        />
        <FormControlLabel value="custom" control={<Radio />} label="Custom" />
      </RadioGroup>
    </Grid>
  );
}
