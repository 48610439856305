import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useRef, useEffect } from "react";
import { createPortal } from "react-dom";

import PropTypes from "prop-types";

import {
  HeaderRow,
  HeaderCell,
  StyledTableSortLabel,
} from "./StyledComponents";

const getItemStyle = ({ isDragging, isDropAnimating }, draggableStyle) => {
  return {
    ...draggableStyle,
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    backgroundColor: "#3D5BA9",
    ...(isDragging && {
      borderRadius: "4px",
      textAlign: "center",
      backgroundColor: "#A0C46D",
    }),
    ...(!isDragging && { transform: "translate(0,0)" }),
    ...(isDropAnimating && { transitionDuration: "0.2s" }),
  };
};

// using React portals to dynamically render draggables in the document body
const useDraggableInPortal = () => {
  const self = useRef({}).current;

  useEffect(() => {
    const div = document.createElement("div");
    div.style.position = "absolute";
    div.style.pointerEvents = "none";
    div.style.top = "0";
    div.style.width = "100%";
    div.style.height = "100%";
    self.elt = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [self]);

  return (render) => (provided, ...args) => {
    const element = render(provided, ...args);
    if (provided.draggableProps.style.position === "fixed") {
      return createPortal(element, self.elt);
    }
    return element;
  };
};

export default function HeaderGroups(props) {
  const {
    headerGroups = [],
    allColumns = [],
    setColumnOrder = () => null,

    classes = {},
    isSelect = false,
    onColumnDrop = () => null,
  } = props;

  const currentColOrder = useRef();

  const renderDraggable = useDraggableInPortal();

  return headerGroups.map((headerGroup, idx) => (
    <DragDropContext
      key={idx}
      onDragStart={() => {
        currentColOrder.current = allColumns.map((o) => o.id);
      }}
      onDragUpdate={(dragUpdateObj, b) => {
        const colOrder = [...currentColOrder.current];
        const sIndex = dragUpdateObj.source.index;
        const dIndex =
          dragUpdateObj.destination && dragUpdateObj.destination.index;
        // console.log(
        //   "onDragUpdate",
        //   dragUpdateObj.destination.index,
        //   dragUpdateObj.source.index
        // );

        // if table has selection, prevent selection from being dropped on
        if (isSelect && dragUpdateObj?.destination?.index === 0) {
          return null;
        }
        if (typeof sIndex === "number" && typeof dIndex === "number") {
          colOrder.splice(sIndex, 1);
          colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
          onColumnDrop(colOrder);
          setColumnOrder(colOrder);
        }
      }}
    >
      <Droppable
        // change droppable ID if using multiple header groups
        droppableId={"droppable"}
        direction="horizontal"
      >
        {(droppableProvided, snapshot) => (
          <HeaderRow
            {...headerGroup.getHeaderGroupProps()}
            ref={droppableProvided.innerRef}
          >
            {headerGroup.headers.map((column, index) => {
              const { style, ...extraProps } = column.getHeaderProps();
              return (
                <Draggable
                  key={column.id}
                  draggableId={column.id}
                  index={index}
                  isDragDisabled={
                    !column.accessor ||
                    column.id === "selection" ||
                    !props.dragEnabled
                  }
                >
                  {renderDraggable((provided, snapshot) => (
                    <HeaderCell
                      {...(column.id === "selection" || column.disableSortBy
                        ? column.getHeaderProps()
                        : column.getHeaderProps({
                            ...column.getSortByToggleProps(),
                          }))}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      {...extraProps}
                      ref={provided.innerRef}
                      style={{
                        ...getItemStyle(
                          snapshot,
                          provided.draggableProps.style
                        ),
                        ...style,
                      }}
                    >
                      {column.render("Header")}
                      {column.id !== "selection" && !column.disableSortBy ? (
                        <StyledTableSortLabel
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        />
                      ) : null}

                      {/* placeholder to take care of console warning */}
                      <div style={{ display: "none" }}>
                        {droppableProvided.placeholder}
                      </div>

                      {/* resizer */}
                      {column.id !== "selection" ? (
                        <button
                          style={{
                            borderStyle: "hidden!important",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          {...column.getResizerProps()}
                          className={classes.resizer}
                        />
                      ) : null}
                    </HeaderCell>
                  ))}
                </Draggable>
              );
            })}
          </HeaderRow>
        )}
      </Droppable>
    </DragDropContext>
  ));
}

HeaderGroups.propTypes = {
  headerGroups: PropTypes.array.isRequired,
  allColumns: PropTypes.array.isRequired,
  setColumnOrder: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isSelect: PropTypes.bool,
  onColumnDrop: PropTypes.func,
  dragEnabled: PropTypes.bool,
};
