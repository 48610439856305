import { Component, createRef } from "react";
import MapGL, {
  Marker,
  NavigationControl,
  FullscreenControl,
} from "react-map-gl";
import { Editor, DrawPolygonMode, EditingMode } from "react-map-gl-draw";

import Pin from "./pin";

import * as MapStyles from "./styles";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { getFeatureStyle, getEditHandleStyle } from "./style";

import AreaText from "./area-text";

const TOKEN =
  "pk.eyJ1IjoiZGFuaWVsZG91YW5na2Vzb25lIiwiYSI6ImNqeGYxMTF5YTA5NjQzeW1mMmM4eXIxdWIifQ.jqF1at0nWobKgC3t1OY4kw";

export default class PolygonMap extends Component {
  constructor(props) {
    super(props);
    this._editorRef = null;
    this.initRef = createRef(false);

    this.state = {
      viewport: {
        latitude: props.latitude || 33.78695,
        longitude: props.longitude || -84.382744,
        zoom: 12,
        bearing: 0,
        pitch: 0,
      },
      mode: null,
      selectedFeatureIndex: null,
      satView: false,
    };
  }

  _updateViewport = (viewport) => {
    this.setState({ viewport });
  };

  _onSelect = (options) => {
    this.setState({
      selectedFeatureIndex: options && options.selectedFeatureIndex,
    });
  };

  _onDelete = () => {
    const selectedIndex = this.state.selectedFeatureIndex;
    if (selectedIndex !== null && selectedIndex >= 0) {
      this._editorRef.deleteFeatures(selectedIndex);
      this.props.setPolygon({
        editsMade: true,
        features: {},
      });
    }
  };

  _onUpdate = ({ editType }) => {
    if (editType === "addFeature") {
      this.setState({
        mode: new EditingMode(),
      });
    }
  };

  _renderDrawTools = () => {
    // copy from mapbox
    return (
      <div className="mapboxgl-ctrl-bottom-right">
        <div className="mapboxgl-ctrl-group mapboxgl-ctrl">
          <button
            className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
            title="Polygon tool (p)"
            onClick={() => this.setState({ mode: new DrawPolygonMode() })}
          />
          <button
            className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
            title="Delete"
            onClick={this._onDelete}
          />
        </div>
      </div>
    );
  };

  _renderAreaText = () => {
    const features = this._editorRef && this._editorRef.getFeatures();
    let featureIndex = this.state.selectedFeatureIndex;
    if (features && featureIndex === null) {
      featureIndex = features.length - 1;
    }
    const polygon = features && features.length ? features[featureIndex] : null;
    return (
      <AreaText
        containerComponent={this.props.containerComponent}
        polygon={polygon}
        satView={this.state.satView}
      />
    );
  };

  // if passing down a polygon, component will update; add polygon to feature list, turn on editing mode so we can select the initial polygon, and set the ref to true so we don't do this on each update
  componentDidUpdate() {
    if (this.props.polygon?.features && !this.initRef.current) {
      this._editorRef.addFeatures({ ...this.props.polygon?.features });
      this.setState({
        mode: !this.props.readOnly ? new EditingMode() : null,
      });
      this.initRef.current = true;
    }
  }

  render() {
    const { viewport, mode } = this.state;
    return (
      <MapGL
        {...viewport}
        width={this.props.width || "100%"}
        height={this.props.height || "100%"}
        style={{
          // border: "rgba(50, 53, 93, 0.514) solid 2px",
          // borderRadius: "4px",
          margin: this.props.margin || "",
        }}
        mapStyle={
          this.state.satView
            ? "mapbox://styles/mapbox/satellite-v9"
            : "mapbox://styles/mapbox/light-v10"
        }
        mapboxApiAccessToken={TOKEN}
        onViewportChange={this._updateViewport}
        attributionControl={false}
      >
        <Editor
          ref={(_) => (this._editorRef = _)}
          clickRadius={12}
          mode={mode}
          onSelect={(e) => {
            this._onSelect(e);
          }}
          // features can be passed in and manually controlled from outside components. Setting the features prop will disable the internal editing logic.
          // features={[this.props.polygon]}
          onUpdate={(e) => {
            const features = this._editorRef && this._editorRef.getFeatures();

            if (features && features.length) {
              // if a single polygon draw tool, and there was already a polygon drawn, remove the first one (the one that came before) and refetch the polygons to return to the parent component
              if (this.props.singlePolygon && features.length > 1) {
                this._editorRef.deleteFeatures(0);
                this.props.setPolygon({
                  editsMade: true,
                  features: this._editorRef.getFeatures()[0],
                });
              } else {
                this.props.setPolygon({
                  editsMade: true,
                  features: features[0],
                });
              }
            }
            this._onUpdate(e);
          }}
          editHandleShape={"circle"}
          featureStyle={getFeatureStyle}
          editHandleStyle={getEditHandleStyle}
        />
        <Marker
          longitude={this.props.longitude}
          latitude={this.props.latitude}
          offsetTop={-20}
          offsetLeft={-10}
        >
          <Pin size={15} fill={this.state.satView ? "#FFC854" : "#32355C"} />
        </Marker>
        {!this.props.readOnly ? this._renderDrawTools() : null}
        {this._renderAreaText()}
        {this.props.readOnly ? (
          <div style={MapStyles.fullscreenControlStyle}>
            <FullscreenControl />
          </div>
        ) : null}

        <div
          style={
            !this.props.readOnly
              ? MapStyles.fullscreenControlStyle
              : MapStyles.navStyle
          }
        >
          <NavigationControl />
        </div>
        <div style={MapStyles.darkModeStyle}>
          <i
            className={
              this.state.satView
                ? "fa fa-road darkmode-icon"
                : "fa fa-globe darkmode-icon"
            }
            style={
              this.state.satView ? { color: "#FFC854" } : { color: "#32355B" }
            }
            onClick={() => {
              this.setState({ ...this.state, satView: !this.state.satView });
            }}
          ></i>
        </div>
      </MapGL>
    );
  }
}
