import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  label: {
    color: "#6D6E70",
    fontFamily: "Lato Semibold",
    fontWeight: "bold",
    marginBottom: 0
  },
}));

export default function SimpleSwitch(props) {
  const {
    checked,
    color = "primary",
    disabled = false,
    formControlLabelStyles,
    inputProps = {},
    label = "",
    labelPlacement,
    labelStyles,
    onChange,
    required,
    size,
  } = props;
  const classes = useStyles();
  return (
    <FormControlLabel
      className={formControlLabelStyles}
      control={
        <Switch
          checked={checked}
          color={color}
          disabled={disabled}
          inputProps={inputProps}
          onChange={onChange}
          required={required}
          size={size}
        />
      }
      label={
        <InputLabel className={`${classes.label} ${labelStyles}`}>
          {label}
        </InputLabel>
      }
      labelPlacement={labelPlacement}
    />
  );
}
