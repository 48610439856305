import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import _ from "lodash";
import { userSelectors } from "../../../globalStore/slices/user/userSlice";
import { useSelector } from "react-redux";

import { Redirect } from "react-router-dom";
import {
  seperateActionsByUserRole,
  quickLinksHelper,
} from "./Utils/QuickLinksUtils";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import "./AppBar.css";

const useStyles = makeStyles((theme) => ({
  button: {
    "&:focus": {
      outline: "none",
    },
  },
  carrot: {
    marginLeft: "1rem",
  },
  formControl: {
    margin: 0,
    marginBottom: "1rem",
    display: "flex",
  },
  formContainer: {
    paddingRight: "1rem",
  },
  icon: {
    marginLeft: "2rem",
  },
  label: {
    color: "black",
    display: "flex",
    fontWeight: "bold",
    justifyContent: "space-around",
  },
  menuItem: {
    backgroundColor: "#f9f9f9!important",
    color: "#383837",
    "&:hover": {
      color: "white",
      backgroundColor: "#5884f5!important",
    },
  },
  hiddenIcon: {
    display: "none",
  },
}));

export default function QuickLinks() {
  const classes = useStyles();
  const [redirect, setRedirect] = useState({ isRedirect: false, path: {} });

  const userPropertiesMap = useSelector(
    userSelectors.userPropertiesMapSelector,
    _.isEqual
  );
  const usersConsoleRole = useSelector(userSelectors.usersConsoleRoleSelector);

  const [state, setState] = useState({
    redirect: false,
    sortedLinks: {},
    values: {},
  });

  const quickLink = (item, index) => {
    const { id, label, actions } = item;
    return (
      <Grid
        className={classes.formContainer}
        item
        key={`${id}-${index}`}
        xs={4}
      >
        <FormControl
          className={`App-Bar ${classes.formControl}`}
          key={`${id}-${index}`}
        >
          <InputLabel
            className={classes.label}
            id={`${id}-${index}-selection`}
            shrink={false}
          >
            {label}
            <i className={`${classes.icon} fas fa-caret-down fa-lg`}></i>
          </InputLabel>

          {/* Decided to go with the select component instead of the menuItem component.
        The menuItem component is basically a deconstructed select component and requires
        a lot of unneccesary steps to get to work / position drop down  */}

          <Select
            disableUnderline
            id={`${id}-${index}-selection`}
            IconComponent={() => {
              return <FavoriteIcon className={classes.hiddenIcon} />;
            }}
            MenuProps={{
              anchorOrigin: {
                horizontal: "left",
                vertical: "bottom",
              },
              transformOrigin: {
                horizontal: "left",
                vertical: "top",
              },
              getContentAnchorEl: null,
            }}
            onChange={(event) => {
              setState((prevState) => ({
                ...prevState,
                values: {
                  ...prevState.values,
                  [`${label}-${index}`]: event.target.value,
                },
              }));
            }}
          >
            {Object.keys(actions).map((action) => {
              // Add Asset used to be an option here. But we have since removed it and
              // needed to make sure pre-existing users would not be effected by this.
              if (action !== "Add Asset") {
                return (
                  <MenuItem
                    className={classes.menuItem}
                    key={`${action}-${id}`}
                    onClick={(event) => {
                      const quickLinkValid = quickLinksHelper(event.target.textContent)
                      if(quickLinkValid) {
                        setRedirect({
                          isRedirect: true,
                          path: quickLinkValid,
                        });
                        setTimeout(() => {
                          setRedirect({
                            isRedirect: false,
                            path: {},
                          });
                        }, 100);

                      }
                    }}
                  >
                    {action}
                  </MenuItem>
                );
              }
              return null;
            })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  useEffect(() => {
    let values = {};
    // create new reference of userPropertiesMap from redux selector so we can mutate it; using deepClone because it's an object
    let propMapCopy = _.cloneDeep(userPropertiesMap);
    const sortedLinks = seperateActionsByUserRole(propMapCopy);
    Object.keys(sortedLinks).forEach((item, index) => {
      values[`${item}-${index}`] = { value: "" };
    });
    setState({ sortedLinks, values, redirect: false });
  }, [userPropertiesMap]);

  return (
    <Grid container>
      {redirect.isRedirect ? <Redirect to={redirect.path} /> : null}

      {usersConsoleRole !== "Lite"
        ? Object.keys(state.sortedLinks).map((item, index) => {
            const actionsCount = Object.keys(
              state.sortedLinks[item].actions
            ).length;
            if (actionsCount !== 0) {
              return quickLink(state.sortedLinks[item], index);
            }
            return null;
          })
        : null}
    </Grid>
  );
}
