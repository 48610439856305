import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

export default function TextFieldWithExternalLabel(props) {
  const {
    className,
    disabled = false,
    error = false,
    errorText = "Required Field",
    helperText,
    id,
    InputProps = {},
    label = "",
    labelPlacement = "top",
    labelStyles,
    multiline = false,
    onChange,
    readOnly = false,
    required,
    size,
    type = "",
    value,
    variant = "outlined",
  } = props;

  const useStyles = makeStyles(() => ({
    root: {
      width: "100%",
    },
    label: {
      fontWeight: "500",
      marginBottom: ".2rem",
    },
    horizontalLabel: {
      display: "flex",
      alignItems: "center",
    },
    marginRight: {
      marginRight: ".5rem",
    },
  }));

  const classes = useStyles();
  return (
    <div className={labelPlacement === "start" ? classes.horizontalLabel : ""}>
      {label ? (
        <div
          className={`${classes.label} ${
            labelPlacement === "start" ? classes.marginRight : ""
          } ${labelStyles} `}
        >
          {label ? label : "Select"}
        </div>
      ) : null}
      <TextField
        InputLabelProps={{ required: false }}
        className={`${classes.root} ${className}`}
        disabled={disabled}
        error={error}
        required={required}
        helperText={error ? errorText : helperText}
        id={id}
        InputProps={{
          ...InputProps,
          readOnly,
        }}
        multiline={multiline}
        onChange={(event) => {
          onChange(event);
        }}
        size={size}
        type={type}
        value={value}
        variant={variant}
      />
    </div>
  );
}
