import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { putCentroid } from "../../API/API";
import { Tooltip } from "@material-ui/core";
import { validateLat, validateLong } from "../../utils/utils";
import CentroidRadiusMap from "../../Maps/CentroidRadiusMap/CentroidRadiusMap";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import MaterialUiButton from "../../../../../components/Buttons/MaterialUiButton/MaterialUiButton"
import SimpleTextField from "../../../../../components/Forms/FieldTypes/TextField";

const useStyles = makeStyles((theme) => ({
  centroid: {},
  form: {
    marginTop: theme.spacing(1),
    width: "100%", // Fix IE 11 issue.
  },
  button: {
    marginTop: theme.spacing(1),
    width: "33%",
  },
}));

function Centroid(props) {
  const initLong =
    props.selectedLocation?.centroidJSON?.x ||
    props.selectedLocation?.location?.longitude ||
    0;
  const initLat =
    props.selectedLocation?.centroidJSON?.y ||
    props.selectedLocation?.location?.latitude ||
    0;

  const initRadius = props.selectedLocation?.radius;
  const classes = useStyles();

  const [centroid, setCentroid] = useState({
    latitude: {
      value: initLat,
      valid: true,
    },
    longitude: {
      value: initLong,
      valid: true,
    },
    radius: initRadius * 1000 || 100,
    changesMade: false,
  });

  useEffect(() => {
    if (validateLat.test(centroid.latitude.value.toFixed(5))) {
      setCentroid((c) => {
        return {
          ...c,
          latitude: {
            value: centroid.latitude.value,
            valid: true,
          },
        };
      });
    } else {
      setCentroid((c) => {
        return {
          ...c,
          latitude: {
            value: centroid.latitude.value,
            valid: false,
          },
        };
      });
    }
  }, [centroid.latitude.value]);

  useEffect(() => {
    if (validateLong.test(centroid.longitude.value.toFixed(5))) {
      setCentroid((c) => {
        return {
          ...c,
          longitude: {
            value: centroid.longitude.value,
            valid: true,
          },
        };
      });
    } else {
      setCentroid((c) => {
        return {
          ...c,
          longitude: {
            value: centroid.longitude.value,
            valid: false,
          },
        };
      });
    }
  }, [centroid.longitude.value]);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          putCentroid({ ...props }, centroid).then((res) => {
            props.onHide();
            if (res?.error) {
              props.notificationModal(`${res?.error}`, true);
            } else {
              props.notificationModal(
                `Facility area successfully updated.`,
                false
              );
              props.onSuccess(res);
            }
          });
        }}
        className={classes.form}
      >
        <h5 style={{ textAlign: "center" }}>
          Revise Area by Centroid and Radius{" "}
          <Tooltip title="Adjust the default attributes of the radius created by LXConnect around your address or lat/long coordinates (default is 400m).">
            <InfoIcon style={{ color: "#3D5BA9" }} fontSize="small" />
          </Tooltip>
        </h5>

        <Grid
          className={classes.centroid}
          container
          justify="center"
          spacing={2}
        >
          <Grid item xs={12}>
            {initLat && initLong ? (
              <div style={{ width: "80%", height: "65vh", margin: "auto" }}>
                <CentroidRadiusMap
                  latitude={centroid.latitude.value}
                  location={props.selectedLocation}
                  longitude={centroid.longitude.value}
                  radius={centroid.radius / 1000}
                  setCentroid={setCentroid}
                />
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={4}>
            <SimpleTextField
              autoFocus
              error={!centroid.latitude.valid ? true : false}
              fullWidth
              helperText={
                !centroid.latitude.valid ? "Must be a valid Latitude" : ""
              }
              id="latitude"
              label="Latitude"
              margin="normal"
              name="latitude"
              onChange={(e) =>
                setCentroid({
                  ...centroid,
                  latitude: {
                    ...centroid.latitude,
                    // default to zero, if NaN
                    value: parseFloat(e.target.value) || 0,
                  },
                  changesMade: true,
                })
              }
              required
              size="small"
              type="number"
              value={centroid.latitude.value}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SimpleTextField
              error={!centroid.longitude.valid ? true : false}
              fullWidth
              helperText={
                !centroid.longitude.valid ? "Must be a valid Longitude" : ""
              }
              id="longitude"
              label="Longitude"
              margin="normal"
              name="longitude"
              onChange={(e) =>
                setCentroid({
                  ...centroid,
                  longitude: {
                    ...centroid.longitude,
                    // default to zero, if NaN
                    value: parseFloat(e.target.value) || 0,
                  },
                  changesMade: true,
                })
              }
              required
              size="small"
              type="number"
              value={centroid.longitude.value}
              variant={"outlined"}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SimpleTextField
              fullWidth
              id="radius"
              inputProps={{ step: 10, min: 10, max: 1600 }}
              label="Radius (meters)"
              margin="normal"
              name="meters"
              onChange={(e) =>
                setCentroid({
                  ...centroid,
                  radius: e.target.value,
                  changesMade: true,
                })
              }
              required
              size="small"
              type="number"
              value={centroid.radius}
              variant="outlined"
            />
          </Grid>
          <MaterialUiButton
            className={classes.button}
            color="primary"
            disabled={
              centroid.latitude.valid &&
              centroid.longitude.valid &&
              centroid.radius &&
              centroid.changesMade
                ? false
                : true
            }
            fullWidth
            label="Submit"
            type="submit"
            variant="outlined"
          />
        </Grid>
      </form>
    </>
  );
}

export default Centroid;
