import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FacilityArea from "./Tabs/FacilityArea";
import HorizontalTabs from "../../../../components/Tabs/HorizontalTabs";
import Information from "./Tabs/Information";

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: "75vh",
    maxHeight: "75vh",
    overflow: "auto",
  },
  root: {
    margin: 0,
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

function FacilityDetail(props) {
  const { handleClose, open, title } = props
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby={title}
      className={classes.root}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={open || false}
    >
      <DialogTitle>
        {" "}
        {handleClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <HorizontalTabs noAppBarColor noElevation>
          <Information label="Information" {...props} />
          <FacilityArea label="Facility Area" {...props} />
        </HorizontalTabs>
      </DialogContent>
    </Dialog>
  );
}

export default FacilityDetail;
