import { useMemo } from "react";
import EnhancedTable from "./components/EnhancedTable";

const UncontrolledTable = (props) => {
  const columns = useMemo(() => props.columns, [props.columns]);
  const data = useMemo(() => props.data, [props.data]);

  return (
    <EnhancedTable
      columns={columns}
      data={data}
      // below are optional props
      defaultSort={props.defaultSort}
      tableHeader={props.tableHeader}
      stickyHeader={props.stickyHeader}
      isSelect={props.isSelect}
      selectAllPageRows={props.selectAllPageRows}
      onSelect={props.onSelect}
      toolbarChildren={props.toolbarChildren}
      language={props.language || "en"}
      onColumnDrop={props.onColumnDrop}
      defaultColumnOrder={props.defaultColumnOrder}
      dragEnabled={props.dragEnabled}
      totalCountLabel={props.totalCountLabel}
      assetStatusMap={props.assetStatusMap}
    />
  );
};

export default UncontrolledTable;
