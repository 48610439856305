import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputAdornment,
  Input,
  OutlinedInput,
} from "@material-ui/core";

export default function CurrencyTextBox(props) {
  const {
    error,
    fullWidth = true,
    label,
    onChange,
    size = "small",
    value,
    variant = "outlined",
  } = props;
  const useStyles = makeStyles((theme) => ({
    label: {
      color: "#6D6E70",
      fontFamily: "Lato",
      fontWeight: "bold",
    },
  }));
  const classes = useStyles();

  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      {variant === "outlined" ? (
        <OutlinedInput
          currencySymbol=""
          error={error}
          fullWidth={fullWidth}
          helperText={`${error ? "Required Field" : ""}`}
          onChange={(event) => {
            onChange(event);
          }}
          outputFormat="string"
          size={size}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          type="number"
          value={value}
        />
      ) : (
        <Input
          currencySymbol=""
          error={error}
          fullWidth={fullWidth}
          helperText={`${error ? "Required Field" : ""}`}
          onChange={(event) => {
            onChange(event);
          }}
          outputFormat="string"
          size={size}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          style={{ marginTop:'13px' }}
          type="number"
          value={value}
        />
      )}
    </>
  );
}
