import escapeCharacters from "../../utils/escapedCharacters";
import moment from "moment-timezone";
const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const showLastEvents = async (props, state) => {
  const { token, organizationId, classifications } = props;

  const { filters = {}, isExport = false, page = 0 } = state;
  let {
    assetCategories,
    assetIds,
    assetType,
    binLocation,
    endDate,
    lastEvents,
    limit,
    locals,
    locations,
    pca,
    sorted,
    startDate,
    users,
    zones,
  } = filters;
  let parsedSorted =
    sorted && sorted.length
      ? (() => {
          let obj = {
            id: "",
            order: sorted[0].desc ? "DESC" : "ASC",
          };
          switch (sorted[0].id) {
            case "timeOfLog":
              obj.id = "time_of_log";
              break;
            case "category":
              obj.id = "category";
              break;
            case "tag":
              obj.id = "tag";
              break;
            case "lastEvent":
              obj.id = "last_event";
              break;
            case "state":
              obj.id = "state";
              break;
            case "facility":
              obj.id = "facility";
              break;
            case "latLong":
              if (sorted[0].desc) {
                obj.id = "latitude DESC, longitude ASC";
              } else {
                obj.id = "latitude ASC, longitude DESC";
              }
              obj.latLong = true;
              break;
            case "user":
              obj.id = "last_name";
              break;
            default:
              obj.id = "time_of_log";
              break;
          }
          return obj;
        })()
      : "";

  let sortedObject =
    parsedSorted && parsedSorted.id
      ? (() => {
          if (parsedSorted.latLong) {
            return `${parsedSorted.id}, `;
          } else {
            // return `${parsedSorted.id} ${parsedSorted.order}, `;
            return {
              [parsedSorted.id]: parsedSorted.order,
            };
          }
        })()
      : "";

  // escape special characters from string inputs
  let escapedAssetType = assetType ? escapeCharacters(assetType) : null;

  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [

          // Give us assets from this organization
          {
            term: {
              current_owner_id: organizationId,
            },
          },

          // No devices
          {
            bool: {
              must_not: [
                {
                  nested: {
                    path: "device",
                    query: {
                      exists: {
                        field: "device.status",
                      },
                    },
                  },
                },
              ],
            },
          },

          // No assets that are from batches
          {
            bool: {
              must_not: [
                {
                  exists: {
                    field: "batch_id",
                  },
                },
              ],
            },
          },

          // Inventory assets only
          {
            term: {
              asset_mode:"Inventory"
            }
          },
        ],
        should: [],
      },
    },
    limit: isExport ? 1000 : limit || 25,
    range: {},
    page,
    sort: [
      {
        tag: "desc",
      },
      sortedObject,
    ],
  };

  // TODO - Elastic - You need to go back to bitBucket and find out where some of those unused variables were previously being used

  // Asset Tag
  if (assetIds && assetIds.length) {
    const assetIdArray = assetIds.map((assetId) => {
      return assetId.value;
    });

    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: {
        asset_id: assetIdArray,
      },
    });
  }

  // Asset Type - TODO - Im adding this is now to mimic the old SOLR Query. But Im starting to think state.assetType never gets hit.
  if (escapedAssetType) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: {
        asset_type: escapedAssetType
      }
    })
  }

  // Bin Location
  if (binLocation?.length) {
    const binLocationsArray = binLocation?.map((bin) => {
      return {
        term: {
          "zone.bin_location.keyword": bin.value,
        },
      };
    });

    elasticQuery.elasticSearchQuery.bool.must.push({
      bool: {
        should: [
          {
            nested: {
              path: "zone",
              query: {
                bool: {
                  should: binLocationsArray,
                },
              },
            },
          },
        ],
      },
    });
  }

  // Categories
  if (assetCategories?.length > 0) {
    // We build up the array
    const categoryArray = assetCategories.map((cat) => {
      return cat.value;
    });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { category: categoryArray },
    });
  }

  // Classifications
  const classificationFilters = Object.keys(filters || {})
    .filter(
      (filterKey) => classifications[filterKey] && filters[filterKey] !== null
    )
    .map((filterKey) => filters[filterKey]);

  if (classificationFilters?.length) {
    classificationFilters?.map((classArray) => {
      // We initially declare the object we are going to push into the query
      let queryObject = {
        nested: {
          path: "classification_data",
          query: {
            bool: {
              must: [],
              should: [],
              minimum_should_match: 1,
            },
          },
        },
      };

      // We build up the queryObject here
      classArray?.map((childClass) => {
        // Make sure the parentId is present
        if (queryObject.nested.query.bool.must.length === 0) {
          queryObject.nested.query.bool.must.push({
            match: {
              "classification_data.key.keyword": childClass.parentLabel,
            },
          });
        }

        // Push the children
        queryObject.nested.query.bool.should.push({
          match: {
            "classification_data.value.keyword": childClass.label,
          },
        });
        return null;
      });

      elasticQuery.elasticSearchQuery.bool.must.push(queryObject);

      return null;
    });
  }

  // Event
  if (lastEvents && lastEvents?.length) {
    // We Build up the array
    const lastEventArray = lastEvents.map((event) => {
      return event.value;
    });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { last_event: lastEventArray },
    });
  }

  // Facility
  if (locations && locations.length) {
    // We build the array
    let locationsSet = locations.map((e) => {
      return e.value?.facilityId || e;
    });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { facility_id: locationsSet },
    });
  }

  // PCA
  if (pca) {
    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { flagged: true },
    });
  }

  // State
  if (locals && locals.length) {
    let localsArray = locals.map((e) => {
      return e.value;
    });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { state: localsArray },
    });
  }

  // Users
  if (users?.length) {
    // We Build the Array
    const usersArray = users?.length
      ? users.map((user) => {
          return user.value;
        })
      : null;

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { app_user_id: usersArray },
    });
  }

  // Zones
  if (zones && zones.length) {
    let zonesArray = zones.map((zone) => {
      const { internalZoneType = null, value = "" } = zone;

      // if {zoneId}.internalZoneId === 'processing' then we know the zone is a
      // pZone. If that value is === 'target' then we know the zone is a tZone.

      if (internalZoneType === "processing") {
        return { "zone.p_zone_id": value };
      } else if (internalZoneType === "target") {
        return { "zone.t_zone_id": value };
      } else {
        return { "zone.zone_id": value };
      }
    });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      nested: {
        path: "zone",
        query: {
          bool: {
            must: [
              {
                terms: {
                  "zone.zone_id": zonesArray,
                },
              },
            ],
          },
        },
      },
    });
  }

  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  if (moment(startDate).isValid() || moment(endDate).isValid()) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      range: {
        time_of_log: {
          gte: moment(startDate).isValid()
            ? moment(startDate).startOf("day").valueOf()
            : null,
          lte: moment(endDate).isValid()
            ? moment(endDate).endOf("day").valueOf()
            : "now",
        },
      }
    })
  }

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "auth-token": token,
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(elasticQuery),
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });
  return results;
};

export const saveFilterSettings = async (props, filters) => {
  const { token, appUserId } = props;
  const payload = {
    propertiesMap: {
      inventoryStatusTableSettings: filters,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${appUserId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const retrieveParentIds = async (props) => {
  const { token } = props;
  const results = await fetch(`${apiUrl}assets/assetParents`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((results) => results.json())
    .then((results) => {
      return results;
    });

  return results;
};

export const inventory = {
  retrieveParentIds,
  saveFilterSettings,
  showLastEvents,
};