import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { easeCubic } from "d3-ease";
import { utc_to_local_12_hour_clock } from "../../../utils/date_time_utils";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import Loading from "../../../components/Loading/Loading"
import ReactMapGL, {
  FullscreenControl,
  LinearInterpolator,
  Marker,
  NavigationControl,
  Popup,
  ScaleControl,
} from "react-map-gl";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// Component is super heavy, which is why we are using lazy/Suspense
const DynamicIconRender = lazy(
  () => import("../../IconPicker/DynamicIconRender")
);

export default function Map(props) {
  const mapToken =
    "pk.eyJ1IjoiZGFuaWVsZG91YW5na2Vzb25lIiwiYSI6ImNqeGYxMTF5YTA5NjQzeW1mMmM4eXIxdWIifQ.jqF1at0nWobKgC3t1OY4kw";

  const {
    eventTypesMap,
    facilities,
    defaultZoomLevel = null,
    timeZone,
  } = props;
  const histories = useMemo(
    () => props.state?.histories?.assetHistories || [],
    [props.state?.histories?.assetHistories]
  );

  const [hotSpot, setHotSpot] = useState(null);

  const [viewport, setViewport] = useState({
    zoom: defaultZoomLevel || 4,
  });

  const [satView, setSatView] = useState(false);

  useEffect(() => {
    const validateLat =
      /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
    const validateLong =
      /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;

    // If the first item in the array does not have a lat/lon but does have a facility id
    // Otherwise check to see if the first item has valid lat/lon values
    if (
      histories &&
      !histories[0]?.latitude &&
      !histories[0]?.longitude &&
      histories[0]?.facilityId
    ) {
      const currentFacility = facilities[histories[0]?.facilityId] || {};
      const { location = {} } = currentFacility;
      const { latitude: currentLat, longitude: currentLon } = location;

      // Verify the facility has a valid Lat/Lon
      if (validateLat.test(currentLat) && validateLong.test(currentLon)) {
        setViewport({
          zoom: defaultZoomLevel || 4,
          latitude: validateLat.test(currentLat) ? currentLat : 0,
          longitude: validateLong.test(currentLon) ? currentLon : 0,
          bearing: 0,
          pitch: 0,
          transitionDuration: 1800,
          transitionInterpolator: new LinearInterpolator(),
          transitionEasing: easeCubic,
        });
      }
    } else if (
      histories &&
      validateLat.test(histories[0]?.latitude) &&
      validateLong.test(histories[0]?.longitude)
    ) {
      setViewport({
        zoom: defaultZoomLevel || 4,
        latitude: validateLat.test(histories[0].latitude)
          ? histories[0].latitude
          : 0,
        longitude: validateLong.test(histories[0].longitude)
          ? histories[0].longitude
          : 0,
        bearing: 0,
        pitch: 0,
        transitionDuration: 1800,
        transitionInterpolator: new LinearInterpolator(),
        transitionEasing: easeCubic,
      });
    }
  }, [defaultZoomLevel, facilities, histories]);

  const _closePopup = () => {
    setHotSpot(null);
  };
  const validateLat =
    /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
  const validateLong =
    /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;

  const renderMarkers = histories
    .map((event, idx) => {
      // If the current event does not have a lat/lon, we check to see if a facilityId exist.
      // If so, we use the facilityId.location.lat and lon. Otherwise we use the event.lat and lon
      if (
        event &&
        (event.latitude !== 0 || event.longitude !== 0) &&
        validateLat.test(event.latitude) &&
        validateLong.test(event.longitude)
      ) {
        return event;
      } else if (
        event &&
        (event.latitude !== 0 || event.longitude !== 0) &&
        event.facilityId
      ) {
        const currentFacility = facilities[event.facilityId] || {};
        const { location = {} } = currentFacility;
        const { latitude, longitude } = location;
        return { ...event, latitude, longitude };
      } else {
        return null;
      }
    })
    .filter((event) => event !== null)
    .sort((a, b) => a.timeOfLog - b.timeOfLog)
    .map((event, idx) => {
      return (
        <Marker
          key={`${event.assetId} - ${idx} - ${event.timeOfLog}`}
          latitude={event.latitude}
          longitude={event.longitude}
          offsetLeft={-20}
          offsetTop={-10}
        >
          {eventTypesMap[event.event] && eventTypesMap[event.event].icon ? (
            <Suspense fallback={<Loading />}>
              <DynamicIconRender
                iconName={eventTypesMap[event.event].icon || "LocationOn"}
                style={{
                  cursor: "pointer",
                  fill:
                    eventTypesMap[event.event] &&
                    eventTypesMap[event.event].color
                      ? eventTypesMap[event.event].color
                      : satView
                      ? "#FFC854"
                      : "#32355C",
                }}
                onMouseEnter={() => setHotSpot(event)}
                onClick={() => setHotSpot(event)}
              />
            </Suspense>
          ) : (
            <i
              className="fas fa-map-marker"
              // marker colors are rendered by checking the eventTypes object for the event action color
              style={{
                cursor: "pointer",
                color:
                  eventTypesMap[event.event] && eventTypesMap[event.event].color
                    ? eventTypesMap[event.event].color
                    : satView
                    ? "#FFC854"
                    : "#32355C",
              }}
              onMouseEnter={() => setHotSpot(event)}
              onClick={() => setHotSpot(event)}
            ></i>
          )}
        </Marker>
      );
    });

  const renderPopups =
    hotSpot !== null ? (
      <Popup
        latitude={parseFloat(hotSpot.latitude)}
        longitude={parseFloat(hotSpot.longitude)}
        onClose={_closePopup}
        className={"popup"}
      >
        {hotSpot.event ? (
          <h5 style={{ marginTop: "5px" }}>{hotSpot.event}</h5>
        ) : (
          ""
        )}{" "}
        {hotSpot.timeOfLog ? (
          <span>
            Date: {utc_to_local_12_hour_clock(hotSpot.timeOfLog, timeZone)}
          </span>
        ) : (
          ""
        )}
        <br />
        {hotSpot.facilityId &&
        facilities[hotSpot.facilityId] &&
        hotSpot.latitude !== 0 &&
        hotSpot.longitude !== 0 ? (
          <span>Facility: {facilities[hotSpot.facilityId].name}</span>
        ) : (
          ""
        )}{" "}
        {hotSpot.propertiesMap && hotSpot.propertiesMap.note ? (
          <span>Note: {hotSpot.propertiesMap.note}</span>
        ) : (
          ""
        )}
      </Popup>
    ) : null;

  const fullscreenControlStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "10px",
  };

  const navStyle = {
    position: "absolute",
    top: 36,
    left: 0,
    padding: "10px",
  };

  const scaleControlStyle = {
    position: "absolute",
    bottom: 36,
    left: 0,
    padding: "10px",
  };

  const satViewStyle = {
    position: "absolute",
    top: 10,
    right: 10,
    padding: "10px",
  };

  return (
    <ReactMapGL
      {...viewport}
      attributionControl={false}
      mapboxApiAccessToken={mapToken}
      width="100%"
      height="100%"
      mapStyle={
        satView
          ? "mapbox://styles/mapbox/satellite-v9"
          : "mapbox://styles/mapbox/light-v10"
      }
      style={{
        border: "rgba(50, 53, 93, 0.514) solid 2px",
        borderRadius: "4px",
      }}
      onViewportChange={(viewport) => setViewport(viewport)}
    >
      {renderMarkers}
      {renderPopups}
      <div style={fullscreenControlStyle}>
        <FullscreenControl />
      </div>
      <div style={navStyle}>
        <NavigationControl />
      </div>
      <div style={scaleControlStyle}>
        <ScaleControl />
      </div>
      <div style={satViewStyle}>
        <i
          className={
            satView ? "fa fa-road darkmode-icon" : "fa fa-globe darkmode-icon"
          }
          style={satView ? { color: "#FFC854" } : { color: "#32355B" }}
          onClick={() => {
            setSatView(!satView);
          }}
        >
          {}
        </i>
      </div>
    </ReactMapGL>
  );
}
