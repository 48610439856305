import { Bar } from "react-chartjs-2";
import moment from "moment-timezone";
import { startCase } from "lodash";

export default function EventActivityGraph(props) {
  const {
    startDate,
    endDate,
    intervals,
    dateSelection,
    intervalLabels,
    selected,
  } = props;

  const dataSets =
    intervals?.filter((interval) => selected[interval.label]) || [];

  return (
    <Bar
      style={{ height: "100%" }}
      data={{
        datasets: dataSets || [],
        labels:
          intervalLabels && intervalLabels.length > 0
            ? intervalLabels
            : ["No event(s) selected"],
      }}
      type="bar"
      options={{
        onHover: function (event) {
          event.native.target.style.cursor = "default";
        },
        maintainAspectRatio: false,
        responsive: true,

        plugins: {
          title: {
            display: true,
            text: `${startCase(dateSelection)} Event Activity ${moment(
              startDate
            ).format("MM/DD/YYYY")} - ${moment(endDate).format("MM/DD/YYYY")}`,
          },
          legend: {
            onHover: function (event, legendItem) {
              // There is only a legendItem when your mouse is positioned over one
              if (legendItem) {
                event.native.target.style.cursor = "pointer";
              }
            },
          },
        },

        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              precision: 0,
            },
            stacked: true,
          },
          x: {
            stacked: true,
          },
        },
      }}
    />
  );
}
