import circle from "@turf/circle";

export const validateLat = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
export const validateLong = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;

// export { radiusCircle };
export const radiusCircle = (longitude, latitude, radius, properties) => {
  var center = [longitude, latitude];
  var options = {
    steps: 64,
    units: "kilometers",
    properties: properties || {},
  };
  // tslint:disable-next-line
  return circle(center, radius, options);
};
