import { InputLabel } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "100%",
  },
  label: {
    color: "#6D6E70",
    fontFamily: "Lato Semibold",
    fontWeight: "bold",
  },
}));

export default function DatePicker(props) {
  const {
    allowKeyboardControl = false,
    disableToolbar = true,
    disabled = false,
    format = "MM/DD/yyyy",
    id = "date-picker-inline-timeCreatedUser",
    inputVariant = "outlined",
    label = null,
    onChange,
    size,
    value,
  } = props;
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <KeyboardDatePicker
        autoOk
        allowKeyboardControl={allowKeyboardControl}
        className={classes.inputField}
        disableToolbar={disableToolbar}
        disabled={disabled}
        format={format}
        id={id}
        inputVariant={inputVariant}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        onChange={(event) => {
          onChange(event);
        }}
        size={size}
        value={value}
        variant="inline"
      />
    </MuiPickersUtilsProvider>
  );
}
