import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cancel: {
    backgroundColor: "#FFFFFF !important",
    color: "#6D6E70 !important",
    "&:hover": {
      backgroundColor: "#A6A8AB !important",
      color: "#FFFFFF !important",
    },
  },
  delete: {
    backgroundColor: "#B71D1A !important",
    color: "#FFFFFF !important",
    "&:hover": {
      backgroundColor: "#EB4F35 !important",
      color: "#FFFFFF !important",
    },
  },
  disabled: {
    backgroundColor: "#6D6E70 !important",
    color: "#E6E7E8 !important",
    "&:hover": {
      backgroundColor: "#6D6E70 !important",
      color: "#E6E7E8 !important",
    },
  },
}));

export default function MaterialUiButton(props) {
  const {
    classes,
    className = {},
    color = "primary",
    disabled = false,
    disableElevation,
    disableFocusRipple,
    disableRipple,
    fullWidth = false,
    label = "Submit",
    onClick,
    onMouseDown = ()=>{},
    size,
    type,
    variant = "contained",
  } = props;
  const cssClasses = useStyles();

  return (
    <Button
      className={`${color === "cancel" ? cssClasses.cancel : ""} ${
        color === "delete" ? cssClasses.delete : ""
      } ${disabled ? cssClasses.disabled : ""} ${className ? className : ``} `}
      classes={classes}
      color={
        color === "cancel" ? "inherit" : color === "delete" ? "inherit" : color
      }
      disabled={disabled || color === "disabled" ? true : false}
      disableElevation={disableElevation}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      fullWidth={fullWidth}
      onClick={onClick}
      onMouseDown={onMouseDown}
      size={size}
      type={type}
      variant={variant}
    >
      {label}
    </Button>
  );
}
