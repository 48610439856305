import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  deviceItem: {
    marginBottom: "2rem",
    textAlign: "left",
  },
  title: {
    marginBottom: ".5rem",
    textAlign: "left",
  },
}));

export default function Device(props) {
  const { propertiesMap = {} } = props.device;
  const classes = useStyles();
  const item = (element) => {
    return (
      <Grid className={classes.deviceItem} key={element} item xs={4}>
        <Typography variant="h6">{element}</Typography>
        <Typography variant="subtitle2">{propertiesMap[element]}</Typography>
      </Grid>
    );
  };
  return (
    <Grid container>
      <Grid className={classes.title} item xs={12}>
        <h4 style={{ textAlign: "left", marginBottom: ".5rem" }}>
          Device Information
        </h4>
      </Grid>
      {Object.keys(propertiesMap).map((element) => {
        return item(element);
      })}
    </Grid>
  );
}
