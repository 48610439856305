import { PureComponent } from "react";
import area from "@turf/area";

export default class AreaText extends PureComponent {
  render() {
    const polygon = this.props.polygon;
    const polygonArea = polygon && area(polygon);
    return (
      <>
        {polygon && (
          <div>
            Polygon Area: {(Math.round(polygonArea) * 100) / 100} sq. meters
          </div>
        )}
      </>
    );
  }
}
