import { isEmpty } from "lodash";
export const postBatch = async (props, newBatch) => {
  const {
    organizationId = "",
    pcaId = "",
    facilities = {},
    apiUrl = "",
    token = "",
  } = props;

  let topChildren = {};
  const {
    tag = "",
    name = null,
    description = null,
    originLocation = "",
    destinationLocation = null,
    topologiesList = [],
    isPCA = false,
    isILDE = false,
  } = newBatch;
  
  const payload = {
    description,
    name,
    organizationId,
    originLocation: facilities[originLocation].location,
    pcaId: isPCA ? pcaId : null,
    propertiesMap: {},
    tag,
    // Reduce topologies into tree; iterate and memoize current topologyMap in topChildren object and assign topChildren to child node, returns accumulated topologies as child nodes in a tree. Wrapped in array since that's what's expected from topologies property (?)
    topologiesList: isILDE
      ? [{}]
      : [
          (topologiesList).reverse().reduce((acc, curr) => {
            if (!isEmpty(topChildren)) {
              curr.child = topChildren;
            }
            topChildren = curr;
            return {
              ...acc,
              ...curr,
            };
          }, {}),
        ],
  };

  // Only add destinationLocation property if there is a destinationLocation
  if(destinationLocation) {
    payload.destinationLocation = facilities[destinationLocation].location
  }

  const results = await fetch(`${apiUrl}batches`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: err,
      };
    });
  return results;
};
