import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./styles.css";
export function Dropzone(props) {
  const [fileNames, setFileNames] = useState([]);

  const handleDrop = (acceptedFiles) =>
    setFileNames(acceptedFiles.map((file) => file.name));
  const callback = props.callback ? props.callback : "";
  const onDrop = useCallback(
    (acceptedFiles) => {
      // run callback function to do something with the files, e.g. validate and format files for POSTing to server. If callback is accepted, update UI with handleDrop
      if (callback) {
        callback(acceptedFiles, (accepted) => {
          if (accepted) {
            handleDrop(acceptedFiles);
          }
        });
      } else {
        // just display accepted file in list.
        handleDrop(acceptedFiles);
      }
    },
    [callback]
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: props.accept ? props.accept : "",
  });

  // additional CSS depends on dragging status
  const additionalClass = isDragReject
    ? "dropzone-reject"
    : isDragAccept
    ? "dropzone-accept"
    : "";

  return (
    <>
      <div
        {...getRootProps({ className: `dropzone ${additionalClass}` })}
        style={{ cursor: "pointer" }}
      >
        <input {...getInputProps()} />
        <span>{isDragActive ? "📂" : "📁"}</span>
        {props.acceptText ? <em>{props.acceptText}</em> : ""}
      </div>
      {fileNames && fileNames.length ? (
        <>
          <strong>File</strong>
          {fileNames.map((fileName) => (
            <p key={fileName} style={{ textAlign: "center" }}>
              <i
                onClick={() => {
                  setFileNames([]);
                  props.clearFile();
                }}
                className="fa fa-times pointer"
              ></i>{" "}
              {fileName}
            </p>
          ))}
        </>
      ) : null}
    </>
  );
}
