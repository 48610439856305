import { useEffect, useRef } from "react";
import { searchBins } from "./api";
import _ from "lodash";
import AsyncReactSelectCheckboxes from "../AsyncReactSelectCheckbox";

export default function BinSelector(props) {
  const {
    apiUrl,
    labelStyles,
    onChange = () => {},
    onError = () => {},
    organizationId,
    token,
    value = null,
  } = props;
  const binLocationOptionsPageRef = useRef(0);

  useEffect(() => {
    return () => {
      // ref for incrementing start location in the query for bin location options
      // having to do this because we are not able to use the length of the options
      // returned to determine start location, as we are constructing distinct sets of
      // options from indistinct results.

      binLocationOptionsPageRef.current = 0;
    };
  });

  const asyncBinLocationLoadOptions = async (
    inputValue = "",
    loadedOptions
  ) => {
    const limit = 300;

    const results = await searchBins(
      { apiUrl, token, organizationId },
      inputValue,
      limit,
      binLocationOptionsPageRef.current
    ).then((results) => {
      if (results.error) {
        onError(results.error);
      }

      // increment page ref...
      if (binLocationOptionsPageRef.current < results.count) {
        binLocationOptionsPageRef.current += limit;
      } else {
        binLocationOptionsPageRef.current = 0;
      }

      const options =
        results.assetHistories && results.assetHistories.length
          ? [
              ...new Set(
                results.assetHistories
                  .filter((history) => history.binLocation)
                  .map((history) => history.binLocation)
              ),
            ].map((binLocation) => {
              return {
                label: binLocation,
                value: binLocation,
              };
            })
          : [];

      // create distinct union of options and loaded options, using lodash _.unionWith to use deep comparison between objects in arrays.
      const union =
        loadedOptions.length > 0
          ? _.unionWith(options, loadedOptions, _.isEqual)
          : options;

      return {
        options: union,
        // I am incrementing a ref by a constant limit outside the scope of the function, which is used to determine the page start location in the data. When that number, the "start" field, is greater than the results.count, we return a false hasMore and stop searching.
        hasMore: binLocationOptionsPageRef.current < results.count,
      };
    });
    return results;
  };
  return (
    <>
      <label
        className={labelStyles ? labelStyles : ""}
        style={{ marginTop: "1vh" }}
      >
        Bin Location
      </label>
      <AsyncReactSelectCheckboxes
        loadOptions={asyncBinLocationLoadOptions}
        onChange={(e) => {
          onChange(e);
        }}
        value={value}
      />
    </>
  );
}
