import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import { date_interval_util, facetQueryEvents } from "./utils";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Graph from "./Graph/Graph";
import Menu from "./Menu/Menu";
import moment from "moment-timezone";

export default function EventActivity(props) {
  const {
    token,
    apiUrl,
    handleSaveWidgetSettings,
    organization,
    classes,
    timeZone,
  } = props;

  const dispatchGlobal = useDispatch();

  const eventActivitySettingsFromStore = useSelector(
    (state) =>
      state.user.userPropertiesMap.propertiesMap?.widgetSettings
        ?.eventActivitySettings,
    isEqual
  );

  const { eventTypesMap = {}, organizationId = "" } = organization;

  // setting up some default filters and date intervals
  const defaultDateIntervals = date_interval_util("daily", timeZone);

  const filterDefaults = {
    startDate: defaultDateIntervals.startDate,
    endDate: defaultDateIntervals.endDate,
    dateSelection: "daily",
    customColors: false,
    events: [],
  };

  const eventActivitySettings =
    eventActivitySettingsFromStore || filterDefaults;

  // make sure date interval is updated, e.g., 'daily' updates with correct time relative to user's current time, etc.
  const dateIntervals =
    eventActivitySettings.dateSelection !== "custom"
      ? date_interval_util(eventActivitySettings.dateSelection, timeZone)
      : {
          startDate: eventActivitySettings.startDate,
          endDate: eventActivitySettings.endDate,
        };

  const initFilters = useMemo(() => {
    const filters = {
      ...eventActivitySettings,
      startDate: new Date(dateIntervals.startDate),
      endDate: new Date(dateIntervals.endDate),

      timeZone: timeZone?.value || moment.tz.guess(),
    };

    return filters;
  }, [
    eventActivitySettings,
    dateIntervals.startDate,
    dateIntervals.endDate,
    timeZone,
  ]);

  const retrieveEventCounts = useCallback(
    async (filters) => {
      const counts = await facetQueryEvents(
        { apiUrl, token, organizationId },
        filters,
        eventTypesMap
      )
        .then((res) => {
          if (res.error) {
            return { error: res.error };
          }
          return res;
        })
        .catch((err) => {
          return { error: err };
        });
      return counts;
    },
    [apiUrl, token, organizationId, eventTypesMap]
  );

  const mountedRef = useRef(false);

  const [data, setData] = useState({
    eventTotals: {},
    intervals: [],
    intervalLabels: [],
  });

  const [filters, setFilters] = useState(initFilters);

  const [selected, setSelected] = useState({
    selected:
      initFilters.events?.reduce((x, y) => {
        return { ...x, [y.label]: true };
      }, {}) || {},
  });

  // initFilter
  useEffect(() => {
    if (initFilters.events?.length > 0 && mountedRef.current !== true) {
      retrieveEventCounts(initFilters).then((events) => {
        if (events.error) {
          dispatchGlobal({
            type: "alert/showAlert",
            payload: { error: events.error },
          });
        } else {
          mountedRef.current = true;
          setData(events);
        }
      });
    }
  }, [initFilters, retrieveEventCounts, dispatchGlobal]);
  return (
    <Grid container spacing={3} id={"eventActivityWidget"}>
      <Grid item xs={4}>
        <Card>
          <CardContent className={classes.container}>
            <Menu
              id="eventActivityMenu"
              filters={filters}
              setFilters={setFilters}
              selected={selected.selected}
              setSelected={setSelected}
              filterDefaults={filterDefaults}
              retrieveEventCounts={retrieveEventCounts}
              handleSaveWidgetSettings={handleSaveWidgetSettings}
              timeZone={timeZone}
              data={data.eventTotals || {}}
              setData={setData}
              {...props}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={8}>
        <Card>
          <CardContent className={classes.container}>
            <Graph
              id="eventActivityGraph"
              intervals={data.intervals}
              selected={selected.selected}
              startDate={filters.startDate}
              endDate={filters.endDate}
              dateSelection={filters.dateSelection}
              intervalLabels={data.intervalLabels}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
