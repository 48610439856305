import { TableCell, TableRow, withStyles } from "@material-ui/core";

const HeaderRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

const HeaderCell = withStyles((theme) => ({
  root: {
    padding: "12px",
    paddingCheckbox: {
      padding: "0px",
      backgroundColor: "red",
    },
    backgroundColor: "#6D6E70",
    color: theme.palette.common.white,
  },
  paddingCheckbox: {
    padding: "0px",
    backgroundColor: "red",
  },
}))(TableCell);

const BodyRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#FAFAFA",
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);

const BodyCell = withStyles((theme) => ({
  root: {
    padding: "12px",
  },
  sizeSmall: {
    padding: "6px",
  },
}))(TableCell);

export { HeaderRow, HeaderCell, BodyRow, BodyCell };
