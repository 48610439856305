import AsyncReactSelectCheckboxes from "../AsyncReactSelectCheckbox";
import { searchAssets } from "../../../utils/API/Assets/Assets";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

export default function AssetSelector(props) {
  const {
    label = "Asset Tag",
    // Has shortage is being used by one of the widgets
    hasShortage = false,
    isMulti = true,
    onChange = () => {},
    onError = () => {},
    showCheckboxes = true,
    showLabel = true,
    value,
  } = props;

  const { classifications } = useSelector(
    (state) => state.organization,
    isEqual
  );

  const asyncAssetIdSearchLoadOptions = async (
    inputValue = "",
    loadedOptions
  ) => {
    const results = await // searchAssets(
    searchAssets(
      {
        ...props,
        classifications,
        hasShortage,
        inputValue,
        skip: loadedOptions.length > 0 ? loadedOptions.length : 0,
      },
      { filters: { limit: 300 } }
    ).then((results) => {
      if (results.error) {
        return onError(results.error);
      }
      return {
        options:
          results.assets && results.assets.length
            ? results.assets.map((asset) => {
                return {
                  label: asset.tag,
                  value: asset.assetId,
                  batchId: asset.batchId,
                  assetType: asset.assetType,
                };
              })
            : [],
        hasMore: results.count > loadedOptions.length,
      };
    });
    return results;
  };

  return (
    <>
      {showLabel ? <label>Asset Tag</label> : null}
      <AsyncReactSelectCheckboxes
        classNamePrefix="asset-tag"
        isMulti={isMulti}
        loadOptions={asyncAssetIdSearchLoadOptions}
        onChange={(e) => {
          onChange(e);
        }}
        showCheckboxes={showCheckboxes}
        value={value}
      />
    </>
  );
}
