import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core/";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function NotificationModal(props) {
  const {
    anchorOrigin = { vertical: "top", horizontal: "center" },
    color = "success",
    confirmationModalShow,
    confirmationText,
    icon = "fas fa-check-circle",
    modalClose,
  } = props;
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={confirmationModalShow}
      onClose={modalClose}
    >
      <Alert icon={false} severity={color}>
        <i className={`${icon}`} style={{ marginRight: ".5rem" }}></i>
        {confirmationText}
      </Alert>
    </Snackbar>
  );
}
