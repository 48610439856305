export default function HoverIconButton(props) {
  const {
    downloadDocType,
    exportItemType,
    icon,
    iconDirection,
    text,
  } = props


  function handleKeyPress(e) {
    if (e.key === "Enter" || e.code === "Space") { e.target.click() }
  }

  return (
    <div tabIndex="0" role="button" className="hoverIcon" onKeyPress={handleKeyPress}>
      {iconDirection === "left" ?
        <>
          <i className={icon} style={{ marginRight: ".5rem" }}></i>
          {text || ""}
          {downloadDocType && <span className="screen-reader-only">{exportItemType ? `selected ${exportItemType}` : null} {downloadDocType} download</span>}
        </>
        :
        <>
          {text || ""}
          {downloadDocType && <span className="screen-reader-only">{exportItemType ? `selected ${exportItemType}` : null} {downloadDocType} download</span>}
          <i className={icon} style={{ marginLeft: ".5rem" }}></i>
        </>
      }
    </div>

  )
}


