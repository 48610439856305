import { CircularProgress, makeStyles } from "@material-ui/core";

export default function Loading(props) {
  const { color = "", margin, opaque } = props;
  const useStyles = makeStyles(() => ({
    loading: {
      alignItems: "center",
      color: color,
      display: "flex",
      height: "100%",
      justifyContent: "center",
      margin: margin,
      position: "absolute",
      visibility: "visible",
      width: "100%",
      zIndex: 1,
    },
    opaqueStyle: {
      backgroundColor: "rgba(255,255,255,0.7)",
      bottom: "0",
      left: "0",
      position: "fixed",
      right: "0",
      top: "0",
      width: "100%",
    },
  }));

  const classes = useStyles();
  return (
    <div
      className={`${classes.loading}  ${opaque ? classes.opaqueStyle : ""} `}
    >
      <CircularProgress />
      <span>Loading...</span>
    </div>
  );
}
