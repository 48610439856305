import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Grid, makeStyles } from "@material-ui/core";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import AssetCategoriesPieChart from "../../components/Widgets/PieChartForAssetCategories/AssetCategoriesPieChart";
import OverviewMap from "../../components/Widgets/OverviewMap/OverviewMap";
import EventActivity from "../../components/Widgets/EventActivity/EventActivity";

import { updateWidgetSettings } from "../../globalStore/slices/user/userSlice";
import { isEqual } from "lodash";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "45vh",
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

export default function Dashboard(props) {
  const classes = useStyles();

  const { timeZone, userId, token, apiUrl, organizationId } = props;

  // OK to destructure, not mutating org data in this page
  const { organization, classifications, facilities, zones } = useSelector(
    (state) => state.organization,
    isEqual
  );
  const dispatchGlobal = useDispatch();

  const handleSaveWidgetSettings = async (widget = "", settings = {}) => {
    await fetch(`${apiUrl}appUsers/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify({
        propertiesMap: {
          widgetSettings: {
            [widget]: settings,
          },
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        dispatchGlobal(updateWidgetSettings({ widget, settings }));
      })
      .catch((err) => {
        console.log(err);
        setAlertModal({
          alertModalContent: err.toString(),
          alertModalOpen: true,
          alertModalSeverity: "error",
        });
      });
  };

  const [alertModal, setAlertModal] = useState({
    alertModalContent: "",
    alertModalOpen: false,
    alertModalSeverity: "success",
  });

  const closeModal = () => {
    setAlertModal({
      ...alertModal,
      alertModalOpen: false,
    });
  };

  const { alertModalContent, alertModalOpen, alertModalSeverity } = alertModal;

  return (
    <Grid container spacing={3}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertModalOpen}
        onClose={closeModal}
      >
        <MuiAlert onClose={closeModal} severity={alertModalSeverity}>
          {alertModalContent}
        </MuiAlert>
      </Snackbar>
      <Grid item xs={12}>
        {/* event activity widget has card components and classes in a higher branch */}
        <EventActivity
          handleSaveWidgetSettings={handleSaveWidgetSettings}
          setAlertModal={setAlertModal}
          classes={classes}
          organization={organization}
          apiUrl={apiUrl}
          token={token}
          timeZone={timeZone}
          organizationId={organizationId}
        />
      </Grid>

      <Grid item xs={12} sm={8}>
        <Card>
          <CardContent className={classes.container}>
            <OverviewMap
              handleSaveWidgetSettings={handleSaveWidgetSettings}
              setAlertModal={setAlertModal}
              organization={organization}
              classifications={classifications}
              apiUrl={apiUrl}
              token={token}
              timeZone={timeZone}
              zones={zones}
              organizationId={organizationId}
              facilities={facilities}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card>
          <CardContent className={classes.container}>
            <AssetCategoriesPieChart apiUrl={apiUrl} token={token} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
