import { useState } from "react";
import AssetContent from "./AssetContent";
import GenerateCQRContent from "./GenerateCQRContent";
import ModalDialog from "../../../Modals/ModalDialog/ModalDialog";

export default function AddAssetModal(props) {
  const { handleAlertModal, handleClose, modalOpen, onSubmit } = props;
  const [generateCQR, setGenerateCQR] = useState(false);
  const [newAsset, setNewAsset] = useState({});

  function closeModal() {
    handleClose();
    setTimeout(() => {
      setGenerateCQR(false);
    }, 300);
  }

  return (
    <ModalDialog
      content={
        generateCQR ? (
          <GenerateCQRContent
            asset={newAsset}
            closeModal={closeModal}
            setGenerateCQR={setGenerateCQR}
          />
        ) : (
          <AssetContent
            {...props}
            closeModal={closeModal}
            handleAlertModal={handleAlertModal}
            onSubmit={onSubmit}
            parentProps={props}
            setGenerateCQR={setGenerateCQR}
            setNewAsset={setNewAsset}
          />
        )
      }
      handleClose={closeModal}
      maxWidth="md"
      open={modalOpen}
      title={generateCQR ? "Generate CQR" : "Add Asset"}
    />
  );
}
