import DefaultOrganizationRolesMap from "./DefaultOrganizationRolesMap";

export function FlattenUserRoles(appUserRoles, userType) {
  // e.g., consoleRoles = org.allRolesMap, appUserRoles = ["Admin", "someMobileRole"], userType = "Asset/Product"
  // filtering the appUser roles because I don't trust depending on the index of the array type

  const consoleRoles = DefaultOrganizationRolesMap.console;
  const usersConsoleRole = appUserRoles.filter((role) => consoleRoles[role])[0];
  const availableActions = { ...consoleRoles[usersConsoleRole]?.actions } || {};

  if (usersConsoleRole === "No Console Access") {
    return;
  }
  // This will append and remove the appropiate actions based on the userType
  switch (userType) {
    case "Asset":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      ["View Products", "View Batches"].forEach((item) => {
        delete availableActions[item];
      });
      break;
    case "Asset/Product":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      break;
    case "Asset-Operations":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      ["View Products", "View Batches"].forEach((item) => {
        delete availableActions[item];
      });

      break;
    case "Product":
      ["View Product Home"].forEach((item) => {
        availableActions[item] = {};
      });
      ["View Devices"].forEach((item) => {
        delete availableActions[item];
      });
      break;
    case "Inventory":
      ["View Inventory", "View Inventory Settings"].forEach((item) => {
        availableActions[item] = {};
      });
      ["View Products", "View Batches"].forEach((item) => {
        delete availableActions[item];
      });
      break;
    case "Inventory/Product":
      ["View Inventory", "View Product", "View Batches", "View Inventory Settings"].forEach((item) => {
        availableActions[item] = {};
      });
      break;
    case "Warehouse-Operations":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      ["View Products", "View Batches"].forEach((item) => {
        delete availableActions[item];
      });

      break;
    case "SuperAdmin":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      break;
    case "No Console Access":
      Object.keys(availableActions).forEach(
        (key) => delete availableActions[key]
      );
      break;
    default:
      break;
  }
  let viewPermissions = {};
  // resolving roles in main init function using promises so we can get everything resolved and setState only once when the component mounts
  Object.keys(availableActions).forEach((action) => {
    switch (action) {
      case "View Asset History":
        viewPermissions.showAssetHistory = true;
        break;
      case "View Batches":
        viewPermissions.showBatches = true;
        break;
      case "View Devices":
        viewPermissions.showDevices = true;
        break;
      case "View Facilities":
        viewPermissions.showFacilities = true;
        break;
      case "View Inventory":
        viewPermissions.showInventory = true;
        break;
      case "View Organizations":
        viewPermissions.showOrganizations = true;
        break;
      case "View Products":
        viewPermissions.showProducts = true;
        break;
      case "View Product Home":
        viewPermissions.showProductHome = true;
        break;
      case "View Settings":
        viewPermissions.showSettings = true;
        break;
      case "View Users":
        viewPermissions.showUsers = true;
        break;
      default:
        break;
    }
  });

  return { availableActions, viewPermissions, usersConsoleRole };
}

export const formatClassifications = (res) => {
  let classifications = {};
  const { classifications: classificationsResponse = [] } = res;

  classificationsResponse.forEach((classification) => {
    const { formOptions = [], name = "" } = classification;
    let formOptionsHashed = {};

    // Here we create a hashmap of the formOptions. This is for the Settings page.
    // We might need to refactor this page, which is why we are leaving this logic here.
    formOptions.forEach((option) => (formOptionsHashed[option.label] = option));

    classifications[name] = {
      ...classification,
      formOptionsHashed,
    };
  });
  return classifications;
};

// gets our exported thunks into the right shape for the extraReducers in our slices, see thunks and extraReducers
// commenting out the API status for now
export const thunkSpreader = (thunks) =>
  Object.keys(thunks)
    .map((category) => {
      return Object.keys(thunks[category])
        .map((thunkKey) => {
          return {
            [thunks[category][thunkKey].pending]: (state, action) => {
              // state[category].status = "loading";
            },
            [thunks[category][thunkKey].fulfilled]: (state, action) => {
              return {
                ...state,
                ...action.payload,
              };
            },
            [thunks[category][thunkKey].rejected]: (state = {}, action) => {
              // error handling here
              // state.error.show = true;
              // state.error.text = action.payload.error;
            },
          };
        })
        .reduce((x, y) => {
          return {
            ...x,
            ...y,
          };
        }, {});
    })
    .reduce((x, y) => {
      return {
        ...x,
        ...y,
      };
    }, {});
