import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  button: {
    background: "none",
    border: "none",
    marginRight: "10px",
  },
});

export default function ActionButtons(props) {
  const { content } = props;
  const classes = useStyles();
  const iconContent = content.map((element, index) => {
    const { action, disabled = false, icon, title } = element;

    return (
      <button
        className={classes.button}
        disabled={disabled}
        key={index}
        onClick={action}
        type="button"
      >
        <i
          className={icon}
          style={disabled ? { pointerEvents: "none" } : {}}
          title={title}
        />
      </button>
    );
  });
  return (
    <Box alignItems="center" display="flex">
      {iconContent}
    </Box>
  );
}
