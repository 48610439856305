import { useState, useEffect } from "react";
import _ from "lodash";
import AssetSelector from "../../Selects/AssetSelector/AssetSelector";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Select from "react-select";
import { naturalSort } from "../../../utils/naturalSort";
import RadioList from "../../Forms/FieldTypes/RadioList";
import SimpleSelect from "../../Forms/FieldTypes/Select";

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: "#E6E7E8",
  },
}));
function NotificationProfile(props) {
  const classes = useStyles();
  const [newItemshow, setNewItemShow] = useState(false);
  const {
    actions,
    batches = [],
    batchHash,
    category = "",
    events = [],
    eventTrigerIdsToRemove,
    index,
    label = "",
    newAction = "",
    organization,
    productHash,
    products = [],
    setEventTrigerIdsToRemove,
    setModalOpen,
    setSelectedItemIndex,
    setTrackedEvents,
    trackedEvents = [],
    type,
  } = props;
  const { assetCategoriesList = [] } = organization;

  useEffect(() => {
    if (events.length === 0) {
      setNewItemShow(true);
    }
  }, [category, events.length]);

  const assetCategoryOptions = () => {
    let categoryValues = [];
    assetCategoriesList.forEach((category) => {
      categoryValues.push({ label: category.label, value: category.id });
    });
    return categoryValues;
  };

  // Renders out the available actions
  const organizationActions = () => {
    let actionsArray = Object.keys(actions);
    let actionOptions = [];
    actionsArray.push("Quantity Minimum Exceeded");
    actionsArray.push("Quantity Maximum Exceeded");

    actionsArray
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .forEach((element) => {
        actionOptions.push({ value: element, label: element });
      });

    actionOptions.unshift({ value: "", label: "Select", disabled: true });

    return actionOptions;
  };

  // Grabs all the batch names
  const batchNames = (batches) => {
    const batchValues = [];
    batches
      .sort((a, b) => {
        const aValue = a.tag ? a.tag : a.name;
        const bValue = b.tag ? b.tag : b.name;
        if (aValue && bValue) {
          return aValue.localeCompare(bValue);
        }
        return null;
      })
      .forEach((batch) => {
        batchValues.push({
          value: batch.tag ? batch.tag : batch.name,
          label: batch.tag ? batch.tag : batch.name,
        });
      });

    return batchValues;
  };

  // Grabs all the product names
  const productNames = (products) => {
    const productValues = [];
    products
      .sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return null;
      })
      .forEach((product) => {
        productValues.push({ label: product.name, value: product.name });
      });

    return productValues;
  };

  const assetSelector =
    type === "asset category" ? (
      <Select
        classNamePrefix="bg-white"
        // defaultInputValue={label}
        onChange={(event) => {
          let newArray = [...trackedEvents];
          if (event !== null) {
            const { label, value } = event;
            newArray[index].label = label;
            newArray[index].id = value;
            setTrackedEvents(newArray);
          }
        }}
        options={assetCategoryOptions().sort((a, b) =>
          naturalSort(a.label, b.label)
        )}
        value={{ value: label, label }}
      />
    ) : (
      <AssetSelector
        {...props}
        isMulti={false}
        onChange={(event) => {
          let newArray = [...trackedEvents];
          if (event !== null) {
            const { label, value } = event;
            newArray[index].label = label;
            newArray[index].id = value;
            setTrackedEvents(newArray);
          } else {
            newArray[index].label = "";
            newArray[index].id = "";
            setTrackedEvents(newArray);
          }
        }}
        showLabel={false}
        value={{ value: label, label }}
      />
    );

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <Typography>
          {label.length === 0 ? "New Notification" : label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={8}>
          {/* Label */}
          <Grid item xs={12}>
            <Typography style={{ width: "100%" }}>
              {`Select ${_.startCase(type)}`}
            </Typography>
          </Grid>

          {/* Item Selector */}
          <Grid item xs={12}>
            {type === "asset tag" || type === "asset category" ? (
              assetSelector
            ) : (
              <Select
                defaultInputValue={label}
                onChange={(event) => {
                  const newArray = [...trackedEvents];
                  const { value } = event;
                  newArray[index].label = value;
                  newArray[index].id =
                    type === "batch"
                      ? batchHash[value].batchId
                      : productHash[value].productId;
                  setTrackedEvents(newArray);
                }}
                options={
                  type === "batch"
                    ? batchNames(Object.values(batches)).sort((a, b) =>
                        naturalSort(a.label, b.label)
                      )
                    : productNames(Object.values(products)).sort((a, b) =>
                        naturalSort(a.label, b.label)
                      )
                }
                value={{ value: label, label }}
              />
            )}
          </Grid>

          {/* Radios */}
          <Grid item xs={12}>
            <Box mt={1} mb={2} ml={2}>
              {type === "asset tag" || type === "asset category" ? (
                <RadioList
                  row={true}
                  onChange={(e) => {
                    const newArray = [...trackedEvents];
                    newArray[index].type = e.target.value;
                    newArray[index].label = "";
                    setTrackedEvents(newArray);
                  }}
                  options={[
                    { label: "Asset Category", value: "asset category" },
                    { label: "Asset Tag", value: "asset tag" },
                  ]}
                  value={type}
                />
              ) : (
                <RadioList
                  row={true}
                  onChange={(e) => {
                    const newArray = [...trackedEvents];
                    newArray[index].type = e.target.value;
                    newArray[index].label = "";
                    setTrackedEvents(newArray);
                  }}
                  options={[
                    { label: "Batches", value: "batch" },
                    { label: "Product", value: "product" },
                  ]}
                  value={type}
                />
              )}
            </Box>
          </Grid>

          {/* Users saved actions */}
          <Grid item xs={12}>
            {events.map((element, eventsIndex) => {
              return (
                <Box key={`${element}${eventsIndex}`} display="flex">
                  <SimpleSelect
                    options={organizationActions()}
                    onChange={(event) => {
                      console.log(event.target.value);
                      const newArray = [...trackedEvents];
                      const item = newArray[index].events[eventsIndex];
                      item.event = event.target.value;
                      item.eventTriggerId = "";

                      setTrackedEvents(newArray);
                    }}
                    value={element.event}
                    variant="outlined"
                  />
                  {/* Plus button only shows up to allow user to add a new item

                        Also only shows up on last item in the array*/}
                  {!newItemshow && events.length - 1 === eventsIndex ? (
                    <Box
                      className="pointer"
                      onClick={() => {
                        setNewItemShow(true);
                      }}
                      mb="auto"
                      ml={1}
                      mt="auto"
                    >
                      <i className="fas fa-plus" />
                    </Box>
                  ) : null}

                  {/* Minus will not show if it is the last item in the array */}
                  {events.length === 1 ? null : (
                    <Box
                      mb="auto"
                      ml={1}
                      mt="auto"
                      className="pointer"
                      onClick={() => {
                        const newActions = [...trackedEvents];
                        const removedAction =
                          newActions[index].events[eventsIndex];
                        const removedActionsEventTriggerId =
                          removedAction.eventTriggerId;
                        const newEventTrigerIdsToRemove = [
                          ...eventTrigerIdsToRemove,
                        ];

                        // This handles deleting the eventTargetId associated with removing
                        // an action from a notification. This will store the eventTriggerId
                        // and remove the id's when the onSubmit fires.
                        newEventTrigerIdsToRemove.push(
                          removedActionsEventTriggerId
                        );
                        setEventTrigerIdsToRemove(newEventTrigerIdsToRemove);

                        // This handles the user removes an action from a notifcation
                        newActions[index].events.splice(eventsIndex, 1);
                        setTrackedEvents(newActions);
                      }}
                    >
                      <i className="fas fa-minus" />
                    </Box>
                  )}
                </Box>
              );
            })}
          </Grid>

          {/* Section for adding a new action */}
          <Grid item xs={12}>
            <Box mt={2}>
              {newItemshow ? (
                <Box display="flex">
                  <SimpleSelect
                    options={organizationActions()}
                    onChange={(event) => {
                      const newArray = [...trackedEvents];
                      newArray[index].newAction = event.target.value;
                      setTrackedEvents(newArray);
                    }}
                    value={newAction}
                    variant="outlined"
                  />

                  {/* Plus Button */}
                  <Box
                    className="pointer"
                    mb="auto"
                    ml={1}
                    mt="auto"
                    onClick={(event) => {
                      const newArray = [...trackedEvents];
                      const { newAction = "" } = newArray[index];
                      if (newAction.length !== 0) {
                        // Pushes the selected action to the events array
                        newArray[index].events.push({
                          event: newAction,
                          eventTriggerId: "",
                        });

                        // Clears out the newAction field
                        newArray[index].newAction = "";

                        setTrackedEvents(newArray);
                      }
                    }}
                  >
                    {/* Plus button is muted if no action is selected */}
                    <i
                      className="fas fa-plus"
                      style={{
                        color: newAction.length !== 0 ? "" : "#6c757d",
                      }}
                    />
                  </Box>

                  {/* Minus button will not show up if there are no events available for this notification profile */}
                  {trackedEvents[index].events.length !== 0 ? (
                    <Box
                      className="pointer"
                      mb="auto"
                      ml={1}
                      mt="auto"
                      onClick={() => {
                        if (trackedEvents[index].events.length !== 0) {
                          setNewItemShow(false);
                        }
                      }}
                    >
                      <i
                        className="fas fa-minus"
                        style={{
                          color:
                            trackedEvents[index].events.length !== 0
                              ? ""
                              : "#6c757d",
                        }}
                      />
                    </Box>
                  ) : null}
                </Box>
              ) : null}
              <Button
                color="secondary"
                onClick={() => {
                  setModalOpen({ open: true, type: type });
                  setSelectedItemIndex(index);
                }}
              >
                Delete
              </Button>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default NotificationProfile;
