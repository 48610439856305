import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { retrieveAssetCategories } from "./api";
import { Grid } from "@material-ui/core";
import { BrandColorArray } from "../../../styles/BrandColors";

export default function AssetCategoriesPieChart(props) {
  const [state, setState] = useState({
    datasets: [],
    labels: [],
  });

  const { apiUrl, token } = props;

  useEffect(() => {
    retrieveAssetCategories({ apiUrl, token }).then((response) => {
      const assetCategories = response.count.category_agg || {};
      let datasets = [];
      let labels = [];
      Object.keys(assetCategories).forEach((category) => {
        datasets.push(assetCategories[category]);
        labels.push(category);
      });
      setState({
        datasets: [{ data: datasets, backgroundColor: BrandColorArray }],
        labels,
      });
    });
  }, [apiUrl, token]);

  return (
    <Grid container spacing={2} id={"assetCategoriesWidget"}>
      {/* TODO: Need to make this use the Typography component. Needs to match other 
        elements on the dash through */}
      <Grid item xs={12}>
        <h5>Number of Assets by Categories</h5>
      </Grid>
      <Grid item xs={12} style={{ height: "35vh" }}>
        <Pie
          data={{
            datasets: state.datasets,
            labels: state.labels,
          }}
          options={{
            onHover: function (event) {
              event.native.target.style.cursor = "default";
            },
            hoverOffset: 4,
            // responsive: true,
            maintainAspectRatio: false,
            plugins: {
              // title: {
              //   display: false,
              //   text: `title hey`,
              // },
              legend: {
                onHover: function (event, legendItem) {
                  // There is only a legendItem when your mouse is positioned over one
                  if (legendItem) {
                    event.native.target.style.cursor = "pointer";
                  }
                },

                position: "bottom",
                labels: {
                  usePointStyle: true,
                },
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
