import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { naturalSort } from "../../../utils/naturalSort";
import { parseUserInfo } from "../utils";
import BinSelector from "../../../components/Selects/BinSelector/BinSelector";
import chroma from "chroma-js";
import CloseIcon from "@material-ui/icons/Close";
import DatePicker from "../../../components/Forms/FieldTypes/DatePicker";
import HoverIconButton from "../../../components/ReusedComponents/HoverIconButton";
import Loading from "../../../components/Loading/Loading";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import ReactSelectCheckboxes from "../../../components/Selects/ReactSelectCheckboxes";
import SimpleSwitch from "../../../components/Forms/FieldTypes/Switch";
import states from "./states.json";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: "8rem",
  },
  clearFiltersButton: {
    backgroundColor: theme.palette.typography.secondary,
    color: "white",
  },
  filterButton: {
    backgroundColor: `${theme.palette.primary.main}`,
    color: "white",
    marginLeft: "1rem !important",
  },
  filterOption: {
    color: theme.palette.text.secondary,
    marginTop: "1vh",
  },
  root: {
    flexGrow: 1,
    height: "100%",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function FilterMenu(props) {
  const { displayColumnOptions, setState, state } = props;
  const { lists } = state;
  const {
    assetTag = {},
    assetType = {},
    binLocation: displayBinLocation = {},
    cityState = {},
    event = {},
    facility = {},
    flagged = {},
    user = {},
    zone = {},
  } = displayColumnOptions;
  const {
    assetsArray,
    assetTypes,
    availableZones,
    eventTypes,
    eventTypesMap,
    facilityArray,
    usersMap,
  } = lists;
  const [filterState, setFilterState] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // We set the initial filters using useEffect for the instance that a user does not
  // navigate away from the page, but uses the "Tag Look up" bar to go to another snapshot page
  useEffect(() => {
    setFilterState({ ...state.filters });
  }, [state.filters]);

  return (
    <>
      <span onClick={handleClickOpen}>
        <HoverIconButton
          icon="fas fa-filter"
          iconDirection="right"
          text="Filters"
        />
      </span>
      
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="filters"
        open={open}
      >
        <DialogTitle id="filters" onClose={handleClose}>
          Filters
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            {/* Item Tag */}
            {assetTag.checked ? (
              <Grid item xs={12}>
                <label className={classes.filterOption}>Item Tag</label>
                <ReactSelectCheckboxes
                  onChange={(e) => {
                    setFilterState((prevState) => {
                      return {
                        ...prevState,
                        assetIds: e,
                      };
                    });
                  }}
                  options={
                    assetsArray && assetsArray.length
                      ? assetsArray
                          .filter((a) => {
                            if (props.state.filters?.type?.value) {
                              return (
                                a.assetType === props.state.filters.type.value
                              );
                            }
                            return a;
                          })
                          .map((asset, idx) => {
                            return {
                              label: asset.tag,
                              value: asset.assetId,
                            };
                          })
                          .sort((a, b) =>
                            naturalSort(a.label, b.label)
                          )
                      : []
                  }
                  value={filterState.assetIds}
                />
              </Grid>
            ) : null}

            {/* Unit of Measure */}
            {assetType.checked ? (
              <Grid item xs={12}>
                <label className={classes.filterOption}>Unit of Measure</label>
                <ReactSelectCheckboxes
                  onChange={(e) => {
                    setFilterState((prevState) => {
                      return {
                        ...prevState,
                        type: e,
                      };
                    });
                  }}
                  options={
                    assetTypes && assetTypes.length
                      ? assetTypes
                          .map((type, idx) => {
                            return {
                              label: type,
                              value: type,
                            };
                          })
                          .sort((a, b) =>
                            naturalSort(a.label, b.label)
                          )
                      : []
                  }
                  value={filterState.type}
                />
              </Grid>
            ) : null}

            {/* Event Type */}
            {event.checked ? (
              <Grid item xs={12}>
                <label className={classes.filterOption}>Event Type</label>
                <ReactSelectCheckboxes
                  onChange={(e) => {
                    setFilterState((prevState) => {
                      return {
                        ...prevState,
                        events: e,
                      };
                    });
                  }}
                  options={
                    eventTypes && eventTypes.length
                      ? eventTypes
                          .map((event, idx) => {
                            return {
                              label: event,
                              value: event,
                              color: eventTypesMap[event]?.color || "black",
                            };
                          })
                          .sort((a, b) =>
                            naturalSort(a.label, b.label)
                          )
                      : []
                  }
                  styles={{
                    menuPortal: (styles) => ({
                      ...styles,
                      zIndex: 99999,
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      const color = data.color ? chroma(data.color) : "";
                      if (!color) {
                        return {
                          ...styles,
                        };
                      }
                      return {
                        ...styles,
                        backgroundColor: isDisabled
                          ? null
                          : isSelected
                          ? data.color
                          : isFocused
                          ? color.alpha(0.1).css()
                          : null,
                        color: isDisabled
                          ? "#ccc"
                          : isSelected
                          ? chroma.contrast(color, "black") < 7
                            ? "white"
                            : "black"
                          : data.color,
                        cursor: isDisabled ? "not-allowed" : "default",

                        ":active": {
                          ...styles[":active"],
                          backgroundColor:
                            !isDisabled &&
                            (isSelected ? data.color : color.alpha(0.3).css()),
                        },
                      };
                    },
                    multiValue: (styles, { data }) => {
                      const color = data.color ? chroma(data.color) : "";
                      if (!color) {
                        return {
                          ...styles,
                        };
                      }
                      return {
                        ...styles,
                        backgroundColor: color.alpha(0.1).css(),
                      };
                    },
                    multiValueRemove: (styles, { data }) => ({
                      ...styles,
                      color: "#b71d1a",
                      cursor: "pointer",
                      ":hover": {
                        backgroundColor: "#b71d1a",
                        color: "white",
                      },
                    }),
                  }}
                  value={filterState.events}
                />
              </Grid>
            ) : null}

            {/* User Name */}
            {user.checked ? (
              <Grid item xs={12}>
                <label className={classes.filterOption}>User</label>
                <ReactSelectCheckboxes
                  options={
                    usersMap && Object.keys(usersMap).length
                      ? [
                          ...Object.keys(usersMap)
                            .map((key) => {
                              return {
                                label: parseUserInfo(usersMap[key]),
                                value: key,
                              };
                            })
                            .filter((user) => user.label !== ""),

                          {
                            label: "User, Android",
                            value: "aabbab69-10c3-4c7e-9011-6f1c05e7b0a7",
                          },
                          {
                            label: "User, iOS",
                            value: "53ef1d1f-3e47-46e7-b444-18170051486f",
                          },
                        ].sort((a, b) =>
                          naturalSort(a.label, b.label)
                        )
                      : []
                  }
                  onChange={(e) => {
                    setFilterState((prevState) => {
                      return {
                        ...prevState,
                        users: e,
                      };
                    });
                  }}
                  value={filterState.users}
                />
              </Grid>
            ) : null}

            {/* Facility */}
            {facility.checked ? (
              <Grid item xs={12}>
                <label className={classes.filterOption}>Facility Name</label>
                <ReactSelectCheckboxes
                  options={
                    facilityArray && facilityArray.length
                      ? [
                          ...facilityArray.map((facility) => {
                            return {
                              label: facility.name,
                              value: facility,
                            };
                          }),
                        ].sort((a, b) =>
                          naturalSort(a.label, b.label)
                        )
                      : []
                  }
                  onChange={(e) => {
                    setFilterState((prevState) => {
                      return {
                        ...prevState,
                        locations: e,
                        locale: null,
                      };
                    });
                  }}
                  value={filterState.locations}
                />
              </Grid>
            ) : null}

            {/* State */}
            {cityState.checked ? (
              <Grid item xs={12}>
                <label className={classes.filterOption}>State</label>
                <ReactSelectCheckboxes
                  isDisabled={
                    filterState.locations === null ||
                    filterState.locations === undefined ||
                    filterState.locations.length === 0
                      ? false
                      : true
                  }
                  options={states ? states : []}
                  onChange={(e) => {
                    setFilterState((prevState) => {
                      return {
                        ...prevState,
                        locals: e,
                      };
                    });
                  }}
                  value={filterState.locals}
                />
              </Grid>
            ) : null}

            {/* Bin Locations */}
            {displayBinLocation.checked ? (
              <Grid item xs={12}>
                <BinSelector
                  {...props}
                  labelStyles={classes.filterOption}
                  onChange={(e) => {
                    setFilterState((prevState) => {
                      return {
                        ...prevState,
                        binLocations: e || null,
                      };
                    });
                  }}
                  value={filterState.binLocations || null}
                />
              </Grid>
            ) : null}

            {/* Zones */}
            {zone.checked ? (
              <Grid item xs={12}>
                <label className={classes.filterOption}>Zones</label>
                <ReactSelectCheckboxes
                  options={
                    availableZones
                      ? availableZones.sort((a, b) =>
                          naturalSort(a.label, b.label)
                        )
                      : []
                  }
                  onChange={(e) => {
                    setFilterState((prevState) => ({
                      ...prevState,
                      zones: e,
                    }));
                  }}
                  value={filterState.zones}
                />
              </Grid>
            ) : null}

            {/* Start Date */}
            <Grid item xs={6}>
              <label className={classes.filterOption}>Select Start Date</label>
              <DatePicker
                format="yyyy/MM/DD"
                label="Start Date"
                onChange={(date) => {
                  if (filterState.endDate && date > filterState.endDate) {
                    alert("Can't set start date later than end date");
                    return;
                  }
                  setFilterState((prevState) => {
                    return {
                      ...prevState,
                      startDate: date,
                    };
                  });
                }}
                size="small"
                value={filterState.startDate}
              />
            </Grid>

            {/* End Date */}
            <Grid item xs={6}>
              <label className={classes.filterOption}>Select End Date</label>
              <DatePicker
                format="yyyy/MM/DD"
                label="End Date"
                onChange={(date) => {
                  if (filterState.startDate && date < filterState.startDate) {
                    alert("Can't set end date earlier than start date");
                    return;
                  }

                  setFilterState((prevState) => {
                    return {
                      ...prevState,
                      endDate: date,
                    };
                  });
                }}
                size="small"
                value={filterState.endDate}
              />
            </Grid>

            {/* PCA */}
            {flagged.checked ? (
              <Grid item xs={12}>
                <SimpleSwitch
                  checked={filterState.pca}
                  label="PCA Flagged"
                  onChange={(e) => {
                    setFilterState((prevState) => ({
                      ...prevState,
                      pca: e.target.checked,
                    }));
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          {loading ? <Loading color="#5884F5" opaque /> : ""}

          <Button
            className={`${classes.clearFiltersButton} ${classes.button}`}
            onClick={() => {
              setFilterState({
                ...state.filters,
                assets: null,
                binLocations: null,
                endDate: null,
                events: null,
                locals: null,
                locations: null,
                pca: false,
                startDate: null,
                type: null,
                users: null,
                zones: null,
              });
            }}
            variant="contained"
          >
            Clear Filters
          </Button>
          <div style={{ flex: "1 0 0" }} />
          <Button
            className={classes.button}
            onClick={handleClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className={`${classes.filterButton} ${classes.button}`}
            onClick={() => {
              setLoading(true);
              setState({
                ...state,
                selectedAssets: {},
                filters: {
                  ...filterState,
                  start: "0",
                },
              });
              setLoading(false);
              handleClose();
            }}
            variant="contained"
          >
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
