import { createSlice } from "@reduxjs/toolkit";

import { initAssetHistory } from "./thunks/init";
import * as thunks from "./thunks/thunks";
import { extraReducers } from "./thunks/extraReducers";

export const assetHistorySlice = createSlice({
  name: "assetHistory",
  initialState: {
    init: {
      status: "idle",
    },
    filters: {},
    histories: [],
    count: 0,
    showInstructions: false,
  },
  reducers: {},
  extraReducers: {
    ...extraReducers,
  },
});

// Action creators are generated for each case reducer function
// const {} = devicesSlice.actions;

export { initAssetHistory, thunks };

export default assetHistorySlice.reducer;
