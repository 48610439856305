import { createSlice } from "@reduxjs/toolkit";

import { initAssets } from "./thunks/init";
import * as thunks from "./thunks/thunks";
import { extraReducers } from "./thunks/extraReducers";

export const assetsSlice = createSlice({
  name: "assets",
  initialState: {
    init: {
      status: "idle",
    },
    filters: {},
    assets: [],
    count: 0,
  },
  reducers: {},
  extraReducers: {
    ...extraReducers,
  },
});

// Action creators are generated for each case reducer function
// const {} = devicesSlice.actions;

export { initAssets, thunks };

export default assetsSlice.reducer;
