import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight:700,
  },
}));

function SimpleCheckbox(props) {
  const {
    checked = false,
    color = "primary",
    label = "",
    name = "",
    onChange,
  } = props;
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.checkbox}
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => {
            onChange(e);
          }}
          name={name}
          color={color}
        />
      }
      label={label}
    />
  );
}

export default SimpleCheckbox;
