import moment from "moment-timezone";

const browserTimeZone = moment.tz.guess();

// function for rendering time from the backend (UTC) in standard 12 hour clock, e.g., 2020-08-23 12:32 p.m, in the users' set timezone.
export const utc_to_local_12_hour_clock = (
  timeOfLog,
  timeZone
) => {
  const tz = timeZone?.value || timeZone || browserTimeZone;

  return moment
    .utc(timeOfLog || null, "YYYYMMDDHHmmss")
    .tz(tz)
    .format("YYYY-MM-DD hh:mm:ss a");
};

// function for rendering time from the backend (UTC) in 24 hour clock, e.g., 2020-08-23 14:32, in the users' set timezone. Use this function instead of the civil time above for transforming timeOfLog strings in the table column data accessors
export const utc_to_local_24_hour_clock = (
  timeOfLog,
  timeZone
) => {
  const tz = timeZone?.value || timeZone || browserTimeZone;
  return moment
    .utc(timeOfLog, "YYYYMMDDHHmmss")
    .tz(tz)
    .format("YYYY-MM-DD HH:mm:ss");
};

// asset table uses unix time for timeOfLog, asset history uses world clock. For accessors and data sorting, just use row.timeOfLog and then render in the table cell using one of the functions below:

export const unix_utc_to_local_12_hour_clock = (
  timeOfLog,
  timeZone
) => {
  const tz = timeZone?.value || timeZone || browserTimeZone;

  return moment
    .utc(timeOfLog || null, "x")
    .tz(tz)
    .format("YYYY-MM-DD hh:mm:ss a");
};

export const unix_utc_to_local_24_hour_clock = (
  timeOfLog,
  timeZone
) => {
  const tz = timeZone?.value || timeZone || browserTimeZone;

  return moment
    .utc(timeOfLog || null, "x")
    .tz(tz)
    .format("YYYY-MM-DD hh:MM:ss");
};

export const date_interval_util = (selection, timeZone) => {
  const tz = timeZone?.value || timeZone || browserTimeZone;

  const currentTime = moment().tz(tz);
  const mutateTime = moment().tz(tz);

  let startDate = null;
  let endDate = null;

  switch (selection) {
    case "daily":
      endDate = currentTime;
      startDate = mutateTime.subtract(1, "day");
      break;
    case "weekly":
      endDate = currentTime;
      startDate = mutateTime.subtract(1, "week");
      break;
    case "monthly":
      endDate = currentTime;
      startDate = mutateTime.subtract(1, "month");
      break;
    default:
      break;
  }

  return {
    startDate: startDate.format(),
    endDate: endDate.format(),
  };
};
