import { PureComponent } from "react";
import area from "@turf/area";
import "./styles.css";

export default class AreaText extends PureComponent {
  render() {
    const satView = this.props.satView;
    const polygon = this.props.polygon;
    const polygonArea = polygon && area(polygon);
    return (
      <>
        {polygon && (
          <div className="control-panel">
            <p
              style={{ opacity: "1!important" }}
              className={satView ? "white-text" : "black-text"}
            >
              {(Math.round(polygonArea) * 100) / 100} <br />
              square meters
            </p>
          </div>
        )}
      </>
    );
  }
}
