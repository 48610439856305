const DefaultOrganizationRolesMap = {
  console: {
    Admin: {
      actions: {
        "Create Assets": {},
        "Create Asset Types": {},
        "Create Batches": {},
        "Create CQR": {},
        "Create Event Types": {},
        "Create Location Types": {},
        "Create Facilities": {},
        "Create Locations": {},
        "Create Organizations": {},
        "Create Product Types": {},
        "Create Products": {},
        "Create Users": {},
        "Delete Asset Types": {},
        "Delete Batches": {},
        "Delete Event Types": {},
        "Delete Location Types": {},
        "Delete Facilities": {},
        "Delete Locations": {},
        "Delete Organizations": {},
        "Delete Product Types": {},
        "Delete Products": {},
        "Delete Users": {},
        "Edit Asset History": {},
        "Edit Asset Types": {},
        "Edit Batches": {},
        "Edit Event Types": {},
        "Edit Location Types": {},
        "Edit Facilities": {},
        "Edit Locations": {},
        "Edit Organizations": {},
        "Edit Product Types": {},
        "Edit Products": {},
        "Edit Users": {},
        Scan: {},
        "Unlock Users": {},
        "View Batches": {},
        "View Devices": {},
        "View Global Settings": {},
        "View Groups": {},
        "View Facilities": {},
        "View Locations": {},
        "View Organizations": {},
        "View Products": {},
        "View Settings": {},
        "View Users": {},
      },
    },
    Standard: {
      actions: {
        "Create Assets": {},
        "Create Batches": {},
        "Create CQR": {},
        "Create Facilities": {},
        "Create Locations": {},
        "Create Organizations": {},
        "Create Products": {},
        "Delete Batches": {},
        "Delete Facilities": {},
        "Delete Locations": {},
        "Delete Products": {},
        "Edit Asset History": {},
        "Edit Batches": {},
        "Edit Facilities": {},
        "Edit Locations": {},
        "Edit Organizations": {},
        "Edit Products": {},
        Scan: {},
        "View Batches": {},
        "View Facilities": {},
        "View Locations": {},
        "View Organizations": {},
        "View Global Settings": {},
        "View Products": {},
        "View Settings": {},
      },
    },
    Lite: {
      actions: {
        "View Batches": {},
        "View Products": {},
        "View Settings": {},
      },
    },
    "No Console Access": {},
  },
  mobile: {
    "Asset Editor": {
      actions: {
        scan: {},
        editAsset: {},
        topology: {},
      },
    },
    "Asset Scanner": {
      actions: {
        scan: {},
      },
    },
    Mobile: {
      actions: {
        Scan: {},
      },
    },
  },
};

export default DefaultOrganizationRolesMap;
