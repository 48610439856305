import moment from "moment-timezone";

// At some point this function should replace showLastEvents. Need to know more about implementing Redux before
// continuing
export const searchAssets = async (props, providedState) => {
  const {
    apiUrl,
    classifications,
    hasShortage,
    inputValue,
    organizationId,
    skip,
    token,
  } = props;
  const { filters = {}, isExport = false, page = 0 } = providedState;
  let {
    assetCategories,
    assetIds,
    assetType,
    binLocation,
    category,
    endDate = null,
    facility,
    lastEvent,
    lastEvents,
    limit,
    locals,
    locations,
    pca,
    sorted,
    state,
    startDate = null,
    tagWildCardSearchValue,
    user,
    users,
    zone,
    zones,
  } = filters;
  let parsedSorted =
    sorted && sorted.length
      ? (() => {
          let obj = {
            id: "",
            order: sorted[0].desc ? "DESC" : "ASC",
          };
          switch (sorted[0].id) {
            case "timeOfLog":
              obj.id = "time_of_log";
              break;
            case "category":
              obj.id = "category";
              break;
            case "tag":
              obj.id = "tag";
              break;
            case "lastEvent":
              obj.id = "last_event";
              break;
            case "state":
              obj.id = "state";
              break;
            case "facility":
              obj.id = "facility";
              break;
            case "latLong":
              if (sorted[0].desc) {
                obj.id = "latitude DESC, longitude ASC";
              } else {
                obj.id = "latitude ASC, longitude DESC";
              }
              obj.latLong = true;
              break;
            case "user":
              obj.id = "last_name";
              break;
            default:
              obj.id = "time_of_log";
              break;
          }
          return obj;
        })()
      : "";
  let sortedObject =
    parsedSorted && parsedSorted.id
      ? (() => {
          if (parsedSorted.latLong) {
            return `${parsedSorted.id}, `;
          } else {
            // return `${parsedSorted.id} ${parsedSorted.order}, `;
            return {
              [parsedSorted.id]: parsedSorted.order,
            };
          }
        })()
      : "";

  // escape special characters from string inputs
  let escapedAssetType = assetType ? assetType : null;

  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              current_owner_id: organizationId,
            },
          },
          {
            bool: {
              must_not: [
                {
                  nested: {
                    path: "device",
                    query: {
                      exists: {
                        field: "device.status",
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
        should: [],
      },
    },
    skip,
    // Limit behaves differently than I expected on Elastic Search. We have to increment the count up as we skip along.
    limit: isExport ? 1000 : limit + skip || 25,
    range: {},
    page,
    sort: [
      {
        tag: "desc",
      },
      sortedObject,
    ],
  };

  // TODO - Elastic - You need to go back to bitBucket and find out where some of those unused variables were previously being used

  // Asset Tag
  if (assetIds && assetIds.length) {
    const assetIdArray = assetIds.map((assetId) => {
      return assetId.value;
    });

    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: {
        asset_id: assetIdArray,
      },
    });
  }

  // Asset Type - TODO - Im adding this is now to mimic the old SOLR Query. But Im starting to think state.assetType never gets hit.
  if (escapedAssetType) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: {
        asset_type: escapedAssetType,
      },
    });
  }

  // Bin Location
  if (binLocation?.length) {
    const binLocationsArray = binLocation?.map((bin) => {
      return {
        term: {
          "zone.bin_location.keyword": bin.value,
        },
      };
    });

    elasticQuery.elasticSearchQuery.bool.must.push({
      bool: {
        should: [
          {
            nested: {
              path: "zone",
              query: {
                bool: {
                  should: binLocationsArray,
                },
              },
            },
          },
        ],
      },
    });
  }

  // Categories - we have to accomodate assetcategories and categories due to users with this presaved.
  if (
    assetCategories?.length > 0 ||
    (category?.length > 0 && Array.isArray(category))
  ) {
    // We build up the array
    const categoryArray =
      assetCategories?.length > 0
        ? assetCategories
        : category.map((cat) => {
            return cat.value;
          });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { category: categoryArray },
    });
  }

  // Classifications
  const classificationFilters = Object.keys(filters || {})
    .filter(
      (filterKey) => classifications[filterKey] && filters[filterKey] !== null
    )
    .map((filterKey) => filters[filterKey]);

  if (classificationFilters?.length) {
    classificationFilters?.map((classArray) => {
      // We initially declare the object we are going to push into the query
      let queryObject = {
        nested: {
          path: "classification_data",
          query: {
            bool: {
              must: [],
              should: [],
              minimum_should_match: 1,
            },
          },
        },
      };

      // We build up the queryObject here
      classArray?.map((childClass) => {
        // Make sure the parentId is present
        if (queryObject.nested.query.bool.must.length === 0) {
          queryObject.nested.query.bool.must.push({
            match: {
              "classification_data.key.keyword": childClass.parentLabel,
            },
          });
        }

        // Push the children
        queryObject.nested.query.bool.should.push({
          match: {
            "classification_data.value.keyword": childClass.label,
          },
        });
        return null;
      });

      elasticQuery.elasticSearchQuery.bool.must.push(queryObject);

      return null;
    });
  }

  // Event
  if ((lastEvents && lastEvents?.length) || (lastEvent && lastEvent?.length)) {
    // We Build up the array
    const lastEventArray =
      lastEvents && lastEvents?.length
        ? lastEvents
        : lastEvent.map((event) => {
            return event.value;
          });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { last_event: lastEventArray },
    });
  }

  // Facility
  if ((locations && locations.length) || (facility && facility.length)) {
    // We build the array
    let locationsSet =
      locations && locations.length
        ? locations
        : facility.map((e) => {
            return e.value?.facilityId || e;
          });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { facility_id: locationsSet },
    });
  }

  // Has Shortage - Some of our dashboard widgets use this
  if (hasShortage) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      bool: {
        must: [
          {
            nested: {
              path: "quantity",
              query: {
                term: {
                  "quantity.has_shortage": true,
                },
              },
            },
          },
        ],
      },
    });
  }

  // Input Value - Wild card searches from the different Filters Menu
  if (inputValue) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      regexp: {
        "tag.wildcard": {
          value: `${inputValue}.*`,
        },
      },
    });

    elasticQuery.sort = "tag ASC";
  }

  // PCA
  if (pca) {
    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { flagged: true },
    });
  }

  // State - Some components are using the word locals and some components are using the word state. Need to unify this
  if ((locals && locals.length) || (state && state.length)) {
    if (locals && locals.length > 0) {
      let localsArray = locals.map((e) => {
        return e.value;
      });

      // And then push it to the query
      elasticQuery.elasticSearchQuery.bool.must.push({
        terms: { state: localsArray },
      });
    } else {
      let statesArray = state.map((e) => {
        return e.value;
      });

      elasticQuery.elasticSearchQuery.bool.must.push({
        terms: { state: statesArray },
      });
    }
  }

  // Tag
  if (tagWildCardSearchValue) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      regexp: {
        "tag.wildcard": {
          value: `${tagWildCardSearchValue}.*`,
        },
      },
    });
  }

  // Users
  if (users?.length || user?.length) {
    // We Build the Array
    const usersArray = users?.length
      ? users
      : user.map((user) => {
          return user.value;
        });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { app_user_id: usersArray },
    });
  }

  // Zones
  if ((zones && zones.length) || (zone && zone.length)) {
    let zonesArray =
      zones && zones.length
        ? zones
        : zone.map((zone) => {
            const { internalZoneType = null, value = "" } = zone;

            // if {zoneId}.internalZoneId === 'processing' then we know the zone is a
            // pZone. If that value is === 'target' then we know the zone is a tZone.

            if (internalZoneType === "processing") {
              return { "zone.p_zone_id": value };
            } else if (internalZoneType === "target") {
              return { "zone.t_zone_id": value };
            } else {
              return { "zone.zone_id": value };
            }
          });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      nested: {
        path: "zone",
        query: {
          bool: {
            must: [
              {
                terms: {
                  "zone.zone_id": zonesArray,
                },
              },
            ],
          },
        },
      },
    });
  }

  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  if (moment(startDate).isValid() || moment(endDate).isValid()) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      range: {
        time_of_log: {
          gte: moment(startDate).isValid()
            ? moment(startDate).startOf("day").valueOf()
            : null,
          lte: moment(endDate).isValid()
            ? moment(endDate).endOf("day").valueOf()
            : "now",
        },
      },
    });
  }

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "auth-token": token,
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(elasticQuery),
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });
  return results;
};
