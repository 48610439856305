export const searchRadios = async (props, zoneIds) => {
  const { apiUrl, token } = props;
  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            terms: {
              tl_zone_id:zoneIds
            }
          }
        ]
      },
    }
  };
  const results = await fetch(`${apiUrl}radios/search/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((response) => response)
    .catch((err) => console.log(err));
  return results;
};