export const createFacility = async (props,payload) => {
  const { apiUrl, token } = props
  const results = await fetch(`${apiUrl}facilities`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload)
  })
  .then((response) => response.json())
  .then((json) => json)
  .catch((err)=> {
    console.log(err)
  })
  return results
}

export const editFacility = async (props,payload) => {
  const { apiUrl, facilityId, token } = props
  const results = await fetch(`${apiUrl}facilities/${facilityId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload)
  })
  .then((response) => response.json())
  .then((json) => json)
  .catch((err)=> {
    console.log(err)
  })
  return results
}

