import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";
import SimpleSelect from "../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  removeField: {
    textAlign: "right",
  },
  section: {
    padding: "1rem 0",
  },
  warning: {
    color: "red",
    marginLeft: ".5rem",
  },
}));

export default function ConnectPageBuilderModal(props) {
  const { setState, state = {} } = props;
  const { selectedProduct = {} } = state;
  const { propertiesMap = {} } = selectedProduct;
  const { template = {} } = propertiesMap;
  const classes = useStyles();
  const [modalState, setModalState] = useState({
    connectPageName: "",
    isCreate: false,
    isError: false,
    isPcaRedirect: false,
    selectedConnectPage: "",
  });

  const pageOptions = Object.keys(template);
  const pageSelectOptions = pageOptions.map((item) => ({
    value: item,
    label: item,
  }));

  return (
    <Grid container>
      {modalState.isPcaRedirect ? (
        <Redirect
          to={{
            pathname: `pca/${
              modalState.isCreate
                ? modalState.connectPageName
                : modalState.selectedConnectPage
            }/${selectedProduct.productId}`,
            state: {
              connectPageName: modalState.isCreate
                ? modalState.connectPageName
                : modalState.selectedConnectPage,
              selectedProduct,
            },
          }}
        />
      ) : null}
      {/* Select Connect Page */}
      <Grid className={classes.section} item xs={12}>
        <SimpleSelect 
        disabled={modalState.isCreate}
        label="Select Connect Page"
        onChange={(e) => {
          setModalState((prevState) => ({
            ...prevState,
            selectedConnectPage: e.target.value,
          }));
        }}
        options={pageSelectOptions}
        size="small"
        value={modalState.selectedConnectPage}
        variant="outlined"
        />
      </Grid>

      {/* Add New Button and Connect Page Name */}
      <Grid className={classes.section} item xs={12}>
        {modalState.isCreate ? (
          <SimpleTextField
            label="Connect Page Name"
            onChange={(e) =>
              setModalState((prevState) => ({
                ...prevState,
                connectPageName: e.target.value,
                isError: false,
              }))
            }
            size="small"
            value={modalState.connectPageName}
          />
        ) : (
          <MaterialUiButton
            color="secondary"
            label="Add New"
            onClick={() => {
              setModalState((prevState) => ({ ...prevState, isCreate: true }));
            }}
          />
        )}
        {modalState.isError ? (
          <span className={classes.warning}>
            This page already exist, please choose another name
          </span>
        ) : null}
      </Grid>

      {/* Cancel / Submit Buttons */}
      <Grid
        className={`${classes.section} ${classes.buttonContainer}`}
        item
        xs={12}
      >
        {/* Cancel Button */}
        <Grid item xs={4}>
          <MaterialUiButton
            color="cancel"
            fullWidth={true}
            label="Cancel"
            onClick={() => {
              if (
                modalState.isCreate ||
                modalState.selectedConnectPage !== ""
              ) {
                setModalState((prevState) => ({
                  ...prevState,
                  connectPageName: "",
                  isCreate: false,
                  isError: false,
                  selectedConnectPage: "",
                }));
              } else {
                setState((prevState) => ({
                  ...prevState,
                  connectPageName: "",
                  modalShow: false,
                  selectedConnectPage: "",
                  selectedProduct: {},
                }));
              }
            }}
            variant="outlined"
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={4}>
          <MaterialUiButton
            color="primary"
            disabled={
              // If the users click Add New but has no name in the template name field
              (modalState.isCreate &&
                modalState.connectPageName?.length === 0) ||
              // If the user has not click Add New, but has not selected a connect page
              (!modalState.isCreate &&
                (modalState.selectedConnectPage?.length === 0 ||
                  modalState.selectedConnectPage === undefined)) ||
              // If there is an error on the modal (User inputted duplicate page name)
              modalState.isError
            }
            fullWidth={true}
            label="Submit"
            onClick={() => {
              // We need to make sure that we do not have duplicate template names
              if (!pageOptions.includes(modalState.connectPageName)) {
                setModalState((prevState) => ({
                  ...prevState,
                  isPcaRedirect: true,
                }));
              } else {
                setModalState((prevState) => ({ ...prevState, isError: true }));
              }
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
