export default function HoverIconDropdown(props) {
    const {
        icon,
        iconDirection,
        text,
    } = props

    function handleKeyPress(e) {
        if (e.key === "Enter" || e.code === "Space") { e.target.click() }
    }

    return (
        <span aria-label={text} aria-controls="display-menu" tabIndex="0" role="listbox" className="hoverIcon" aria-haspopup="true" onKeyPress={handleKeyPress}>
            {iconDirection === "left" ?
                <>
                    <i className={icon} style={{ marginRight: ".5rem" }}></i>
                    {text || ""}
                </>
                :
                <>
                    {text || ""}
                    <i className={icon} style={{ marginLeft: ".5rem" }}></i>
                </>
            }
        </span>

    )
}