import escapeCharacters from "../../../utils/escapedCharacters";

export const searchBins = async (props, input, limit = 50, start = 0) => {
  const { apiUrl, token, organizationId } = props;
  let escapedInput = escapeCharacters(input);
  const elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            match: {
              organization_id: organizationId,
            },
          },
          {
            regexp: {
              "bin_location.wildcard": {
                value: `${escapedInput}.*`,
                rewrite: "constant_score",
              },
            },
          },
        ],
      },
    },
    skip: start,
    limit: limit,
    sort: `time_of_log ASC`,
  };
  const results = await fetch(`${apiUrl}assetHistories/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};
