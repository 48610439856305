import Autocomplete from "@material-ui/lab/Autocomplete";
import { InputLabel, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function AutocompleteSelect(props) {
  const { 
    disabled = false,
    error,
    getOptionLabel = (option) => option.id, 
    id,
    key,
    label,
    multiple = false,
    onChange, 
    options, 
    size="small",
    value = [],
    variant = "outlined",
  } = props;
  const useStyles = makeStyles((theme) => ({
    label: {
      color:"#6D6E70",
      fontFamily:"Lato Semibold",
      fontWeight:"bold"
    }
  }));
  const autoCompleteClasses = useStyles();
  return (
    <div key={key}>
      <InputLabel className={autoCompleteClasses.label}>{label}</InputLabel>
      <Autocomplete
        disabled={disabled}
        id={id}
        multiple={multiple}
        options={options}
        onChange={(event, value) => onChange(event, value)}
        getOptionLabel={(option) => getOptionLabel(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={error ? "Required Field" : ""}
            size={size}
            variant={variant}
          />
        )}
        value={value}
      />
    </div>
  );
}
