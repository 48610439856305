import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
  },
  header: {
    textAlign: "center",
    marginBottom: "1rem",
  },
  root: {
    margin: ".5rem 1rem",
  },
  title: {
    marginRight: "1rem",
    fontWeight: "bold",
  },
}));

export default function CQRInformationCard(props) {
  const {
    assetCount,
    assetType,
    batchAsset,
    batchName,
    productName,
    selectedAssets = {},
  } = props;
  const classes = useStyles();
  const cardContent = batchAsset ? (
    <>
      <Grid className={classes.flex} item xs={12}>
        <Typography className={classes.title} variant="body1">
          Batch ID:
        </Typography>
        <Typography variant="body1">{batchName}</Typography>
      </Grid>
      <Grid className={classes.flex} item xs={12}>
        <Typography className={classes.title} variant="body1">
          Product Name:
        </Typography>
        <Typography variant="body1">{productName}</Typography>
      </Grid>
      <Grid className={classes.flex} item xs={12}>
        <Typography className={classes.title} variant="body1">
          Unit of Measure:
        </Typography>
        <Typography variant="body1">{assetType}</Typography>
      </Grid>
      <Grid className={classes.flex} item xs={12}>
        <Typography className={classes.title} variant="body1">
          Count:
        </Typography>
        <Typography variant="body1">{assetCount}</Typography>
      </Grid>
    </>
  ) : (
    <>
      <Grid item xs={3}>
        <Typography className={classes.title} variant="body1">
          Count:
        </Typography>
        <Typography className={classes.title} variant="body1">
          Asset Tag:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="body1">
          {Object.keys(selectedAssets).length}
        </Typography>
        <div>
          {Object.keys(selectedAssets).map((asset, index) => {
            const { tag, assetTag } = selectedAssets[asset];

            return (
              <Typography key={`${tag}-${index}`} variant="body1">
                {tag || assetTag || ""}
              </Typography>
            );
          })}
        </div>
      </Grid>
    </>
  );
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.header} variant="h4">
          Information
        </Typography>
        <Grid container>{cardContent}</Grid>
      </CardContent>
    </Card>
  );
}
