import CentroidRadius from "./AreaVerticalTabs/CentroidRadius";
import Polygon from "./AreaVerticalTabs/Polygon";
import VerticalTabs from "../../../../../components/Tabs/VerticalTabs";

function FacilityArea(props) {
  return (
    <VerticalTabs
      alignLeft
      containerStyle={{ backgroundColor: "#FAFAFA" }}
      panelStyle={{ minWidth: "80%" }}
    >
      <CentroidRadius
        {...props}
        label={
          <span style={{ textAlign: "left" }}>
            Centroid &<br />
            Radius
          </span>
        }
      />
      <Polygon
        {...props}
        label={<span style={{ textAlign: "left" }}>Polygon</span>}
      />
    </VerticalTabs>
  );
}

export default FacilityArea;
