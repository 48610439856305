export const saveFilterSettings = async (props, filters) => {
  const { apiUrl, token, userId } = props;
  const payload = {
    propertiesMap: {
      assetHistoryTableSettings: filters,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const getAssetsById = async (props, assetIds, limit = 1000) => {
  const { apiUrl, token, organizationId } = props;

  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must_not: [],
        must: [
          
          // Give us results only from this organization
          {
            term: {
              current_owner_id: organizationId,
            },
          },
          
          // Minus the devices
          {
            bool: {
              must_not: [
                {
                  nested: {
                    path: "device",
                    query: {
                      exists: {
                        field: "device.status",
                      },
                    },
                  },
                },
              ],
            },
          },

          // We dont want any assets that are part of a batch
          {
            bool: {
              must_not: [
                {
                  exists: {
                    field: "batch_id",
                  },
                },
              ],
            },
          },

          // Only give us inventory Assets
          {
            term: {
              asset_mode:"Inventory"
            }
          },

          // Here's the particular assetId we are looking for
          {
            terms: {
              asset_id: assetIds,
            },
          },
        ],
      },
    },
    limit: limit,
    sort: [
      {
        tag: "desc",
      },
      {
        asset_type: "asc",
      },
    ],
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};

export const resetDisplayColumnOptions = async (props) => {
  const { apiUrl, token, userId } = props;
  const payload = {
    propertiesMap: {
      assetHistoryTableSettings: {
        displayColumnOptions: null,
        defaultColumnOrder: [],
      },
    },
  };

  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};
