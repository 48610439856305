import moment from "moment";

export const getAssetProps = async (props, assetId) => {
  const { apiUrl, token } = props;

  const results = await fetch(`${apiUrl}assets/${assetId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });

  return results;
};

export const submitEditAsset = async (
  props,
  assetData,
  editAsset,
  selectedClassifications,
  note
) => {
  const customMap = {};
  const reducedProps = {};

  Object.keys(editAsset).forEach((item) => {
    // Here we divvy up whether or not items are customs
    if (editAsset[item].isCustom) {
      // Verify there is a value in the custom before appending it to the object
      if (editAsset[item].value.length > 0) {
        customMap[item] = editAsset[item].value;
      }
    } else {
      reducedProps[item] = editAsset[item].value;
    }
  });

  const body = {
    classificationMap: {},
    category: reducedProps.category || assetData.category,
    classificationSet: [],
    customMap,
    propertiesMap: {
      ...assetData.propertiesMap,
      ...reducedProps,
      note,
    },
  };

  // Handle Classifications
  // Object.keys(selectedClassifications).forEach((item) => {
  //   body.classificationSet.push(selectedClassifications[item].value);
  // });

  Object.keys(selectedClassifications).forEach((classification) => {
    const selectedClassification = selectedClassifications[classification];
    const { label = "", parentName = "" } = selectedClassification;
    body.classificationMap[parentName] = label;
  });

  const results = await fetch(`${props.apiUrl}assets/${assetData.assetId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update data, please contact system administrator.",
      };
    });
  return results;
};

export const submitUpdateEvent = async (props, updateEvent) => {
  const { apiUrl, token, facilities, userId, assetData } = props;
  const {
    location,
    event,
    note,
    zone = null,
    binLocation = null,
  } = updateEvent;
  let latitude = null;
  let longitude = null;
  if (
    location &&
    facilities[location.value] &&
    facilities[location.value].location
  ) {
    latitude = facilities[location.value]?.location?.latitude || null;
    longitude = facilities[location.value]?.location?.longitude || null;
  }
  const payload = {
    latitude,
    longitude,
    facility: facilities[location?.value] || null,
    action: event,
    appUserId: userId,
    binLocation: binLocation || null,
    zone:
      zone?.zoneId || binLocation
        ? {
            zoneId: zone?.zoneId || null,
            binLocation: binLocation || null,
          }
        : null,
    propertiesMap: {
      note: note,
    },
  };
  const results = await fetch(`${apiUrl}assets/${assetData.assetId}/action`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update event, please contact system administrator.",
      };
    });

  return results;
};

export const saveFilterSettings = async (
  props,
  assetSnapshotSettings
) => {
  const { apiUrl, token, userId } = props;

  // Removing filters that do not need to be saved on the users asset snapshot settings
  [
    "binLocation",
    "endDate",
    "events",
    "locals",
    "locations",
    "start",
    "startDate",
    "users",
    "zones",
  ].forEach((item) => {
    if (assetSnapshotSettings && assetSnapshotSettings[item]) {
      delete assetSnapshotSettings[item];
    }
  });

  const payload = {
    propertiesMap: {
      assetSnapshotSettings: assetSnapshotSettings,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => {
      return results;
    })
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

// TODO - Elastic - Need to test this function to verify it works once telemetry data comes in
export const getTelemetry = async (
  props,
  deviceId,
  filters,
  page
) => {
  const { apiUrl, organizationId } = props;
  const csmToken = process.env.REACT_APP_CSM_TOKEN;
  let data = {};
  let {
    devices = [],
    // start = 0,
    limit,
    startDate,
    endDate,
    types,
    radios,
    nodes,
  } = filters;

  let elasticQuery = {
    csmToken,
    organizationId,
    elasticSearchQuery: {
      bool: {
        must_not: [],
        must: [
          {
            term: {
              asset_id: deviceId,
            },
          },
        ],
      },
    },
    limit: limit ? limit : 25000,
    page,
    sort: `time_of_report DESC`,
  };

  // Devices
  if (devices && devices.length) {
    let deviceArray = devices.map((e) => {
      return e.value;
    });
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: {
        asset_id: deviceArray,
      },
    });
  }

  // Report Type
  if (types && types.length) {
    let typesArray = types.map((e) => {
      return e.value;
    });
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: {
        report_type: typesArray,
      },
    });
  }

  // Radios
  if (radios && radios.length) {
    let radiosArray = radios.map((e) => {
      return e.value;
    });
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: {
        radio: radiosArray,
      },
    });
  }

  // Nodes
  if (nodes && nodes.length) {
    let nodesArray = nodes.map((e) => {
      return e.value;
    });
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: {
        node: nodesArray,
      },
    });
  }

  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  if (moment(startDate).isValid() || moment(endDate).isValid()) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      range: {
        time_of_log: {
          gte: moment(startDate).isValid()
            ? moment(startDate).startOf("day").valueOf()
            : null,
          lte: moment(endDate).isValid()
            ? moment(endDate).endOf("day").valueOf()
            : null,
        },
      },
    });
  }

  const lastReportBody = {
    csmToken,
    elasticSearchQuery: {
      bool: {
        must_not: [],
        must: [
          {
            term: {
              asset_id: deviceId,
            },
          },
          {
            term: {
              report_type: "R",
            },
          },
        ],
      },
    },
    limit: 1,
    sort: `time_of_report DESC`,
  };

  const lastMovementBody = {
    csmToken,
    elasticSearchQuery: {
      bool: {
        must_not: [],
        must: [
          {
            term: {
              asset_id: deviceId,
            },
          },
          {
            term: {
              report_type: "M",
            },
          },
        ],
      },
    },
    limit: 1,
    sort: `time_of_report DESC`,
  };

  const telemetryData = await fetch(`${apiUrl}telemetry/csm/console/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to fetch Device, please contact system administrator.",
      };
    });

  const lastReport = await fetch(`${apiUrl}telemetry/csm/console/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lastReportBody),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to fetch Device, please contact system administrator.",
      };
    });

  const lastMovement = await fetch(`${apiUrl}telemetry/csm/console/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lastMovementBody),
  })
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to fetch Device, please contact system administrator.",
      };
    });

  data = { ...telemetryData, lastReport, lastMovement };

  return data;
};

export const retrieveRadioIds = async (props) => {
  const { apiUrl } = props;
  const csmToken = process.env.REACT_APP_CSM_TOKEN;
  const body = {
    csmToken,
  };
  const results = await fetch(`${apiUrl}radios/csm/console`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      const nodes = {};
      const radios = {};
      response.radios.forEach((item) => {
        nodes[item.node] = item.node;
        radios[item.radio] = item.radio;
      });
      return { nodes, radios };
    });

  return results;
};

export const retrieveAssetChildren = async (props, assetId) => {
  const { organizationId, apiUrl, token, zones, facilities } = props;
  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must_not: [],
        must: [
          {
            term: {
              current_owner_id: organizationId,
            },
          },
          {
            term: {
              parent_id: assetId,
            },
          },
        ],
      },
    },
    limit: 1000,
    sort: `time_of_log DESC`,
  };

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((res) => res.json())
    .then((res) => {
      return (
        res.assets?.map((asset) => {
          const zone =
            asset.zone && asset.zone.zoneId && zones[asset.zone.zoneId]
              ? zones[asset.zone.zoneId].name
              : null;
          const binLocation =
            asset.zone && asset.zone.binLocation
              ? asset.zone.binLocation
              : null;
          return {
            assetTag: asset.tag,
            ancestorList: asset.ancestorList || [],
            ancestors: asset.ancestors || [],
            binLocation,
            facility: facilities[asset.facilityId]
              ? facilities[asset.facilityId].name
              : "",
            id: asset.assetId,
            lastEvent: asset.lastEvent,
            parentId: asset.parentId,
            quantityNeeded: asset.quantity
              ? asset.quantity.quantityNeeded
              : null,
            quantityPicked: asset.quantity
              ? asset.quantity.quantityPicked
              : null,
            zone,
          };
        }) || []
      );
    });
  return results;
};

export const resetDisplayColumnOptions = async (props, filters) => {
  const { apiUrl, token, userId } = props;
  const payload = {
    propertiesMap: {
      assetStatusTableSettings: {
        displayColumnOptions: null,
        defaultColumnOrder: [],
      },
    },
  };

  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};
