import { useEffect, useState } from "react";
import { 
  Button, 
  Grid, 
  makeStyles, 
  Switch, 
  Tooltip,
 } from "@material-ui/core";
import { eventSelectStyles } from "./eventSelectStyles";
import { naturalSort } from "../../../../utils/naturalSort";
import DatePicker from "../../../Forms/FieldTypes/DatePicker";
import DateSelectionRadios from "./DateSelectionRadios";
import ReactSelectCheckboxes from "../../../Selects/ReactSelectCheckboxes";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: "8rem",
  },
  clearFiltersButton: {
    backgroundColor: theme.palette.typography.secondary,
    color: "white",
  },
  saveButton: {
    backgroundColor: theme.palette.main,
  },
}));

export default function Filters(props) {
  const classes = useStyles();

  const {
    setFilters: setFiltersFromProps,
    filters: filtersFromProps,
    retrieveEventCounts,
    filterDefaults,
    setView,
    setData,
    handleSaveWidgetSettings,
    organization,
    timeZone,
  } = props;

  const { eventTypesMap = {} } = organization;
  const [filters, setFilters] = useState(filtersFromProps);

  const [eventTypesOptions, setEventTypesOptions] = useState(
    (eventTypesMap ? Object.keys(eventTypesMap) : [])
      .map((type) => {
        return {
          label: type,
          value: type,
          color: filters?.customColors ? eventTypesMap[type].color || "" : "",
        };
      })
      .sort((a, b) => naturalSort(a.label, b.label))
  );

  const handleFilterChange = (update) => {
    setFilters((f) => {
      return {
        ...f,
        ...update,
      };
    });
  };

  // effect for re-rendering select options and styles when user toggles custom color swtich
  useEffect(() => {
    setEventTypesOptions((events) => {
      const newEvents = events.map((event) => {
        return {
          ...event,
          color:
            filters?.customColors === true
              ? eventTypesMap[event.value]?.color || ""
              : "",
        };
      });
      return newEvents;
    });
  }, [filters.customColors, eventTypesMap]);

  return (
    <>
      <Grid id="eventActivityFilterView" container spacing={3}>
        {/* Events */}
        <Grid item xs={12}>
          <label>
            Events{" "}
            <Tooltip title="Select up to 7 events to display in the event activity tracker and graph.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
          </label>
          <ReactSelectCheckboxes
            id={"eventActivityEventFilterSelect"}
            maximumSelectionLength={7}
            styles={eventSelectStyles(filters.customColors)}
            options={eventTypesOptions}
            onChange={(e) => {
              handleFilterChange({
                events: e,
              });
            }}
            value={filters.events}
          />
        </Grid>
        {/* Date interval selection */}
        <DateSelectionRadios
          filters={filters}
          setFilters={setFilters}
          timeZone={timeZone}
        />
        {/* Start Date */}
        <Grid item xs={6}>
          <label>Select Start Date</label>
          <DatePicker
            disabled={filters.dateSelection !== "custom"}
            format="yyyy/MM/DD"
            label="Start Date"
            onChange={(date) => {
              if (filters.endDate && date > filters.endDate) {
                alert("Can't set start date later than end date");
                return;
              }

              handleFilterChange({ startDate: new Date(date) });
            }}
            size="small"
            value={filters.startDate}
          />
        </Grid>

        {/* End Date */}
        <Grid item xs={6}>
          <label>Select End Date</label>
          <DatePicker
            disabled={filters.dateSelection !== "custom"}
            format="yyyy/MM/DD"
            label="End date"
            onChange={(date) => {
              if (filters.startDate && date < filters.startDate) {
                alert("Can't set end date earlier than start date");
                return;
              }
              handleFilterChange({ endDate: new Date(date) });
            }}
            size="small"
            value={filters.endDate}
          />
        </Grid>
        <Grid item xs={12}>
          <label>
            Use custom color pallete{" "}
            <Tooltip title="Use custom colors for events as defined in global settings.">
              <i className="fas fa-info-circle fa-sm"></i>
            </Tooltip>{" "}
          </label>
          <Switch
            checked={filters.customColors || false}
            onChange={(e) => {
              handleFilterChange({
                customColors: e.target.checked,
              });
            }}
            color="primary"
            name="customColors"
            inputProps={{ "aria-label": "Set default colors" }}
          />
        </Grid>
      </Grid>
      <hr />
      <Grid container justify={"space-between"} spacing={3}>
        <Grid item>
          <Button
            className={`${classes.clearFiltersButton} ${classes.button}`}
            onClick={() => {
              setFilters({
                ...filterDefaults,
              });
            }}
            variant="contained"
          >
            Clear Filters
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            onClick={() => {
              setView({
                filterView: false,
              });
            }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={`${classes.button} ${classes.saveButton}`}
            color="primary"
            onClick={() => {
              setFiltersFromProps(filters);
              retrieveEventCounts(filters).then((counts) => {
                setData(counts);
                handleSaveWidgetSettings("eventActivitySettings", filters);
                setTimeout(() => {
                  setView({
                    filterView: false,
                  });
                }, 200);
              });
            }}
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
