import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "1rem 0",
  },
  content: {
    textAlign: "center",
  },
  title: {
    backgroundColor: "#3d5ba9",
    color: "white",
    textAlign: "center",
  },
}));

export default function ConfirmationDialog(props) {
  const { cqrLayoutName, modalShow, onChange, onSubmit, open, setState } =
    props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => {
        setState((prevState) => ({
          ...prevState,
          cqrLayoutName: { value: "", error: false },
        }));
        modalShow();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.title} id="alert-dialog-title">
        Save CQR Layout
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid className={classes.content} item xs={12}>
            <Typography variant="body1" gutterBottom>
              Define CQR Layout Name
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SimpleTextField
              error={cqrLayoutName.error}
              helperText={cqrLayoutName.error ? "Required Field" : ""}
              id="outlined-basic"
              onChange={(event) => {
                onChange(event.target.value);
              }}
              value={cqrLayoutName.value}
              variant="outlined"
            />
          </Grid>
          <Grid className={classes.button} item xs={12}>
            <MaterialUiButton
              color="primary"
              fullWidth={true}
              label="Submit"
              onClick={() => {
                if (cqrLayoutName.value.length === 0) {
                  setState((prevState) => ({
                    ...prevState,
                    cqrLayoutName: { value: "", error: true },
                  }));
                } else {
                  onSubmit();
                }
              }}
              variant="contained"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
