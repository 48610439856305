import { useState } from "react";
import { editZone } from "../API/api";
import { Grid, makeStyles } from "@material-ui/core";
import { naturalSort } from "../../../../utils/naturalSort";
import MaterialUiButton from "../../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import SimpleSelect from "../../../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../../../components/Forms/FieldTypes/TextField";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

export default function EditZone(props) {
  const {
    notificationModal,
    onHide,
    onSuccess,
    selectedZone = {},
    zoneCategories,
    zoneHashMap,
    zones,
  } = props;
  const classes = useStyles();
  const [editState, setEditState] = useState({
    abbreviation: selectedZone.abbreviation || "",
    category: selectedZone.category || "",
    description: selectedZone.propertiesMap.description,
    error: {
      name: false,
    },
    floor: selectedZone.floor || "",
    name: selectedZone.name,
    parentId: selectedZone.parentId || "",
    validated: false,
    zoneType: selectedZone.zoneType || "",
  });
  const {
    abbreviation = "",
    category = "",
    description = "",
    floor = "",
    name = "",
    parentId = "",
    zoneType = "",
  } = editState;
  const categorySelectOptions = [...zoneCategories]
    .sort((a, b) => naturalSort(a, b))
    .map((element, index) => ({
      label: element,
      value: element,
    }));

  const parentZoneSelectOptions = [...zones]
    .filter((z) => z.zoneId !== selectedZone.zoneId)
    .sort((a, b) => naturalSort(a.label, b.label))
    .map((element, index) => ({
      label: element.name,
      value: element.name,
    }));

  const selectedParentZone =
    zones.filter((item) => item.zoneId === parentId)[0] || {};

  const handleSubmit = () => {
    const {
      name,
      description,
      abbreviation,
      floor,
      category,
      zoneType,
      parentId,
    } = editState;

    const payload = {
      name,
      abbreviation,
      floor,
      category,
      parentId: zoneHashMap[parentId] ? zoneHashMap[parentId].zoneId : null,
      zoneType,
      propertiesMap: {
        description: description,
      },
    };

    editZone(props, selectedZone.zoneId, payload).then((editResponse) => {
      if (editResponse.success) {
        onSuccess(selectedZone.zoneId, editResponse.zone);
        onHide();
        notificationModal(
          "Zone Successfully Updated",
          false,
          "fas fa-check-circle",
          3000
        );
      } else {
        notificationModal(
          `${
            editResponse.error ||
            `Something went wrong when trying to add a zone to the facility. Please contact your system administrator.`
          }`,
          true,
          "fas fa-times-circle",
          3000
        );
      }
    });
  };

  function validateFields() {
    let isValidated = true;
    const fieldsToValidate = [{ id: "name", value: name }];

    // If something is empty, we flip isValidated to false
    fieldsToValidate.forEach((element) => {
      const { id, value = "" } = element;
      if (value?.length === 0) {
        isValidated = false;

        setEditState((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            [id]: true,
          },
        }));
      }
    });

    // If isValidated is never flipped, we move on
    if (isValidated) {
      handleSubmit();
    }
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      {/* Zone Name */}
      <Grid item xs={6}>
        <SimpleTextField
          error={editState.error?.name}
          id="name"
          label="Zone Name"
          onChange={(event) => {
            setEditState((prevState) => ({
              ...prevState,
              error: { ...prevState.error, name: false },
              name: event.target.value,
            }));
          }}
          required
          value={name}
        />
      </Grid>

      {/* Zone Abbreviation */}
      <Grid item xs={6}>
        <SimpleTextField
          id="abbreviation"
          label="Zone Abbreviation - optional"
          onChange={(event) => {
            setEditState((prevState) => ({
              ...prevState,
              abbreviation: event.target.value,
            }));
          }}
          value={abbreviation}
        />
      </Grid>

      {/* Floor */}
      <Grid item xs={6}>
        <SimpleTextField
          id="abbreviation"
          label="Floor - optional"
          onChange={(event) => {
            setEditState((prevState) => ({
              ...prevState,
              floor: event.target.value,
            }));
          }}
          value={floor}
        />
      </Grid>

      {/* Category */}
      <Grid item xs={6}>
        <SimpleSelect
          id="category"
          label="Category - optional"
          onChange={(event) => {
            setEditState((prevState) => ({
              ...prevState,
              category: event.target.value,
            }));
          }}
          options={categorySelectOptions}
          value={category}
          variant="outlined"
        />
      </Grid>

      {/* Zone Type */}
      <Grid item xs={6}>
        <SimpleTextField
          id="zoneType"
          label="Zone Type - optional"
          onChange={(event) => {
            setEditState((prevState) => ({
              ...prevState,
              zoneType: event.target.value,
            }));
          }}
          value={zoneType}
        />
      </Grid>

      {/* Parent Zone */}
      <Grid item xs={6}>
        <SimpleSelect
          id="parentId"
          label="Parent Zone - optional"
          onChange={(event) => {
            setEditState((prevState) => ({
              ...prevState,
              parentId: zones.filter(
                (item) => item.name === event.target.value
              )[0].zoneId,
            }));
          }}
          options={parentZoneSelectOptions}
          value={selectedParentZone.name}
          variant="outlined"
        />
      </Grid>

      {/* Zone Description */}
      <Grid item xs={12}>
        <SimpleTextField
          id="abbreviation"
          label="Zone Description - optional"
          multiline
          onChange={(event) => {
            setEditState((prevState) => ({
              ...prevState,
              description: event.target.value,
            }));
          }}
          rows={4}
          value={description}
        />
      </Grid>

      {/* Cancel and Submit Buttons */}
      <Grid className={classes.buttonContainer} item xs={12}>
        {/* Cancel Button */}
        <Grid item xs={4}>
          <MaterialUiButton
            color="cancel"
            fullWidth
            label="Cancel"
            onClick={() => {
              onHide();
            }}
            variant="outlined"
          />
        </Grid>
        {/* Submit Button */}
        <Grid item xs={4}>
          <MaterialUiButton
            color="primary"
            fullWidth
            label="Submit"
            onClick={() => {
              validateFields();
            }}
            variant="contained"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
