import { useState } from "react";
import { formatLatLon } from "../../../../../utils/lat_lon_utils";
import { makeStyles } from "@material-ui/core/styles";
import { putPolygon } from "../../API/API";
import { Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import MaterialUiButton from "../../../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import PolygonMap from "../../Maps/PolygonMap/PolygonMap";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(1),
    width: "100vw", // Fix IE 11 issue.
  },
  polygon: {
    textAlign: "center",
  },
  submitButton: {
    marginTop: theme.spacing(1),
    width: "33%",
  },
}));

function DrawPolygon(props) {
  const { selectedLocation } = props;
  const classes = useStyles();
  const initPolygonCoordinates = selectedLocation?.polygonGeoJSON?.coordinates;

  const [polygon, setPolygon] = useState({
    features: {
      type: "Feature",
      id: "initFeature",
      geometry: {
        coordinates: initPolygonCoordinates || [],
        type: "Polygon",
      },
      properties: {
        renderType: "Polygon",
      },
    },

    editsMade: false,
  });

  const initLat = selectedLocation?.location?.latitude;
  const initLong = selectedLocation?.location?.longitude;

  return (
    <>
      <div className={classes.form}>
        <h5 style={{ textAlign: "center" }}>
          Polygon{" "}
          <Tooltip
            title="Draw a new Polygon feature by selecting the draw tool in the bottom
              right corner of the map, and then clicking positions to add then
              closing the polygon (or double-clicking). Edit or drag the polyon
              by clicking on the completed polygon, and delete it by selecting the trash icon."
          >
            <InfoIcon style={{ color: "#3D5BA9" }} fontSize="small" />
          </Tooltip>
        </h5>

        <Grid
          container
          className={classes.polygon}
          spacing={2}
          justify="center"
        >
          <Grid item xs={12}>
            <p>Draw a polygon to establish the perimeter of your facility.</p>
            {initLat && initLong && polygon ? (
              <div style={{ width: "80%", height: "65vh", margin: "auto" }}>
                <PolygonMap
                  singlePolygon
                  // width="80%"
                  // height="40vh"
                  // margin="auto"
                  latitude={initLat}
                  longitude={initLong}
                  setPolygon={setPolygon}
                  polygon={polygon}
                />
              </div>
            ) : null}
          </Grid>
          {polygon?.features?.geometry?.coordinates[0]?.length ? (
            <Grid item xs={12}>
              <h5>Points</h5>
            </Grid>
          ) : null}
          {polygon?.features?.geometry?.coordinates[0] &&
          polygon?.features?.geometry?.coordinates[0].length
            ? polygon.features?.geometry.coordinates[0].map(
                (point, idx) => (
                  <Grid
                    item
                    xs={3}
                    sm={2}
                    // style={{ textAlign: "left" }}
                    key={`${point[0]} ${point[1]} ${idx}`}
                  >
                    <p>
                      {" "}
                      Point {idx + 1}: <br />[{formatLatLon(point[1], point[0])}
                      ]
                    </p>
                  </Grid>
                )
              )
            : null}
          <Grid item xs={12}>
            <MaterialUiButton
              className={classes.submitButton}
              color="primary"
              disabled={!polygon.editsMade}
              fullWidth
              label="Submit"
              onClick={() => {
                putPolygon({ ...props }, polygon).then((res) => {
                  props.onHide();
                  if (res?.error) {
                    props.notificationModal(`${res.error}`, true);
                  } else {
                    props.notificationModal(
                      `Facility area successfully updated.`,
                      false
                    );
                    props.onSuccess(res);
                  }
                });
              }}
              type="submit"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default DrawPolygon;
