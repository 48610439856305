export const BrandColorSwatches = [
  ["#32355C", "#3D5BA9", "#5884F5"],
  ["#36562F", "#45793A", "#A0C46D"],
  ["#B71D1A", "#EB4F35", "#FFC854"],
  ["#4E237C", "#723F9E", "#BF83EA"],
  ["#B83373", "#EB4293", "#FF92D0"],
  ["#6D6E70", "#A6A8AB", "#E6E7E8"],
];

export const VerticalBrandColorSwatches = [
  "#32355C",
  "#36562F",
  "#B71D1A",
  "#4E237C",
  "#B83373",
  "#6D6E70",
  "#3D5BA9",
  "#45793A",
  "#EB4F35",
  "#723F9E",
  "#EB4293",
  "#A6A8AB",
  "#5884F5",
  "#A0C46D",
  "#FFC854",
  "#BF83EA",
  "#FF92D0",
  "#E6E7E8",
];

export const BrandColorArray = [
  "#32355C",
  "#3D5BA9",
  "#5884F5",
  "#36562F",
  "#45793A",
  "#A0C46D",
  "#B71D1A",
  "#EB4F35",
  "#FFC854",
  "#4E237C",
  "#723F9E",
  "#BF83EA",
  "#B83373",
  "#EB4293",
  "#FF92D0",
  "#6D6E70",
  "#A6A8AB",
  "#E6E7E8",
];

export const LX_INDIGO = "#32355C";

export const LX_GREEN = "#";
