import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

function SimpleSelect(props) {
  const {
    disabled,
    error,
    errorText = "Required Field",
    helperText,
    id,
    inputProps,
    InputLabelProps,
    label,
    margin,
    onChange,
    options,
    readOnly,
    required,
    size = "small",
    style,
    type,
    value,
    variant,
  } = props;
  const useStyles = makeStyles((theme) => ({
    label: {
      color:"#6D6E70",
      fontFamily:"Lato",
      fontWeight:"bold"
    },
    select: {
      width: "100%",
    },
  }));
  const classes = useStyles();
  const formattedLabel = label?.replace(/\s+/g, '');

  return (
    <>
     {label ? (
        <InputLabel className={classes.label} id={formattedLabel} shrink={props.shrink}>
          {label ? label : "Select"}
        </InputLabel>
      ) : null}
    <FormControl
      className={`${props.classes?.select ? props.classes.select : classes.select} simple-select`}
      error={error}
      InputLabelProps={{
        ...InputLabelProps,
        classes:{
          root:"hi",
          focused:"hello"
        },
        required: false,
      }}
      size={size}
      variant={variant ? variant : "standard"}
    >
     

      <Select
        disabled={disabled}
        id={id}
        inputProps={{ readOnly: readOnly, ...inputProps }}
        label={label}
        labelId={formattedLabel}
        margin={margin}
        onChange={(e) => {
          onChange(e);
        }}
        required={required}
        style={style}
        type={type}
        value={value || ""}
      >
        {options && options.length ? (
          options.map((option, idx) => (
            <MenuItem
              disabled={option.disabled}
              value={option.value}
              key={`${idx} - ${option.value}`}
            >
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem>None</MenuItem>
        )}
      </Select>
      {error ? <FormHelperText>{errorText}</FormHelperText> : null}
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
    </>
  );
}

export default SimpleSelect;
