const createPreflightCheck = async (props, data) => {
  const { apiUrl, token } = props;
  const {
    centroidString = null,
    name = null,
    radius = null,
    location = null,
  } = data;

  const payload = {
    name: name,
    centroidString: centroidString,
    radius: radius,
    location: location?.address1
      ? {
          address1: location.address1 || null,
          city: location.city || null,
          state: location.state || null,
        }
      : null,
  };
  // if null/falsy centroid string, remove property altogether from payload as it interferes with address lookup
  if (!payload.centroidString) {
    delete payload.centroidString;
  }

  const results = await fetch(`${apiUrl}facilities/preflight`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

const editPreflightCheck = async (props, data) => {
  const { apiUrl, token } = props;
  const {
    centroidString = null,
    name = null,
    radius = null,
    location = null,
    facilityId = null,
  } = data;

  const payload = {
    name: name,
    location: location
      ? {
          ...location,
        }
      : null,
    centroidString: centroidString,
    radius: radius,
  };
  // removing properties altogether for preflight API if null / falsey
  Object.keys(payload).forEach((k) => {
    if (!payload[k]) {
      delete payload[k];
    }
  });

  const results = await fetch(`${apiUrl}facilities/${facilityId}/preflight`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

const centroidPreflightCheck = async (props, data) => {
  const { apiUrl, token } = props;
  const { centroidString = null, radius = null, facilityId = null } = data;

  const payload = {
    centroidString: centroidString,
    radius: radius,
  };

  const results = await fetch(`${apiUrl}facilities/${facilityId}/preflight`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

const polygonPreflightCheck = async (props, data) => {
  const { apiUrl, token } = props;
  const { polygonString = null, facilityId } = data;

  // if no polygonString, then user is deleting polygon. OK to return true.
  if (!polygonString) {
    return true;
  } else {
    const payload = {
      polygonString: `POLYGON((${polygonString}))`,
    };
    const results = await fetch(`${apiUrl}facilities/${facilityId}/preflight`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return { error: err };
      });
    return results;
  }
};

export {
  createPreflightCheck,
  editPreflightCheck,
  centroidPreflightCheck,
  polygonPreflightCheck,
};
